import { RpMustUser } from "@/types";

import axios from "./axiosService";

const URLS = {
  USER: {
    INDEX: "/user",
    IMPORT: "/user/import",
    UNIT: "/user/unit",
    TITLE: "/user/title",
  },
};

/**
 * 取得使用者
 */
export const getUser = (userId: string) => axios.get<RpMustUser>(`${URLS.USER.INDEX}/${userId}`);

/**
 * 新增使用者
 */
export const postUser = (user: any) => axios.post(URLS.USER.INDEX, user);

/**
 * 修改使用者
 */
export const putUser = (user: any) => axios.put(URLS.USER.INDEX, user);

/**
 * 刪除使用者
 */
export const deleteUser = (userId: string) => axios.delete(`${URLS.USER.INDEX}/${userId}`);

/**
 * 取得所有使用者
 */
export const getAllUser = () => axios.get<RpMustUser[]>(URLS.USER.INDEX);

/**
 * 匯入使用者
 * @param file
 */
export const postImportUser = (file: FormData) => axios.post(URLS.USER.IMPORT, file);

/**
 * 取得所有單位
 */
export const getAllUnit = () => axios.get(URLS.USER.UNIT);

/**
 * 取得所有職稱
 */
export const getAllTitle = () => axios.get(URLS.USER.TITLE);
