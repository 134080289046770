import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { useForm } from "antd/lib/form/util";

import { useRpMustUser } from "@/hooks";
import { StoreTypes } from "@/store";
import { fetchMostAndProjectType, requestProject } from "@/action/projectAction";
import {
  getProjectIsNewTeacher,
  getProjectMost,
  getProjectProperty,
  getProjectType,
  updateProject,
} from "@/services/projectService";

import { MuiSpin, ProjectForm, RpAlert } from "..";

type EditDialogProps = {
  open: boolean;
  handleOpen: (value: React.SetStateAction<boolean>) => void;
};

const EditDialog: React.FC<EditDialogProps> = ({ open, handleOpen }) => {
  const [loading, setLoading] = useState(true);
  const { data: project, projectTypes, mostGroup } = useSelector(
    (state: StoreTypes) => state.project
  );
  const dispatch = useDispatch();
  const { userId } = useRpMustUser();
  const [form] = useForm();

  useEffect(() => {
    //有資料就不需要抓了
    if (projectTypes.length !== 0 || mostGroup.length !== 0) {
      return;
    }

    //抓取計畫類型跟學門分類
    dispatch(fetchMostAndProjectType());
  }, [dispatch, projectTypes.length, mostGroup.length]);

  const handleRendered = async () => {
    setLoading(true);

    // 開啟dialog的時候設定預設值
    const typeId = (await getProjectType(userId)).data;
    const propertyId = (await getProjectProperty(userId)).data;
    const mostIdArray = (await getProjectMost(userId)).data;
    const isNewTeacher = (await getProjectIsNewTeacher(userId)).data;

    form.setFieldsValue({
      name: project.name,
      ext: project.ext,
      phone: project.phone,
      type: typeId,
      property: propertyId,
      most: mostIdArray,
      isNewTeacher: isNewTeacher,
      appointmentDate: project.appointmentDate && moment(project.appointmentDate),
    });
    setLoading(false);
  };

  const handleFinish = (value: any) => {
    setLoading(true);
    updateProject(value, userId)
      .then((res) => {
        handleOpen(false);
        if (res.data.message) {
          RpAlert.error("修改失敗！");
          return;
        }
        RpAlert.show("修改成功！");
        dispatch(requestProject(userId));
      })
      .catch((err) => RpAlert.error(String(err)))
      .finally(() => setLoading(false));
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleOpen(false)}
      fullWidth
      maxWidth="md"
      style={{ zIndex: 30 }}
      onRendered={handleRendered}
    >
      <DialogTitle style={{ textAlign: "center" }}>編輯計畫</DialogTitle>
      <DialogContent>
        <MuiSpin spinning={loading}>
          <ProjectForm form={form} onFinish={handleFinish} />
        </MuiSpin>
      </DialogContent>
      <DialogActions style={{ display: "flex", justifyContent: "center" }}>
        <Button variant="contained" color="inherit" onClick={() => handleOpen(false)}>
          取消
        </Button>

        <Button
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={() => form.submit()}
        >
          編輯完成
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDialog;
