import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from "@material-ui/core";

import { requestBudgetList } from "@/action/budgetAction";
import { putBudget } from "@/services/budgetService";
import { useRpMustUser } from "@/hooks";
import { Budget } from "@/types";
import { RpAlert } from "@/components";

import PartTimeForm from "./PartTimeForm";

type EditDialogProps = {
  budget: Budget;
} & DialogProps;

const EditDialog: React.FC<EditDialogProps> = ({ open, budget, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState<any>({});
  const { userId } = useRpMustUser();
  const dispatch = useDispatch();

  const handleFinish = (value: any) => {
    setLoading(true);

    //編輯工讀預算
    putBudget(value as any, budget.id, userId).then((res) => {
      setLoading(false);
      if (res.data.message) {
        RpAlert.error(`${res.data.message}`);
        return;
      }
      dispatch(requestBudgetList(userId));
      RpAlert.show("修改成功！");
      (onClose as any)();
    });
  };

  return (
    <Dialog
      disableBackdropClick={loading}
      disableEscapeKeyDown={loading}
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>編輯工讀生費用</DialogTitle>
      <DialogContent>
        <PartTimeForm
          mode="edit"
          loading={loading}
          budget={budget}
          setData={setForm}
          onFinish={handleFinish}
        />
      </DialogContent>
      <DialogActions style={{ display: "flex", justifyContent: "center" }}>
        <Button
          disabled={loading}
          variant="contained"
          color="inherit"
          onClick={() => (onClose as any)()}
        >
          取消
        </Button>
        <Button
          disabled={loading}
          variant="contained"
          color="primary"
          onClick={() => form.submitForm()}
        >
          確認
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDialog;
