import React, { useContext } from "react";
import { Button, createStyles, makeStyles, Theme, Tooltip } from "@material-ui/core";
import { ReplayRounded } from "@material-ui/icons";
import { RiFileExcel2Fill } from "react-icons/ri";

import ProjectPagination from "./ProjectPagination";
import SearchInput from "./SearchInput";
import classes from "./style.module.scss";
import { ProjectContext } from "./ProjectManager";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: "green",
    },
  })
);
const ProjectToolbar: React.FC = () => {
  const classes2 = useStyles();
  const { getProjectsData } = useContext(ProjectContext);

  /**
   * 計畫轉Excel
   */
  const handleProjectToExcel = () => {
    let downloadBtn = document.createElement("a") as HTMLAnchorElement;
    downloadBtn.target = "_blank";
    downloadBtn.href = "/api/admin/project-to-excel";
    downloadBtn.style.display = "none";
    document.body.appendChild(downloadBtn);
    downloadBtn.click();
    document.body.removeChild(downloadBtn);
  };

  return (
    <div className={classes.menu}>
      <div className={classes.refresh}>
        <Tooltip title="重新整理頁面">
          <Button startIcon={<ReplayRounded />} onClick={getProjectsData}>
            重新整理
          </Button>
        </Tooltip>
      </div>
      <div>
        <Tooltip title="將計畫資料匯出成Excel試算表">
          <Button
            className={classes2.root}
            startIcon={<RiFileExcel2Fill />}
            onClick={handleProjectToExcel}
          >
            匯出成Excel
          </Button>
        </Tooltip>
      </div>
      <div className={classes.search}>
        <SearchInput />
      </div>
      <div className={classes.pagination}>
        <ProjectPagination />
      </div>
    </div>
  );
};

export default ProjectToolbar;
