import React, { Suspense } from "react";
import { Route, Switch } from "react-router";

import { AuthorizeRoute, Loading } from "@/components";
import { ROUTES } from "@/constants";
import {
  Admin,
  File,
  Home,
  Login,
  NotFound,
  OutReviewer,
  OutReviewerEdit,
  OutReviewerList,
  Project,
  Report,
} from "@/views";

import { ReviewerRouter } from ".";

const AppRouter: React.FC = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path={ROUTES.LOGIN} component={Login} />
        <AuthorizeRoute path={ROUTES.PROJECT.INDEX} component={Project} />
        <AuthorizeRoute path={ROUTES.ADMIN.INDEX} component={Admin.Index} />
        <AuthorizeRoute path={ROUTES.REVIEWER.OUT_REVIEWER.INDEX} component={OutReviewer} exact />
        <AuthorizeRoute path={ROUTES.REVIEWER.OUT_REVIEWER.LIST} component={OutReviewerList} />
        <AuthorizeRoute
          path={`${ROUTES.REVIEWER.OUT_REVIEWER.EDIT}/:userId`}
          component={OutReviewerEdit}
        />
        <AuthorizeRoute path={ROUTES.REVIEWER.INDEX} component={ReviewerRouter} />
        <AuthorizeRoute path={ROUTES.REPORT.INDEX} component={Report.Index} />
        <AuthorizeRoute path={`${ROUTES.FILE}/:fileName`} component={File} />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
};
export default AppRouter;
