import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, TextField } from "@material-ui/core";

import { ProjectType } from "@/types";
import { MuiSpin } from "@/components";

const ProjectTypeDialog: React.FC<
  { pt: ProjectType; onUpdate: (pt: ProjectType) => void } & DialogProps
> = ({ open, pt, onUpdate, onClose }) => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true);
    onUpdate({
      id: pt.id,
      name,
    });
  };

  useEffect(() => {
    setLoading(false);
    setName(pt.name);
  }, [pt]);

  return (
    <Dialog
      fullWidth
      disableBackdropClick={loading}
      open={open}
      onClose={onClose}
      maxWidth="sm"
    >
      <DialogTitle>編輯</DialogTitle>
      <DialogContent>
        <MuiSpin spinning={loading}>
          <TextField
            fullWidth
            variant="outlined"
            color="primary"
            size="small"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </MuiSpin>
      </DialogContent>
      <DialogActions style={{ display: "flex", justifyContent: "center" }}>
        <Button
          disabled={loading}
          color="inherit"
          variant="contained"
          onClick={onClose as any}
        >
          取消
        </Button>
        <Button
          disabled={loading}
          color="primary"
          variant="contained"
          onClick={handleClick}
        >
          確認
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProjectTypeDialog;
