import React, { useContext, useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogProps,
  Button,
  TextField,
} from "@material-ui/core";

import { ProjectContext } from "@/views/Admin/ProjectManager/ProjectManager";
import { OutReviewer } from "@/types";
import {
  getOutReviewerMsgFromProject,
  getOutReviewers,
  postOutReviewerAssign,
  putOutReviewerAssign,
} from "@/services/outReviewerService";
import { Autocomplete } from "@material-ui/lab";
import { RpAlert } from "../MuiAlert/MuiAlert";

type AssignDialogProps = {} & DialogProps;

const AssignDialog: React.FC<AssignDialogProps> = ({ open, onClose }) => {
  const { currentProject, setUpdateReviewerState, updateReviewerState } = useContext(
    ProjectContext
  );
  const [or, setOr] = useState<OutReviewer[]>([]);
  const [currentOr, setCurrentOr] = useState<OutReviewer>({} as OutReviewer);
  const [loading, setLoading] = useState(false);
  const [hasOr, setHasOr] = useState(false);
  const [currentMsgId, setCurrentMsgId] = useState(0);

  const handleClick = () => {
    setLoading(true);

    if (hasOr) {
      console.log({ currentMsgId, orid: currentOr.outReviewerId, userId: currentProject.userId });
      //修改
      putOutReviewerAssign(currentMsgId, currentOr.outReviewerId, currentProject.userId).then(
        (res) => {
          setLoading(false);
          if (res.data) {
            setHasOr(true);
            RpAlert.show("修改成功！");
            (onClose as any)();
          } else {
            RpAlert.error("修改失敗");
          }
          setUpdateReviewerState(!updateReviewerState);
        }
      );
    } else {
      //新增
      postOutReviewerAssign(currentOr.outReviewerId, currentProject.userId).then((res) => {
        setLoading(false);
        if (res.data) {
          setHasOr(true);
          RpAlert.show("指派成功！");
          (onClose as any)();
        } else {
          RpAlert.error("指派失敗");
        }
        setUpdateReviewerState(!updateReviewerState);
      });
    }
  };

  useEffect(() => {
    if (!hasOr) {
      return;
    }

    getOutReviewerMsgFromProject(currentProject.userId).then((res) => {
      console.log("setcurrent");
      setCurrentMsgId(res.data.id);
    });
  }, [hasOr]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const orRes = await getOutReviewers();
      const currentOr = await getOutReviewerMsgFromProject(currentProject.userId);
      setOr(orRes.data);
      if (currentOr.data) {
        setCurrentMsgId(currentOr.data.id);
        setHasOr(true);
        setCurrentOr(
          or.find((o) => o.outReviewerId === currentOr.data.outReviewerId) as OutReviewer
        );
      } else {
        setHasOr(false);
        setCurrentOr({} as OutReviewer);
      }
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject.userId]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>指派外審委員（{currentProject.userName}）</DialogTitle>
      <DialogContent>
        <Autocomplete
          disabled={loading}
          disableClearable
          size="small"
          options={or}
          noOptionsText="找不到~"
          value={currentOr}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} label="外審委員" variant="outlined" />}
          onChange={(_, v) => {
            console.log(v);
            setCurrentOr(v);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} variant="contained" color="primary" onClick={onClose as any}>
          取消
        </Button>
        <Button disabled={loading} variant="contained" color="primary" onClick={handleClick}>
          確認
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignDialog;
