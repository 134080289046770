import { Dispatch } from "redux";

import { getAllSetting } from "@/services/settingService";
import { Setting } from "@/types/setting";

type SettingJSON = {
  id:string;
  name:string;
  value:string;
  description:string;
}

export enum SETTING_ACTION {
  REQUEST_SETTING = "REQUEST_SETTING",
  RECEIVE_SETTING = "RECEIVE_SETTING",
}

type RequestSetting = {
  type: SETTING_ACTION.REQUEST_SETTING;
};

type ReceiveSetting = {
  type: SETTING_ACTION.RECEIVE_SETTING;
  payload: SettingJSON[];
};

/**
 * 請求設定
 */
export const requestSetting = () => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: SETTING_ACTION.REQUEST_SETTING } as RequestSetting);

    const data = (await getAllSetting()).data;

    dispatch({ type: SETTING_ACTION.RECEIVE_SETTING, payload: data } as ReceiveSetting);
  };
};

export type SettingActionTypes = RequestSetting | ReceiveSetting;
