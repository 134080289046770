import React, { useContext } from "react";
import { Button, Card, Dialog, DialogContent, DialogProps, DialogTitle, Divider } from "@material-ui/core";
import { Close, Print } from "@material-ui/icons";

import { PrintProject } from "@/components";

import { ProjectContext } from "./ProjectManager";

/**
 * 列印申請表
 */
const PrintDialog: React.FC<DialogProps> = ({ open, onClose }) => {
  const { currentProject } = useContext(ProjectContext);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      PaperProps={{ style: { maxWidth: 830 } }}
      onClose={onClose}
    >
      <DialogTitle>
        <div style={{ position: "absolute", right: 15 }}>
          <Button onClick={onClose as any}>
            <Close />
          </Button>
        </div>
        <div
          style={{
            flex: "1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          列印申請表（{currentProject.userName}）
          <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<Print />}
            onClick={() => window.print()}
          >
            列印
          </Button>
        </div>
      </DialogTitle>
      <Divider style={{ background: "rgba(0, 0, 0, 0.2)" }} />
      <DialogContent style={{ padding: 0 }}>
        <Card>
          <PrintProject userId={currentProject.userId} />
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default PrintDialog;
