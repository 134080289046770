import React, { memo, useContext, useEffect, useState } from "react";
import { Button, TextField, Tooltip } from "@material-ui/core";

import { RpMust } from "@/constants";

import classes from "../style.module.scss";
import { TeacherScoreContext } from "../TeacherScore";

/**
 * 佐證輸入框
 * @param param0
 */
const EvidenceInput: React.FC = () => {
  const { teacherPerformance, evidence, setEvidence } = useContext(TeacherScoreContext);
  const [title, setTitle] = useState(evidence.title);
  const [file, setFile] = useState<File | null>(evidence.file);

  // 因為Context值會變，所以這個值一更新也要跟著改變，否則會對不上
  useEffect(() => {
    setTitle(evidence.title);
    setFile(evidence.file);
  }, [evidence]);

  // 變更佐證
  const handleChangeEvidence = () =>
    setEvidence({
      ...evidence,
      title,
      file: file as any,
    });

  const handleTitle = (e: React.ChangeEvent<{ value: unknown }>) => setTitle(e.target.value as any);

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const eFile = e.target.files![0] ? e.target.files![0] : null;

    if (eFile) {
      setFile(eFile);
      setEvidence({
        ...evidence,
        title,
        file: eFile,
      });
    }
  };

  // 因為直接onChange改Context的值會很lag，所以用一個state存值然後在onBlur改Context的值 (類似Vue的 v-model.lazy)
  return (
    <div className={classes.container}>
      <div>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          placeholder={teacherPerformance.method === 3 ? "金額（單位：元）" : "佐證名稱"}
          value={title}
          onChange={handleTitle}
          onBlur={handleChangeEvidence}
        />
      </div>
      <div className={classes.full}>
        <input
          type="file"
          name="file"
          id={`file-evidence`}
          style={{ display: "none" }}
          accept=".pdf"
          onChange={handleUpload}
        />
        <label htmlFor={`file-evidence`}>
          {file ? (
            <Tooltip
              title={
                <div>
                  檔案：{file.name} <br />
                  大小：{RpMust.sizeConvert(file.size)}
                </div>
              }
            >
              <Button fullWidth color="primary" variant="contained" component="span">
                已選擇佐證檔案（滑鼠移入查看）
              </Button>
            </Tooltip>
          ) : (
            <Button fullWidth color="primary" variant="contained" component="span">
              上傳佐證證明
            </Button>
          )}
        </label>
      </div>
    </div>
  );
};

export default memo(EvidenceInput);
