import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Divider } from "@material-ui/core";

import { addOtherBudget, requestOtherBudgetList } from "@/action/budgetAction";
import { useRpMustUser } from "@/hooks";
import { postOtherBudget } from "@/services/budgetService";
import { RpAlert } from "@/components";

import OtherForm from "./OtherForm";
import OtherTable from "./OtherTable";

const Other: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState<any>({});
  const { userId } = useRpMustUser();
  const dispatch = useDispatch();

  const handleFinish = (value: any) => {
    setLoading(true);

    value.quantity = Number(value.quantity);
    value.price = Number(value.price);

    // 新增預算
    dispatch(addOtherBudget());
    postOtherBudget(value, userId)
      .then((res) => {
        setLoading(false);
        if (res.data.message) {
          RpAlert.error(res.data.message);
          return;
        }
        RpAlert.show("新增成功！");
        dispatch(requestOtherBudgetList(userId) as any);

        //重置表單
        form.resetForm();
      })
      .catch((err) => RpAlert.error(err));
  };

  return (
    <React.Fragment>
      <OtherForm mode="add" loading={loading} setData={setForm} onFinish={handleFinish} />
      <Divider />
      <OtherTable />
    </React.Fragment>
  );
};

export default Other;
