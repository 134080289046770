import React, { useContext } from "react";
import { MenuItem, Select } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";

import { ProjectContext } from "./ProjectManager";

const rowPerPageOptions = [5, 10, 25, 50];

const ProjectPagination: React.FC = () => {
  const { currentProjects, page, rowPerPage, onRowPerPageChange, onPageChange } = useContext(
    ProjectContext
  );

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        每頁
        <Select
          value={rowPerPage}
          style={{ textAlign: "center" }}
          onChange={(e) => onRowPerPageChange(Number(e.target.value))}
          SelectDisplayProps={{ style: { paddingLeft: 8 } }}
        >
          {rowPerPageOptions.map((r) => (
            <MenuItem key={r} value={r}>
              {r}
            </MenuItem>
          ))}
        </Select>
        筆
      </div>
      <Pagination
        showFirstButton
        showLastButton
        shape="rounded"
        page={page}
        count={Math.floor((currentProjects.length + rowPerPage - 1) / rowPerPage)}
        onChange={(_, page) => onPageChange(page)}
      />
    </React.Fragment>
  );
};

export default ProjectPagination;
