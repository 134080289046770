import moment from "moment";
import React, { useEffect, useState } from "react";

import { getProject, getTeacherScore } from "@/services/projectService";
import { getBudgetCount, getOtherBudgets, getBudgets } from "@/services/budgetService";
import { getUser } from "@/services/userService";
import { Budget, OtherBudget, Project, RpMustUser } from "@/types";

import classes from "./PrintBudget.module.scss";
import { TeacherScoreType } from "../TeacherScore/TeacherScore";
import { MuiSpin, RpAlert } from "..";

type PrintBudgetType = {
  userId: string;
  shadow?: boolean;
  style?: React.CSSProperties;
};

const PrintBudget: React.FC<PrintBudgetType> = ({ userId, style, shadow = false }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<RpMustUser>({} as any);
  const [project, setProject] = useState<Project>({} as any);
  const [budgets, setBudgets] = useState<Budget[]>([]);
  const [otherBudgets, setOtherBudgets] = useState<OtherBudget[]>([]);

  useEffect(() => {
    (async () => {
      setLoading(true);

      try {
        const project = (await getProject(userId)).data;
        const user = (await getUser(userId)).data;
        const budgets = (await getBudgets(userId)).data;
        const otherBudgets = (await getOtherBudgets(userId)).data;

        setUser(user);
        setProject(project);
        setLoading(false);
        setBudgets(budgets);
        setOtherBudgets(otherBudgets);
        console.log("budgets:", budgets);
        console.log("otherBudgets:", otherBudgets);
      } catch {
        RpAlert.error("請求資料發生錯誤，請重新整理後再試一次");
      }
    })();
  }, [userId]);

  return (
    <MuiSpin spinning={loading}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          className={classes["print-box"]}
          style={{ boxShadow: shadow ? "1px 1px 4px 1px" : "", ...style }}
        >
          <h3>明新學校財團法人明新科技大學校內專題研究計畫經費表</h3>
          <table cellSpacing="1" cellPadding="8">
            <tbody>
              {/* 計畫主持人 */}
              <tr>
                <td className={classes.center}>計畫主持人</td>
                <td>
                  ( {user.userId} ) {user.name + user.title}
                </td>
              </tr>
              {/* 計畫名稱 */}
              <tr>
                <td className={classes.center}>中文計畫名稱</td>
                <td>{project.name}</td>
              </tr>
            </tbody>
          </table>
          <br />
          <h2>研究耗材與其他費用</h2>
          <table cellSpacing="1" cellPadding="8">
            <thead>
              <tr>
                <td>#</td>
                <td>品名</td>
                <td>用途</td>
                <td>單位</td>
                <td>數量</td>
                <td>單價</td>
                <td>小計</td>
              </tr>
            </thead>
            <tbody>
              {otherBudgets.map((value, i) => (
                <tr key={value.id}>
                  <td>{i + 1}</td>
                  <td>{value.name}</td>
                  <td>{value.detail}</td>
                  <td>{value.unit}</td>
                  <td>{value.quantity}</td>
                  <td>{value.price}</td>
                  <td>{value.price * value.quantity}</td>
                </tr>
              ))}
              <tr>
                <td colSpan={7} style={{ textAlign: "right" }}>
                  總合 : {otherBudgets.reduce((a, b) => a + b.price * b.quantity, 0)}元
                </td>
              </tr>
            </tbody>
          </table>

          <br />
          <br />
          <h2>工讀生費用</h2>
          <table cellSpacing="1" cellPadding="8">
            <thead>
              <tr>
                <td>#</td>
                <td>工讀內容</td>
                <td>時數</td>
                <td>時薪</td>
                <td>合計</td>
              </tr>
            </thead>
            <tbody>
              {budgets.map((value, i) => (
                <tr key={value.id}>
                  <td>{i + 1}</td>
                  <td>{value.detail}</td>
                  <td>{value.hours}</td>
                  <td>{value.salary}</td>
                  <td>{value.salary * value.hours}</td>
                </tr>
              ))}
              <tr>
                <td colSpan={6} style={{ textAlign: "right" }}>
                  總合 : {budgets.reduce((a, b) => a + b.salary * b.hours, 0)}元
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </MuiSpin>
  );
};

export default PrintBudget;
