import { Budget, OtherBudget } from "@/types";

import axios from "./axiosService";

const URLS = {
  BUDGET: "/budget",
  OTHER_BUDGET: "/otherBudget",
};

//其他預算
export const getOtherBudgets = (userId: string) => {
  return axios.get(`${URLS.OTHER_BUDGET}/${userId}`);
};

export const postOtherBudget = (value: any, userId: string) => {
  return axios.post(`${URLS.OTHER_BUDGET}/${userId}`, value);
};

export const deleteOtherBudget = (id: number) => {
  return axios.delete(`${URLS.OTHER_BUDGET}/${id}`);
};

export const putOtherBudget = (value: OtherBudget, id: number) => {
  return axios.put(`${URLS.OTHER_BUDGET}/${id}`, value);
};

/**
 * 取得總金額
 * @param userId
 */
export const getOtherBudgetsCount = (userId: string) => {
  return axios.get(`${URLS.OTHER_BUDGET}/count/${userId}`);
};

//工讀預算
export const getBudgets = (userId: string) => {
  return axios.get(`${URLS.BUDGET}/${userId}`);
};

export const postBudgets = (value: any, userId: string) => {
  return axios.post(`${URLS.BUDGET}/${userId}`, value);
};

export const deleteBudget = (id: number) => {
  return axios.delete(`${URLS.BUDGET}/${id}`);
};

export const putBudget = (budget: Budget, id: number,userId:string) => {
  return axios.put(`${URLS.BUDGET}/${id}/${userId}`, budget);
};

/**
 * 取得總金額(薪資)
 * @param userId 
 */
export const getBudgetCount = (userId: string) => {
  return axios.get(`${URLS.BUDGET}/count/${userId}`);
};
