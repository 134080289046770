import React, { memo } from "react";
import { Button, Dialog, DialogContent, DialogProps, DialogTitle } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import OtherTable from "./OtherTable";
import PartTimeTable from "./PartTimeTable";

type BudgetDialogProps = {
  title: string;
  userId: string;
  mode: "part-time" | "other" | "both";
};

/**
 * 預算的dialog，透過字串切換
 * @param props
 */
const BudgetDialog: React.FC<DialogProps & BudgetDialogProps> = ({
  mode,
  title,
  userId,
  open,
  onClose,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {title}
        <Button
          style={{
            position: "absolute",
            right: 10,
            top: 10,
          }}
          onClick={onClose as any}
        >
          <Close />
        </Button>
      </DialogTitle>
      <DialogContent>
        {mode === "other" ? (
          <OtherTable userId={userId} />
        ) : mode === "part-time" ? (
          <PartTimeTable userId={userId} />
        ) : null}
        {mode === "both" ? (
          <React.Fragment>
            <fieldset>
              <legend>研究耗材與其他費用</legend>
              <OtherTable userId={userId} />
            </fieldset>
            <br />
            <fieldset>
              <legend>工讀生費用</legend>
              <PartTimeTable userId={userId} />
            </fieldset>
          </React.Fragment>
        ) : null}
      </DialogContent>
    </Dialog>
  );
};

export default memo(BudgetDialog);
