import { Setting } from "@/types/setting";
import { SETTING_ACTION, SettingActionTypes } from "@/action/settingAction";

type SettingState = Setting;

const init: SettingState = {
  minSalary: 0,
  needPw: false,
  lastYearDeadline: new Date(),
  lastYearStartDate: new Date(),
  reviewerDeadline: new Date(),
  reviewerStartDate: new Date(),
  submitDeadline: new Date(),
  submitStartDate: new Date(),
  year: 0,
  projectReadme: "",
  reportReadme: "",
  currentState: 0,
};

export default (state = init, action: SettingActionTypes) => {
  switch (action.type) {
    case SETTING_ACTION.RECEIVE_SETTING:
      let newState = { ...state };

      action.payload.forEach(({ name, value }) => {
        (newState as any)[name[0].toLowerCase() + name.substring(1, name.length)] = value as any;
      });

      return newState;
    default:
      return state;
  }
};
