import React, { memo } from "react";
import { useSelector } from "react-redux";
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";

import { StoreTypes } from "@/store";

import { ScoreTableRow } from "..";

const ScoreTable: React.FC = () => {
  const {
    scoreSheet: { scoreArray, scoreClass },
  } = useSelector((state: StoreTypes) => state.reviewer.current);

  return (
    <Paper elevation={0}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>研究計畫審查項目</TableCell>
            <TableCell align="center" style={{ width: "70px" }}>
              權重
            </TableCell>
            <TableCell align="center">劣</TableCell>
            <TableCell align="center">可</TableCell>
            <TableCell align="center">良</TableCell>
            <TableCell align="center">佳</TableCell>
            <TableCell align="center">優</TableCell>
            <TableCell align="center" style={{ width: "70px" }}>
              得分
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {scoreClass.map((score, i) => (
            <ScoreTableRow
              key={score.id}
              id={score.id}
              index={i}
              title={score.name}
              weight={score.weight}
            />
          ))}
          <TableRow>
            <TableCell colSpan={8} align="right">
              總分：
            </TableCell>
            <TableCell align="center">
              {scoreArray.length > 0 && scoreArray.reduce((a, b) => a + b.value, 0)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

export default memo(ScoreTable);
