import React, { createContext, memo, useEffect, useState } from "react";

import {
  BudgetDialog,
  FileDialog,
  MuiSpin,
  ReviewerDialog,
  RpAlert,
  TeacherScoreInfoDialog,
} from "@/components";
import { getAllProject, postRejectProject } from "@/services/projectService";
import { InReviewer, InReviewerMsg, Project } from "@/types";

import PrintDialog from "./PrintDialog";
import ProjectStatus from "./ProjectStatus";
import ProjectTable from "./ProjectTable";
import ReviewerAssignDialog from "./ReviewerAssignDialog";
import OutReviewerAssignDialog from "@/components/OutReviewer/AssignDialog";
import PrintBudgetDialog from "./PrintBudgetDialog";

type DialogType =
  | "budget"
  | "out-reviewer-assign"
  | "reviewer-assign"
  | "file"
  | "teacherScoreInfo"
  | "print"
  | "print-budget"
  | "none";

type ProjectContextType = {
  /**
   * 第幾頁
   */
  page: number;
  /**
   * 每頁數量
   */
  rowPerPage: number;

  /**
   * 所有計畫(已處理)
   */
  currentProjects: Project[];

  /**
   * 所有計畫(未處理)
   */
  projects: Project[];

  /**
   * 目前計畫
   */
  currentProject: Project;

  /**
   * 目前內審委員
   */
  currentInReviewer: InReviewer;

  /**
   * 內審訊息
   */
  currentInReviewerMsgs: InReviewerMsg[];

  /**
   * 當前內審順序
   */
  currentOrder: number;

  updateReviewerState: boolean;

  getProjectsData: () => void;

  setCurrentProjects: (project: Project[]) => void;

  setCurrentInReviewer: (inReviewer: InReviewer) => void;

  setCurrentOrder: (order: number) => void;

  setCurrentInReviewerMsgs: (inReviewerMsg: InReviewerMsg[]) => void;

  /**
   * 更新ReviewerState組件，暫時想不到別的方法...
   */
  setUpdateReviewerState: (value: boolean) => void;

  /**
   * 變更當前計畫
   */
  handleChangeProject: (project: Project, dialogType: DialogType) => void;

  /**
   * 退回計畫(非刪除)
   */
  handleReject: (userId: string) => void;

  /**
   * 頁數變動
   */
  onPageChange: (page: number) => void;

  /**
   * 每頁數量變動
   */
  onRowPerPageChange: (rowPerPage: number) => void;
};

//給子組件用的project context
export const ProjectContext = createContext<ProjectContextType>({} as any);

const ProjectManager: React.FC = () => {
  const [dialogType, setDialogType] = useState<DialogType>("none");
  const [loading, setLoading] = useState(false);
  const [currentProjects, setCurrentProjects] = useState<Project[]>([]); //當前所有計畫(有條件過濾過的)
  const [projects, setProjects] = useState<Project[]>([]); //所有計畫
  const [currentProject, setCurrentProject] = useState<Project>({} as Project); //當前計畫
  const [page, setPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [currentInReviewer, setCurrentInReviewer] = useState({} as InReviewer); // 當前內審;
  const [currentOrder, setCurrentOrder] = useState(0);
  const [currentInReviewerMsgs, setCurrentInReviewerMsgs] = useState<InReviewerMsg[]>([]);
  const [updateReviewerState, setUpdateReviewerState] = useState(false);

  const handlePageChange = (page: number) => setPage(page);

  const handleRowPerPageChange = (rowPerPage: number) => {
    setPage(1);
    setRowPerPage(rowPerPage);
  };

  useEffect(() => {
    setPage(1);
  }, [currentProjects]);

  /**
   * 取得所有計畫
   */
  const getProjectsData = () => {
    setLoading(true);
    getAllProject().then((res) => {
      setProjects(res.data);
      setCurrentProjects(res.data);
      setLoading(false);
    });
  };

  const handleClose = () => setDialogType("none");

  /**
   * 改變當前計畫的dialog
   * @param project
   * @param dialogType
   */
  const handleChangeProject = (project: Project, dialogType: DialogType) => {
    //設定當前計畫
    setCurrentProject(project);

    //開啟Dialog
    setDialogType(dialogType);
  };

  /**
   * 退回
   * @param userId
   */
  const handleReject = (userId: string) => {
    if (!window.confirm("確定要退回嗎？")) {
      return;
    }

    postRejectProject(userId).then((res) => {
      res.data.success ? RpAlert.show("已退回") : RpAlert.error("退回失敗，請重試！");
      getProjectsData();
    });
  };

  useEffect(() => {
    getProjectsData();
  }, []);

  useEffect(() => {
    console.log(updateReviewerState);
  }, [updateReviewerState]);

  return (
    <ProjectContext.Provider
      value={{
        page,
        rowPerPage,
        currentProjects,
        projects,
        currentProject,
        currentInReviewer,
        currentInReviewerMsgs,
        currentOrder,
        updateReviewerState,
        getProjectsData,
        setCurrentProjects,
        setCurrentInReviewer,
        setCurrentInReviewerMsgs,
        setCurrentOrder,
        setUpdateReviewerState,
        handleChangeProject,
        handleReject,
        onPageChange: handlePageChange,
        onRowPerPageChange: handleRowPerPageChange,
      }}
    >
      <MuiSpin spinning={loading}>
        <ProjectStatus />
        <ProjectTable />

        <ReviewerAssignDialog open={dialogType === "reviewer-assign"} onClose={handleClose} />
        <OutReviewerAssignDialog
          open={dialogType === "out-reviewer-assign"}
          onClose={handleClose}
        />

        <FileDialog open={dialogType === "file"} onClose={handleClose} />

        <TeacherScoreInfoDialog
          open={dialogType === "teacherScoreInfo"}
          name={currentProject.userName}
          userId={currentProject.userId}
          onClose={handleClose}
        />

        <BudgetDialog
          open={dialogType === "budget"}
          title={`預算（${currentProject.userName}）`}
          mode="both"
          userId={currentProject.userId}
          onClose={handleClose}
        />

        {/* <ReviewerDialog open={dialogType === "reviewer"} onClose={handleClose} /> */}

        <PrintDialog open={dialogType === "print"} onClose={handleClose} />
        <PrintBudgetDialog open={dialogType === "print-budget"} onClose={handleClose} />
      </MuiSpin>
    </ProjectContext.Provider>
  );
};

export default memo(ProjectManager);
