import { PROJECT_ACTION, ProjectActionTypes } from "@/action/projectAction";
import { MostGroup, Project, ProjectType } from "@/types";

type ProjectState = {
  isLoading: boolean;
  data: Project;
  hasProject: boolean;
  projectTypes: ProjectType[];
  mostGroup: MostGroup[];
  files: string[];
};

const init: ProjectState = {
  isLoading: true,
  data: {
    userId: "",
    userName: "",
    most: "",
    name: "",
    ext: "",
    phone: "",
    property: "",
    type: "",
    hasMostProject: "",
    isNewTeacher: false,
    appointmentDate: new Date(),
    iucRadio: 0,
    iucMoneyOrPoint: 0,
    iucManageMoney: 0,
    submit: false,
    date: new Date(),
    personalType: 0,
  },
  hasProject: false,
  projectTypes: [],
  mostGroup: [],
  files: [],
};

export default (state = init, action: ProjectActionTypes): ProjectState => {
  switch (action.type) {
    case PROJECT_ACTION.REQUEST_PROJECT:
      return {
        ...state,
        isLoading: true,
      };
    case PROJECT_ACTION.RECEIVE_PROJECT:
      return {
        ...state,
        isLoading: false,
        data: action.payload.project,
        hasProject: true,
      };
    case PROJECT_ACTION.REQUEST_PROJECT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case PROJECT_ACTION.RESET_PROJECT:
      return init;
    case PROJECT_ACTION.SET_MOST_GROUP:
      return {
        ...state,
        mostGroup: action.payload.mostGroup,
      };
    case PROJECT_ACTION.SET_PROJECT_TYPE:
      return {
        ...state,
        projectTypes: action.payload.projectTypes,
      };
    case PROJECT_ACTION.RECEIVE_FILES:
      return {
        ...state,
        files: action.payload,
      };
    default:
      return state;
  }
};
