import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";

import { RpMust } from "@/constants";

type DialogProps = {
  open: boolean;
  onClose: () => void;
};

/**
 * 忘記密碼的Dialog
 * @param props
 */
const ForgetDialog: React.FC<DialogProps> = ({ open, onClose }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>無法登入？</DialogTitle>
    <DialogContent>
      請確認電子郵件及密碼是否輸入錯誤 <br />
      如確認無誤請去學校官網登入一次教職員郵件
      <a href="http://mail.must.edu.tw/" rel="noopener noreferrer" target="_blank">
        http://mail.must.edu.tw/
      </a>
      <br />
      若學校官網的無法登入，請聯絡電算中心，
      <br />
      若學校官網可正常登入，請聯絡
      <br />
      {`${RpMust.contact.unit} ${RpMust.contact.name} ${RpMust.contact.tel}`}。
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary" autoFocus>
        知道了
      </Button>
    </DialogActions>
  </Dialog>
);

export default ForgetDialog;
