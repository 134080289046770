import React from "react";
import { Form, Input } from "antd";

type OnlyNumberProps = {
  name?: string;
  label?: string;
  ext?: boolean;
  phone?: boolean;
};

const OnlyNumber: React.FC<OnlyNumberProps> = ({ name, label, ext, phone }) => {
  return (
    <Form.Item
      name={name}
      label={label}
      rules={[
        { required: true },
        {
          validator: (_, value: string) => {
            if (!value) {
              return Promise.reject();
            } else if (isNaN(Number(value))) {
              return Promise.reject("請輸入數字");
            } else if (ext && (value.length <= 0 || value.length > 4)) {
              return Promise.reject("請輸入正確的分機");
            } else if (phone) {
              const pattern = /^09\d{2}-?\d{3}-?\d{3}$/;
              if (!pattern.test(value)) {
                return Promise.reject("電號格式錯誤");
              }
            }

            return Promise.resolve();
          },
        },
      ]}
    >
      <Input autoComplete="on" />
    </Form.Item>
  );
};

export default OnlyNumber;
