import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router";

import { ProjectAdd, ProjectManager, ProjectPrint, ProjectReadme, ProjectViewer } from "@/views";
import { AuthorizeRoute, Loading } from "@/components";
import { ROUTES } from "@/constants";

const ProjectRouter: React.FC = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <AuthorizeRoute path={ROUTES.PROJECT.README} component={ProjectReadme} />
        <AuthorizeRoute path={ROUTES.PROJECT.ADD} component={ProjectAdd} />
        <AuthorizeRoute path={ROUTES.PROJECT.VIEWER} component={ProjectViewer} />
        <AuthorizeRoute path={ROUTES.PROJECT.PRINT} component={ProjectPrint} />
        <AuthorizeRoute
          path={`${ROUTES.PROJECT.MANAGER}/:tabKey?`}
          component={ProjectManager}
        />

        <Redirect to={ROUTES.PROJECT.README} from={ROUTES.PROJECT.INDEX} />
      </Switch>
    </Suspense>
  );
};

export default ProjectRouter;
