import * as Yup from "yup";
import React from "react";
import { ErrorMessage, Formik, FormikHelpers } from "formik";
import { Button, Card, CardContent, CardHeader, TextField } from "@material-ui/core";

import { RpAlert } from "@/components";

type ProjectTypeInputType = {
  onSubmit: (name: string) => Promise<boolean>;
};

const ProjectTypeInput: React.FC<ProjectTypeInputType> = ({ onSubmit }) => {
  const handleSubmit = async (
    value: any,
    { resetForm }: FormikHelpers<{ name: string }> // 這裡放要submit後的動作
  ) => {
    const success = await onSubmit(value.name);
    if (success) {
      RpAlert.show("新增成功！");
      resetForm();
    } else {
      RpAlert.error("新增失敗！");
    }
  };

  return (
    <Card>
      <CardHeader title={<h5>新增計畫類型</h5>} />
      <CardContent>
        <Formik
          initialValues={{
            name: "任務型-",
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("必填").trim(),
          })}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit, getFieldProps }) => (
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                {...getFieldProps("name")}
              />
              <div
                style={{ color: "red", textAlign: "center", padding: "5px" }}
              >
                <ErrorMessage name="name" />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <Button variant="contained" color="primary" type="submit">
                  新增
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default ProjectTypeInput;
