import { combineReducers } from "redux";

import authReducer from "./authReducer";
import budgetReducer from "./budgetReducer";
import projectReducer from "./projectReducer";
import reviewerReducer from "./reviewerReducer";
import settingReducer from "./settingReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  project: projectReducer,
  budget:budgetReducer,
  reviewer:reviewerReducer,
  setting:settingReducer
});

export default rootReducer;
