import * as Yup from "yup";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Redirect, useHistory } from "react-router-dom";
import { ErrorMessage, Formik } from "formik";
import {
  Button,
  Container,
  createMuiTheme,
  CssBaseline,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
} from "@material-ui/core";

import { useQuery, useTitle } from "@/hooks";
import { login, loginReviewer } from "@/services/loginService";
import { loginFailed, loginSuccess, loginSuccessReviewer, setUserInfo } from "@/action/authAction";
import { StoreTypes } from "@/store";
import { Role } from "@/types";
import { ROUTES, RpMust } from "@/constants";
import { MuiSpin, RpAlert } from "@/components";
import { postOutReviewerLogin } from "@/services/outReviewerService";

import ForgetDialog from "./ForgetDialog";
import LoginFailedDialog from "./LoginFailedDialog";
import UserIdInputDialog from "./UserIdInputDialog";
import { useSettings } from "@/hooks/useSettings";

type Color = {
  primary: string;
  secondary: string;
};

type AllColor = { [key in Role]: Color };

const colors: AllColor = {
  Admin: {
    primary: "#ef5350",
    secondary: "#b71c1c",
  },
  Reviewer: {
    primary: "#7e57c2",
    secondary: "#5e35b1",
  },
  User: {
    primary: "#2196f3",
    secondary: "#f50057",
  },
};

const useStyles = makeStyles((theme) => ({
  paper: {
    paddingTop: "5em",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const LoginMaterial: React.FC = () => {
  const type = useQuery("type") as Role;
  const [color, setColor] = useState<Color>({
    primary: colors.User.primary,
    secondary: colors.User.secondary,
  });
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [first, setFirst] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [failOpen, setFailOpen] = useState(false); //登入失敗open
  const [role, setRole] = useState<Role>("User");
  const [accountLabel, setAccountLabel] = useState("教職員信箱，可省略@must.edu.tw");
  const { isAuthenticated } = useSelector((state: StoreTypes) => state.auth);
  const history = useHistory();
  const dispatch = useDispatch();
  const toUrl = useQuery("url");
  const classes = useStyles();
  const { canSubmitProject, canSubmitLastYearProject } = useSettings();
  const loginFailCount = useRef(0); //紀錄登入失敗次數

  useTitle("登入");

  useEffect(() => {
    if (!type || type.length < 2) {
      return;
    }
    const correctRole = (type.substring(0, 1).toUpperCase() + type.substring(1)) as Role;

    if (correctRole === "Admin" || correctRole === "Reviewer" || correctRole === "User") {
      setRole(correctRole);
      setAccountLabel(correctRole === "User" ? "教職員信箱，可省略@must.edu.tw" : "帳號");
      setColor(colors[correctRole]);
    }
  }, [type]);

  //防止通過驗證後進入此頁面
  if (isAuthenticated) {
    if (canSubmitProject) {
      return <Redirect to={ROUTES.PROJECT.README} />;
    } else if (canSubmitLastYearProject) {
      return <Redirect to={ROUTES.REPORT.INDEX} />;
    }
  }

  const theme = createMuiTheme({
    palette: {
      primary: { main: color.primary },
      secondary: { main: color.secondary },
    },
    typography: {
      fontFamily: "微軟正黑體",
    },
  });

  const handleClickOpen = () => setOpen(true);

  const handleClickClose = () => setOpen(false);

  const handleLoginFailedClose = () => setFailOpen(false);

  /**
   * 改變角色
   * @param event
   */
  const handleChangeRole = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newRole: Role = event.target.value as Role;
    setRole(newRole);
    setAccountLabel(newRole === "User" ? "教職員信箱，可省略@must.edu.tw" : "帳號");
    //設定切換身分時的顏色
    setColor(colors[newRole]);
  };

  /**
   * 登入
   */
  const handleLogin = (values: any) => {
    setLoading(true);
    setEmail(values.email);
    setPassword(values.password);

    if (role === "Reviewer") {
      // 外審
      if ((values.email as string).includes("o-") || (values.email as string).includes("e-")) {
        postOutReviewerLogin(values).then((res) => {
          //登入失敗
          if (!res.data.success) {
            RpAlert.error(res.data.message);
            setLoading(false);
            return;
          }

          //登入成功
          dispatch(loginSuccessReviewer(res.data));
          history.replace(`${ROUTES.REVIEWER.OUT_REVIEWER.INDEX}`);
        });
        return;
      }

      // 內審
      loginReviewer(values).then((res) => {
        //登入失敗
        if (!res.data.success) {
          RpAlert.error(res.data.message);
          setLoading(false);
          return;
        }

        //登入成功
        dispatch(loginSuccessReviewer(res.data));
        history.replace(`${ROUTES.REVIEWER.IN_REVIEWER.INDEX}/${res.data.reviewer.id}`);
      });

      return;
    }

    login(values)
      .then((res) => {
        if (res.data.success) {
          //登入成功
          dispatch(loginSuccess());
          dispatch(setUserInfo(res.data));

          // 導向query的url
          history.replace(
            toUrl
              ? toUrl
              : role === "Admin" && (res.data.roles as Role[]).includes("Admin")
              ? ROUTES.ADMIN.INDEX
              : canSubmitProject
              ? ROUTES.PROJECT.README
              : ROUTES.REPORT.INDEX
          );
        } else {
          //登入失敗
          setLoading(false);

          if (res.data.first) {
            setFirst(true);
            return;
          }

          RpAlert.error(res.data.message);

          if (++loginFailCount.current >= 3) {
            setFailOpen(true);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        dispatch(loginFailed());
        RpAlert.error(String(err));
      });
  };

  return (
    <MuiSpin spinning={loading}>
      <UserIdInputDialog
        open={first}
        email={email}
        password={password}
        loading={loading}
        onClose={() => setFirst(false)}
        onLogin={handleLogin}
      />
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <h1>
              <NavLink to="/">{RpMust.title}</NavLink>
            </h1>
            <Formik
              initialValues={{
                email: "",
                password: "",
                userId: "",
              }}
              validationSchema={Yup.object({
                email: Yup.string().required("必填"),
                password: Yup.string().required("必填"),
              })}
              onSubmit={handleLogin}
            >
              {(props) => (
                <form className={classes.form} onSubmit={props.handleSubmit}>
                  <div>
                    <TextField
                      variant="outlined"
                      disabled={loading}
                      margin="normal"
                      size="small"
                      fullWidth
                      label={accountLabel}
                      autoComplete="email"
                      autoFocus
                      {...props.getFieldProps("email")}
                    />
                    <div style={{ color: "red" }}>
                      <ErrorMessage name="email" />
                    </div>
                  </div>

                  <div>
                    <TextField
                      variant="outlined"
                      disabled={loading}
                      margin="normal"
                      size="small"
                      fullWidth
                      label="密碼"
                      type="password"
                      autoComplete="password"
                      {...props.getFieldProps("password")}
                    />
                    <div style={{ color: "red" }}>
                      <ErrorMessage name="password" />
                    </div>
                  </div>

                  <Button
                    type="submit"
                    disabled={loading}
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    登入
                  </Button>
                  <Grid container>
                    <Grid item xs>
                      <Button color="primary" onClick={handleClickOpen}>
                        無法登入？
                      </Button>
                    </Grid>
                    <Grid>
                      <FormControl size="small" variant="outlined">
                        <InputLabel id="role-select-label">身分組</InputLabel>
                        <Select
                          labelId="role-select-label"
                          id="role-select"
                          value={role}
                          onChange={handleChangeRole}
                          label="身分組"
                        >
                          <MenuItem value="User">一般教職員</MenuItem>
                          <MenuItem value="Reviewer">審查委員</MenuItem>
                          <MenuItem value="Admin">管理員</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </div>

          {/* 無法登入Dialog */}
          <ForgetDialog open={open} onClose={handleClickClose} />

          {/* 登入失敗太多次的Dialog */}
          <LoginFailedDialog
            open={failOpen}
            count={loginFailCount.current}
            onClose={handleLoginFailedClose}
          />
        </Container>
      </ThemeProvider>
    </MuiSpin>
  );
};

export default LoginMaterial;
