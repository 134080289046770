import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { Close, Done } from "@material-ui/icons";

import { getCheckErrors, getTeacherScoreTotal, postSubmitProject } from "@/services/projectService";
import { requestProject } from "@/action/projectAction";
import { useRpMustUser } from "@/hooks";
import { ROUTES } from "@/constants";

import ZeroTipDialog from "../TeacherScore/ZeroTipDialog";
import SubmitErrorsDialog from "./SubmitErrorsDialog";
import classes from "./SubmitProject.module.scss";
import { RpAlert } from "..";

const SubmitProject: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [errorsOpen, setErrorsOpen] = useState<"error" | "zeroTip" | "">("");
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [errorsLoading, setErrorsLoading] = useState(true);
  const { userId } = useRpMustUser();
  const dispatch = useDispatch();
  const history = useHistory();

  /**
   * 送出前驗證
   */
  const handleBeforeSubmit = async () => {
    let hasError = false;
    setLoading(true);
    setSubmitLoading(true);
    setErrorsLoading(true);
    await getCheckErrors(userId).then((res) => {
      //沒錯誤就設回預設值
      if (res.data.length > 0) {
        setErrors(res.data);
        setErrorsOpen("error");
        hasError = true;
      } else {
        setErrors([]);
      }
      setErrorsLoading(false);
      setLoading(false);
    });

    if (hasError) {
      return;
    }

    //檢查績效是否為0分
    // await getTeacherScoreTotal(userId).then(({ data }) => {
    //   if (data === 0) {
    //     setErrorsOpen("zeroTip");
    //   } else {
    //     setOpen(true);
    //     setErrorsOpen("");
    //   }
    // });
    setOpen(true);
  };

  const handleClick = () => {
    setLoading(true);
    postSubmitProject(userId).then((res) => {
      setLoading(false);
      if (res.data.success) {
        RpAlert.show("送出成功！");
        history.replace(ROUTES.PROJECT.VIEWER);

        dispatch(requestProject(userId));
      } else {
        RpAlert.error("送出失敗，請重新整理後再試一次。");
        setOpen(false);
      }
    });
  };

  const handleClose = () => {
    setSubmitLoading(false);
    setErrorsOpen("");
  };

  const renderDialog = () => (
    <Dialog disableEscapeKeyDown open={open}>
      <DialogTitle>提示</DialogTitle>
      <DialogContent>確定要送出嗎？</DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          disabled={loading}
          startIcon={<Close />}
          onClick={() => {
            setSubmitLoading(false);
            setOpen(false);
          }}
        >
          再想想
        </Button>
        <Button color="primary" disabled={loading} startIcon={<Done />} onClick={handleClick}>
          送出
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <React.Fragment>
      {renderDialog()}

      <SubmitErrorsDialog
        open={errorsOpen === "error"}
        errors={errors}
        loading={errorsLoading}
        onClose={handleClose}
        onConfirm={handleClose}
      />

      {/* 提醒績效0分 */}
      <ZeroTipDialog
        open={errorsOpen === "zeroTip"}
        goto={() => history.push(ROUTES.PROJECT.MANAGER + "/score")}
        disableEscapeKeyDown
        onClose={handleClose}
        onConfirm={() => {
          setErrorsOpen("");
          setOpen(true);
        }}
      />

      <Container style={{ display: "flex", justifyContent: "center" }}>
        <ul className={classes.list}>
          <li>目前計畫尚未送出，可進行計畫內容之編修。</li>
          <li>按下『送出計畫』才算正式申請！</li>
          <li>一旦按下『送出計畫』則不能再編輯計畫資料了。</li>
          <li>送出計畫後，列印申請表的按鈕才會出現。</li>
          <li>若無法成功送出計畫，請依照彈出視窗的指示修正計畫內容。</li>
        </ul>
      </Container>
      <Typography align="center">
        <Button
          variant="contained"
          color="primary"
          disabled={submitLoading}
          onClick={handleBeforeSubmit}
        >
          送出計畫
        </Button>
      </Typography>
    </React.Fragment>
  );
};

export default SubmitProject;
