import { InReviewer, InReviewerMsg, ScoreClass } from "@/types";

import axios from "./axiosService";

const URLS = {
  IN_REVIEWER: {
    INDEX: "/inReviewer",
    LOGIN: "/inReviewer/login",
    MSG: {
      INDEX: "/inReviewer/msg",
      ASSIGN: "/inReviewer/msg/assign/",
    },
    SCORE: {
      INDEX: "/inReviewer/score/",
      CLASS: "/inReviewer/score/class",
    },
  },
};

/**
 * 取得內審委員(單筆)
 * @param id
 */
export const getInReviewer = (id: string) => {
  return axios.get<InReviewer>(URLS.IN_REVIEWER.INDEX + "/" + id);
};

/**
 * 取得所有內審訊息(從內審委員)
 * @param inReviewerId
 */
export const getInReviewerMsgs = (inReviewerId: number) => {
  return axios.get(URLS.IN_REVIEWER.MSG.INDEX + "/" + inReviewerId);
};

/**
 * 取得所有內審委員
 */
export const getInReviewers = (showPassword: boolean = true) => {
  return axios.get<InReviewer[]>(URLS.IN_REVIEWER.INDEX, {
    params: {
      // 是否顯示密碼
      p: showPassword ? "show" : " ",
    },
  });
};

/**
 * 取得內審委員訊息(單筆)
 * @param data
 */
export const getInReviewerMsg = (data: {
  userId: string;
  order: number;
  inReviewerId: number;
}) => {
  return axios.get<InReviewerMsg>(URLS.IN_REVIEWER.MSG.INDEX, { params: data });
};

/**
 * 取得使用者的內審委員審核訊息
 * @param userId
 */
export const getInReviewerMsgsFromUserId = (userId: string) => {
  return axios.get<InReviewerMsg[]>(
    URLS.IN_REVIEWER.MSG.INDEX + "/user/" + userId
  );
};

/**
 * 更改內審委員審核訊息
 * @param value
 */
export const postInReviewerMsg = (value: any) => {
  return axios.post(URLS.IN_REVIEWER.MSG.ASSIGN, value);
};

/**
 * 新增內審委員
 * @param value
 */
export const postInReviewer = (value: InReviewer) => {
  return axios.post(URLS.IN_REVIEWER.INDEX, value);
};

/**
 * 修改內審委員
 * @param value
 */
export const putInReviewer = (value: InReviewer) => {
  return axios.put(URLS.IN_REVIEWER.INDEX, value);
};

/**
 * 刪除內審委員
 * @param id
 */
export const deleteInReviewer = (id: number) => {
  return axios.delete(URLS.IN_REVIEWER.INDEX + "/" + id);
};

/**
 * 取得所有內審項目
 */
export const getInReviewerScoreClass = () => {
  return axios.get<ScoreClass[]>(URLS.IN_REVIEWER.SCORE.CLASS);
};

/**
 * 新增內審分數
 * @param value
 */
export const postInReviewerScore = (value: any) => {
  return axios.post(URLS.IN_REVIEWER.SCORE.INDEX, value);
};

/**
 * 取得內審分數
 */
export const getInReviewerScore = (params: {
  userId: string;
  order: number;
  inReviewerId: number;
}) => {
  return axios.get(URLS.IN_REVIEWER.SCORE.INDEX, { params });
};

/**
 * 修改內審分數
 * @param value
 */
export const putInReviewerScore = (value: any) => {
  return axios.put(URLS.IN_REVIEWER.SCORE.INDEX, value);
};
