import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HelpIcon from "@material-ui/icons/Help";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import React, { createContext, useContext, useEffect, useState } from "react";
import {
  Button,
  createMuiTheme,
  createStyles,
  List,
  ListItem,
  makeStyles,
  Theme,
  ThemeProvider,
  Tooltip,
} from "@material-ui/core";
import { BsQuestion } from "react-icons/bs";
import { useSelector } from "react-redux";

import { getInReviewerMsgsFromUserId } from "@/services/inReviewerService";
import { InReviewer, InReviewerMsg, OutReviewer, Project } from "@/types";
import { StoreTypes } from "@/store";
import { Loading } from "@/components";

import classes from "./style.module.scss";
import { ProjectContext } from "./ProjectManager";
import { getOutReviewerMsgFromProject, getOutReviewers } from "@/services/outReviewerService";
import { OutReviewerMsg } from "@/components/OutReviewer/Table";

type ReviewerContextType = {
  userId: string;
  disabled: boolean;
};

const ReviewerContext = createContext<ReviewerContextType>({} as any);

const ReviewerItem: React.FC<{ msg: InReviewerMsg | null; order: number }> = ({ msg, order }) => {
  const { inReviewers } = useSelector((state: StoreTypes) => state.reviewer);
  const { projects, setCurrentOrder, setCurrentInReviewer, handleChangeProject } = useContext(
    ProjectContext
  );
  const { disabled, userId } = useContext(ReviewerContext);
  const [color, setColor] = useState(() => {
    if (!msg?.lastDate) {
      return "#009be5";
    }
    return msg.ok ? "#689f38" : "#d60924";
  });

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: color,
      },
      secondary: {
        main: "#827717",
      },
    },
  });

  const handleClick = () => {
    handleChangeProject(projects.find((p) => p.userId === userId) as Project, "reviewer-assign");
    setCurrentInReviewer(inReviewers.find((i) => i.inReviewerId === msg?.reviewerId) as InReviewer);
    setCurrentOrder(order + 1);
  };

  const renderIcon = (): JSX.Element => {
    if (disabled || !msg) {
      return <React.Fragment />;
    }

    if (!msg.lastDate) {
      return <HelpIcon />;
    } else {
      return msg.ok ? <CheckCircleIcon /> : <RemoveCircleIcon />;
    }
  };

  return (
    <ListItem>
      <div>內審 - {order + 1}</div>
      <div>
        <ThemeProvider theme={theme}>
          <Tooltip
            title={
              msg && msg.lastDate ? "查看審查結果" : msg && !msg.lastDate ? "審查委員尚未審查" : ""
            }
          >
            <Button
              className={classes.root}
              color={msg ? "primary" : "default"}
              variant="contained"
              size="small"
              disabled={disabled}
              onClick={handleClick}
              endIcon={renderIcon()}
            >
              {disabled && "無法指派"}
              {msg
                ? inReviewers.find((val) => val.inReviewerId === msg.reviewerId)?.name
                : disabled
                ? ""
                : "未指派"}
            </Button>
          </Tooltip>
        </ThemeProvider>
      </div>
    </ListItem>
  );
};

const OutReviewerItem: React.FC = () => {
  const { projects, updateReviewerState, handleChangeProject } = useContext(ProjectContext);
  const [msg, setMsg] = useState<OutReviewerMsg>();
  const [or, setOr] = useState<OutReviewer[]>([]);

  const { userId } = useContext(ReviewerContext);

  useEffect(() => {
    (async () => {
      const ors = await getOutReviewers();
      const msg = await getOutReviewerMsgFromProject(userId);
      setOr(ors.data);
      setMsg(msg.data);
    })();
  }, [userId, updateReviewerState]);

  return (
    <ListItem>
      <div style={{ marginRight: 31 }}>外審</div>
      <div>
        <Tooltip title="外審委員指派">
          <Button
            className={classes.root}
            color={msg ? "primary" : "default"}
            variant="contained"
            size="small"
            onClick={() => {
              handleChangeProject(
                projects.find((p) => p.userId === userId) as Project,
                "out-reviewer-assign"
              );
            }}
          >
            {msg ? or.find((val) => val.outReviewerId === msg.outReviewerId)?.name : "未指派"}
          </Button>
        </Tooltip>
      </div>
    </ListItem>
  );
};

/**
 * 審查委員狀態
 */
const ReviewerState: React.FC<{ userId: string; disabled: boolean }> = ({ userId, disabled }) => {
  const [inReviewerMsgs, setInReviewerMsg] = useState<InReviewerMsg[]>([]);
  const [loading, setLoading] = useState(true);
  const { updateReviewerState } = useContext(ProjectContext);

  useEffect(() => {
    if (!disabled) {
      getInReviewerMsgsFromUserId(userId).then((res) => {
        setInReviewerMsg(res.data);
        setLoading(false);
      });
    }
    console.log("updateReviewerState update");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled, userId, updateReviewerState]);

  return (
    <ReviewerContext.Provider
      value={{
        disabled,
        userId,
      }}
    >
      {loading && !disabled ? (
        <Loading />
      ) : (
        <>
          <List className={classes.reviewerStateList}>
            {disabled
              ? [0, 1, 2].map((value) => {
                  return <ReviewerItem key={value} msg={null} order={value} />;
                })
              : inReviewerMsgs.map((value, index) => {
                  return <ReviewerItem key={index} msg={value} order={index} />;
                })}
            <OutReviewerItem />
          </List>
        </>
      )}
    </ReviewerContext.Provider>
  );
};

export default ReviewerState;
