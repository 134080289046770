import { Dispatch } from "redux";

import { getUser } from "@/services/userService";
import {
  getInReviewers,
  getInReviewerScore,
  getInReviewerScoreClass,
} from "@/services/inReviewerService";
import { getProject, getProjectFilesLink } from "@/services/projectService";
import { InReviewer, OutReviewer, Project, RpMustUser, ScoreClass } from "@/types";

export const REQUEST_IN_REVIEWER = "REQUEST_IN_REVIEWER";
export const RECEIVE_IN_REVIEWER = "RECEIVE_IN_REVIEWER";

export const REQUEST_OUT_REVIEWER = "REQUEST_OUT_REVIEWER";
export const RECEIVE_OUT_REVIEWER = "RECEIVE_OUT_REVIEWER";

export const REQUEST_CURRENT_PROJECT = "REQUEST_CURRENT_PROJECT";
export const RECEIVE_CURRENT_PROJECT = "RECEIVE_CURRENT_PROJECT";
export const RESET_CURRENT_PROJECT = "RESET_CURRENT_PROJECT";

export const REQUEST_SCORE_CLASS = "REQUEST_SCORE_CLASS";
export const RECEIVE_SCORE_CLASS = "RECEIVE_SCORE_CLASS";

export const REQUEST_SCORE_SHEET = "REQUEST_SCORE_SHEET";
export const RECEIVE_SCORE_SHEET = "RECEIVE_SCORE_SHEET";

export const SET_SCORE_ARRAY = "SET_SCORE_ARRAY";
export const SET_RECOMMEND_AND_MESSAGE = "SET_RECOMMEND_AND_MESSAGE";
export const RESET_SCORE_SHEET = "RESET_SCORE_SHEET";

type RequestInReviewer = {
  type: typeof REQUEST_IN_REVIEWER;
};

type ReceiveInReviewer = {
  type: typeof RECEIVE_IN_REVIEWER;
  payload: InReviewer[];
};

type RequestOutReviewer = {
  type: typeof REQUEST_OUT_REVIEWER;
};

type ReceiveOutReviewer = {
  type: typeof RECEIVE_OUT_REVIEWER;
  payload: OutReviewer[];
};

type RequestCurrentProject = {
  type: typeof REQUEST_CURRENT_PROJECT;
};

type ReceiveCurrentProject = {
  type: typeof RECEIVE_CURRENT_PROJECT;
  payload: {
    project: Project;
    user: RpMustUser;
    link: string;
  };
};

type ResetCurrentProject = {
  type: typeof RESET_CURRENT_PROJECT;
};

type SetScore = {
  type: typeof SET_SCORE_ARRAY;
  payload: {
    value: number;
    id: number;
    index: number;
  };
};

type SetRecommendAndMessage = {
  type: typeof SET_RECOMMEND_AND_MESSAGE;
  payload: {
    recommend: boolean;
    message?: string;
  };
};

type ResetScoreSheet = {
  type: typeof RESET_SCORE_SHEET;
};

type RequestScoreSheet = {
  type: typeof REQUEST_SCORE_SHEET;
};
type ReceiveScoreSheet = {
  type: typeof RECEIVE_SCORE_SHEET;
  payload: {
    scoreClass: ScoreClass[];
    scoreArray: any[];
    recommend: boolean;
    message: string;
    isSubmit: boolean;
  };
};

type RequestScoreClass = {
  type: typeof REQUEST_SCORE_CLASS;
};

type ReceiveScoreClass = {
  type: typeof RECEIVE_SCORE_CLASS;
  payload: ScoreClass[];
};

/**
 * 取得內審委員名單
 */
export const requestInReviewer = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: REQUEST_IN_REVIEWER,
    } as RequestInReviewer);

    await getInReviewers().then((res) => {
      dispatch({
        type: RECEIVE_IN_REVIEWER,
        payload: res.data,
      } as ReceiveInReviewer);
    });
  };
};

/**
 * 取得正在審查的計畫
 * @param userId
 */
export const requestCurrentProject = (userId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: REQUEST_CURRENT_PROJECT,
    } as RequestCurrentProject);

    //請求計畫資料
    const project = (await getProject(userId)).data;

    //請求使用者資料
    const user = (await getUser(userId)).data;

    const link = (await getProjectFilesLink(userId)).data[1];

    dispatch({
      type: RECEIVE_CURRENT_PROJECT,
      payload: {
        project,
        user,
        link,
      },
    } as ReceiveCurrentProject);
  };
};

/**
 * 清除currentProject
 */
export const resetCurrentProject = (): ResetCurrentProject => {
  return {
    type: RESET_CURRENT_PROJECT,
  };
};

/**
 * 設置分數
 * @param arr
 */
export const setScoreArray = (value: number, id: number, index: number): SetScore => {
  return {
    type: SET_SCORE_ARRAY,
    payload: {
      value,
      id,
      index,
    },
  };
};

/**
 * 是否推薦和不推薦訊息
 * @param r
 * @param m
 */
export const setRecommendAndMessage = (
  r: boolean,
  m: string | undefined
): SetRecommendAndMessage => {
  return {
    type: SET_RECOMMEND_AND_MESSAGE,
    payload: {
      recommend: r,
      message: m,
    },
  };
};

/**
 * 重置分數表
 */
export const resetScoreSheet = (): ResetScoreSheet => {
  return {
    type: RESET_SCORE_SHEET,
  };
};

/**
 * 取得分數表資料
 * @param params
 */
export const requestScoreSheet = (params: {
  inReviewerId: number;
  userId: string;
  order: number;
}) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: REQUEST_SCORE_SHEET } as RequestScoreSheet);
    getInReviewerScore(params).then((res) => {
      if (!res.data) {
        return;
      }

      dispatch({
        type: RECEIVE_SCORE_SHEET,
        payload: res.data,
      } as ReceiveScoreSheet);
    });
  };
};

export const requestScoreClass = () => {
  return (dispatch: Dispatch) => {
    dispatch({ type: REQUEST_SCORE_CLASS } as RequestScoreClass);
    getInReviewerScoreClass().then((res) => {
      //先取得scoreClass的數量，然後再給score[]初值
      dispatch({
        type: RECEIVE_SCORE_CLASS,
        payload: res.data,
      } as ReceiveScoreClass);
    });
  };
};

export type ReviewerActionTypes =
  | RequestInReviewer
  | ReceiveInReviewer
  | RequestOutReviewer
  | ReceiveOutReviewer
  | RequestCurrentProject
  | ReceiveCurrentProject
  | ResetCurrentProject
  | SetScore
  | SetRecommendAndMessage
  | ResetScoreSheet
  | RequestScoreSheet
  | ReceiveScoreSheet
  | ReceiveScoreClass;
