import React, { useRef } from "react";
import { Redirect } from "react-router";

import { ProjectResult } from "@/types";
import { ROUTES } from "@/constants";

import { Loading } from "..";

type CheckProjectProps = {
  projectResult: ProjectResult;
  mode: "add" | "manager" | "viewer" | "print";
};

/**
 * 檢查計畫，
 */
const CheckProject: React.FC<CheckProjectProps> = ({
  children,
  mode,
  projectResult,
}) => {
  const {
    project: { submit },
    hasProject,
    isLoading,
  } = projectResult;
  const ref = useRef(false);
  if (ref.current) {
    return <React.Fragment>{children}</React.Fragment>;
  } else if (isLoading) {
    return <Loading />;
  } else {
    let to = "";

    //沒有計畫
    if ((mode === "viewer" || mode === "manager") && !hasProject) {
      to = ROUTES.PROJECT.ADD;
    }
    //有計畫且已送出
    else if ((mode === "add" || mode === "manager") && hasProject && submit) {
      to = ROUTES.PROJECT.VIEWER;
    }
    //有計畫但未送出
    else if ((mode === "add" || mode === "viewer") && hasProject && !submit) {
      to = ROUTES.PROJECT.MANAGER;
    }

    ref.current = true;
    return (
      <React.Fragment>{!!to ? <Redirect to={to} /> : children}</React.Fragment>
    );
  }
};

export default CheckProject;
