import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "@material-ui/core";
import { Cascader, DatePicker, Form, Input, Radio, Select } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import { FormInstance } from "antd/lib/form";

import { RpMust } from "@/constants";
import { StoreTypes } from "@/store";
import { useIfMounted } from "@/hooks";

import { OnlyNumber } from "..";

const MonthPicker = DatePicker.MonthPicker;

const { Option } = Select;

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 15 },
};

const validateMessages = {
  required: "這是必要欄位!",
};

const trashChineseLocal = {
  lang: {
    locale: "zh_TW",
    placeholder: "入校日期",
    rangePlaceholder: ["Start date", "End date"],
    today: "Today",
    now: "Now",
    backToToday: "Back to today",
    ok: "Ok",
    clear: "Clear",
    month: "Month",
    year: "Year",
    timeSelect: "Select time",
    dateSelect: "Select date",
    monthSelect: "Choose a month",
    yearSelect: "Choose a year",
    decadeSelect: "Choose a decade",
    yearFormat: "YYYY年",
    dateFormat: "M/D/YYYY",
    dayFormat: "D",
    dateTimeFormat: "M/D/YYYY HH:mm:ss",
    monthFormat: "M月",
    monthBeforeYear: true,
    previousMonth: "Previous month (PageUp)",
    nextMonth: "Next month (PageDown)",
    previousYear: "Last year (Control + left)",
    nextYear: "Next year (Control + right)",
    previousDecade: "Last decade",
    nextDecade: "Next decade",
    previousCentury: "Last century",
    nextCentury: "Next century",
  },
  timePickerLocale: {
    placeholder: "Select time",
  },
  dateFormat: "YYYY-MM-DD",
  dateTimeFormat: "YYYY-MM-DD HH:mm:ss",
  weekFormat: "YYYY-wo",
  monthFormat: "YYYY-MM",
};

type ProjectFormProps = {
  onFinish: ((values: any) => void) | undefined;
  form: FormInstance;
};

const ProjectForm: React.FC<ProjectFormProps> = ({ form, onFinish }) => {
  const [dateValid, setDateValid] = useState<boolean>(false);
  const { projectTypes, mostGroup } = useSelector((state: StoreTypes) => state.project);
  const ifMounted = useIfMounted();

  /**
   * 是否為新進教師
   * @param e
   */
  const handleChange = (e: RadioChangeEvent) => ifMounted(() => setDateValid(e.target.value));

  return (
    <Form
      {...layout}
      form={form}
      onFinish={onFinish}
      initialValues={{ isNewTeacher: false }}
      validateMessages={validateMessages}
    >
      <OnlyNumber ext name="ext" label="校內分機" />

      <OnlyNumber phone name="phone" label="手機號碼" />

      <Form.Item name="name" label="計畫名稱" rules={[{ required: true }]}>
        <Input autoComplete="on" />
      </Form.Item>

      <Form.Item name="property" label="計畫衍生成果" rules={[{ required: true }]}>
        <Radio.Group value={2}>
          <Radio value={1}>應達成申請公部門計畫至少乙件</Radio>
          <br />
          <Radio value={2}>國內外各類論文發表總和達乙篇</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        name="type"
        label={
          <Tooltip title="任務型計畫題目">
            <a href={RpMust.url.projectType} target="_blank" rel="noopener noreferrer">
              計畫類型
            </a>
          </Tooltip>
        }
        rules={[{ required: true }]}
      >
        <Select
          showSearch
          defaultValue="請選擇"
          filterOption={(input, opt) => (opt?.title as string).indexOf(input) > -1}
          notFoundContent="無資料"
        >
          {projectTypes.map((pt) => (
            <Option key={pt.id} value={pt.id} title={pt.name}>
              {pt.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="most"
        label={
          <Tooltip title="國科會學門專長分類表">
            <a href={RpMust.url.most} target="_blank" rel="noopener noreferrer">
              學門分類
            </a>
          </Tooltip>
        }
        rules={[{ required: true }]}
      >
        <Cascader
          showSearch={{
            filter: (input, path) =>
              path.some((option) => {
                let label = option.label as string;
                return label.toLowerCase().indexOf(input.toLowerCase()) > -1;
              }),
          }}
          options={mostGroup}
          placeholder="請選擇"
          notFoundContent="無資料"
        />
      </Form.Item>

      <Form.Item name="isNewTeacher" label="是否為新進教師" rules={[{ required: true }]}>
        <Radio.Group onChange={handleChange}>
          <Radio value={true}>是 - 我是新進教師</Radio>入校日期：
          <Form.Item noStyle name="appointmentDate" rules={[{ required: dateValid }]}>
            <MonthPicker
              locale={trashChineseLocal as any}
              disabledDate={(current) => {
                const minDate = `${new Date().getFullYear() - 3}/${new Date().getMonth() + 1}`;
                const over3Year = current && current < moment(minDate, "YYYY-MM");
                const overNow = current && current > moment();

                return overNow || over3Year;
              }}
            />
          </Form.Item>
          <br />
          <br />
          <Radio value={false}>否 - 我已到校超過三年以上</Radio>
        </Radio.Group>
      </Form.Item>
    </Form>
  );
};

export default ProjectForm;
