import axios from "./axiosService";

const urls = {
  report: {
    index: "/report",
    link: "/report/link",
  },
};

/**
 * 取得所有結案報告
 */
export const getAllLYProject = () => axios.get(urls.report.index);

/**
 * 取得單筆結案報告
 * @param userId
 */
export const getLYProject = (userId: string) => axios.get(`${urls.report.index}/${userId}`);

/**
 * 判斷是否有結案報告
 * @param userId
 */
export const getHasReport = (userId: string) => axios.get(`${urls.report.index}/has/${userId}`);

/**
 * 上傳結案報告
 * @param form
 * @param userId
 */
export const postUploadReport = (form: FormData, userId: string) =>
  axios.post(`${urls.report.index}/${userId}`, form);

/**
 * 取得連結
 * @param userId
 */
export const getReportLink = (userId: string) =>
  axios.get<string[]>(`${urls.report.link}/${userId}`);

/**
 * 送出
 * @param userId
 */
export const postSubmit = (userId: string) => axios.post(`${urls.report.index}/submit/${userId}`);
