import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  Grid,
  TextField,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { useSelector } from "react-redux";

import { MuiSpin, RpAlert } from "@/components";
import { getInReviewerScore, postInReviewerMsg } from "@/services/inReviewerService";
import { StoreTypes } from "@/store";

import { ProjectContext } from "./ProjectManager";

const defaultScoreState = {
  isSubmit: false,
  message: "",
  recommend: true,
  scoreArray: [
    {
      id: 0,
      value: 0,
    },
  ],
};

/**
 * 審查委員指派
 */
const ReviewerAssignDialog: React.FC<DialogProps> = ({ open, onClose }) => {
  const {
    currentProject,
    currentInReviewer,
    currentOrder,
    updateReviewerState,
    setCurrentInReviewer,
    setUpdateReviewerState,
  } = useContext(ProjectContext);
  const { inReviewers } = useSelector((state: StoreTypes) => state.reviewer);
  const [loading, setLoading] = useState(false);
  const [scoreState, setScoreState] = useState(defaultScoreState);

  useEffect(() => {
    if (currentProject && currentInReviewer && currentOrder) {
      getInReviewerScore({
        userId: currentProject.userId,
        order: currentOrder,
        inReviewerId: currentInReviewer.inReviewerId,
      }).then((res) => {
        setScoreState(res.data);
      });
    }
  }, [currentInReviewer, currentOrder, currentProject]);

  const handleConfirm = () => {
    setLoading(true);
    postInReviewerMsg({
      userId: currentProject.userId,
      inReviewerId: currentInReviewer.inReviewerId,
      order: currentOrder,
    })
      .then((res) => {
        if (res.data) {
          RpAlert.show("修改成功");
          (onClose as any)();
        } else {
          RpAlert.error("修改失敗");
        }
        setUpdateReviewerState(!updateReviewerState);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Dialog fullWidth open={open} onClose={onClose} maxWidth="sm">
      <DialogTitle>
        指派審查委員（{currentProject.userName}）
        <Button style={{ float: "right" }} onClick={onClose as any}>
          <Close />
        </Button>
      </DialogTitle>
      <DialogContent>
        <MuiSpin spinning={loading}>
          <Autocomplete
            disableClearable
            id="in-reviewer-assign"
            options={inReviewers}
            value={currentInReviewer}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <>
                <TextField {...params} label="內審委員" />* 指派後無法移除，請確定後再指派
              </>
            )}
            onChange={(_, value) => {
              setCurrentInReviewer(value);
            }}
          />
          {currentInReviewer && scoreState.isSubmit && (
            <Grid container style={{ marginTop: 10 }}>
              <Grid item xs={3}>
                分數：
              </Grid>
              <Grid item xs={9}>
                {scoreState.scoreArray.map((el) => el.value).reduce((a, b) => a + b)}
              </Grid>
              <Grid item xs={3}>
                是否推薦：
              </Grid>
              <Grid item xs={9}>
                {scoreState.recommend ? (
                  <span style={{ color: "green" }}>是</span>
                ) : (
                  <span style={{ color: "red" }}>不推薦</span>
                )}
              </Grid>
              {!scoreState.recommend && (
                <>
                  <Grid item xs={3}>
                    不推薦原因：
                  </Grid>
                  <Grid item xs={9}>
                    {scoreState.message ? scoreState.message : "無"}
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </MuiSpin>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose as any}>
          取消
        </Button>
        <Button color="primary" variant="contained" onClick={handleConfirm}>
          確認
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReviewerAssignDialog;
