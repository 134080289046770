import axios from "./axiosService";

/**
 * 取得檔案
 * @param url 檔案URL
 */
export const getFile = (url: string) => axios.get(url, { baseURL: "", responseType: "blob" }); //<-responseType很重要，一定要加blob！才能接到pdf檔之類的文檔流

/**
 * 上傳檔案至wwwroot
 * @param file
 * @returns
 */
export const postFileToWWWRoot = (file: FormData) =>
  axios.post("/admin/setting/file/wwwroot/", file);
