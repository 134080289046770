import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Radio, TableCell, TableRow } from "@material-ui/core/";

import { setScoreArray } from "@/action/reviewerAction";
import { StoreTypes } from "@/store";

type ScoreTableRowProps = {
  weight: number;
  id: number;
  index: number;
  title: string;
};

const ScoreTableRow: React.FC<ScoreTableRowProps> = ({
  weight,
  title,
  id,
  index,
}) => {
  let total = 5;
  let step = weight / total;
  const dispatch = useDispatch();
  const [score, setScore] = useState(weight - step);
  const { scoreArray, isSubmit } = useSelector(
    (state: StoreTypes) => state.reviewer.current.scoreSheet
  );

  useEffect(() => {
    //已送出的話就修改成評分過的分數
    if (!isSubmit) {
      dispatch(setScoreArray(score, id, index));
    } else {
      dispatch(setScoreArray(scoreArray[index].value, id, index));
      setScore(scoreArray[index].value);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmit]);

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setScoreArray(parseInt(e.target.value), id, index));
    setScore(parseInt(e.target.value));
  };

  const renderRadio = (): JSX.Element[] => {
    let radioList: JSX.Element[] = [];
    let result = step;

    for (let i = 0; i < total; i++) {
      // 這邊要轉成字串來比較，用number雖然值一樣但會出現false
      const checked = String(result) === String(score);
      radioList.push(
        <TableCell key={i} align="center">
          <Radio
            checked={checked}
            value={result}
            color="primary"
            size="small"
            name="radio-button"
            onChange={handleRadioChange}
          />
        </TableCell>
      );
      result += step;
    }
    return radioList;
  };

  return (
    <TableRow>
      <TableCell>{index + 1}</TableCell>
      <TableCell>{title}</TableCell>
      <TableCell align="center">{weight}</TableCell>
      {renderRadio()}
      <TableCell align="center">{score}</TableCell>
    </TableRow>
  );
};

export default memo(ScoreTableRow);
