import { Dispatch } from "redux";

import { getMostGroup, getProject, getProjectFilesLink, getProjectTypes } from "@/services/projectService";
import { MostGroup, Project, ProjectType } from "@/types";

export enum PROJECT_ACTION {
  SET_MOST_GROUP = "SET_MOST_GROUP",
  SET_PROJECT_TYPE = "SET_PROJECT_TYPE",
  FETCH_MOST_AND_PROJECT_TYPE = "FETCH_MOST_AND_PROJECT_TYPE",
  REQUEST_PROJECT = "REQUEST_PROJECT",
  RECEIVE_PROJECT = "RECEIVE_PROJECT",
  REQUEST_PROJECT_SUCCESS = "REQUEST_PROJECT_SUCCESS",
  RESET_PROJECT = "RESET_PROJECT",
  REQUEST_FILES = "REQUEST_FILES",
  RECEIVE_FILES = "RECEIVE_FILES",
}

type SetMostGroup = {
  type: PROJECT_ACTION.SET_MOST_GROUP;
  payload: {
    mostGroup: MostGroup[];
  };
};

type SetProjectType = {
  type: PROJECT_ACTION.SET_PROJECT_TYPE;
  payload: {
    projectTypes: ProjectType[];
  };
};

type FetchMostAndProjectType = {
  type: PROJECT_ACTION.FETCH_MOST_AND_PROJECT_TYPE;
};

type RequestProject = {
  type: PROJECT_ACTION.REQUEST_PROJECT;
};

type ReceiveProject = {
  type: PROJECT_ACTION.RECEIVE_PROJECT;
  payload: {
    project: Project;
  };
};

type RequestProjectSuccess = {
  type: PROJECT_ACTION.REQUEST_PROJECT_SUCCESS;
};

type ResetProject = {
  type: PROJECT_ACTION.RESET_PROJECT;
};

type RequestFiles = {
  type: PROJECT_ACTION.REQUEST_FILES;
};

type ReceiveFiles = {
  type: PROJECT_ACTION.RECEIVE_FILES;
  payload: string[];
};

/**
 * 設置學門分類
 * @param mostGroup 學門分類
 */
const setMostGroup = (mostGroup: MostGroup[]): SetMostGroup => ({
  type: PROJECT_ACTION.SET_MOST_GROUP,
  payload: {
    mostGroup,
  },
});

/**
 * 設置計畫類型
 * @param projectTypes 計畫類型
 */
const setProjectType = (projectTypes: ProjectType[]): SetProjectType => ({
  type: PROJECT_ACTION.SET_PROJECT_TYPE,
  payload: {
    projectTypes,
  },
});

/**
 * 抓取計畫類型跟學門分類
 */
export const fetchMostAndProjectType = (): ((dispatch: Dispatch) => void) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: PROJECT_ACTION.FETCH_MOST_AND_PROJECT_TYPE });

    const projectTypes = (await getProjectTypes()).data;
    const mostGroup = (await getMostGroup()).data;

    dispatch(setProjectType(projectTypes));
    dispatch(setMostGroup(mostGroup));
  };
};

/**
 * 請求計畫
 */
export const requestProject = (
  userId: string
): ((dispatch: Dispatch) => void) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: PROJECT_ACTION.REQUEST_PROJECT } as RequestProject);

    await getProject(userId)
      .then((res) => {
        if (!res.data.message) {
          //設置計畫
          dispatch({
            type: PROJECT_ACTION.RECEIVE_PROJECT,
            payload: { project: res.data },
          } as ReceiveProject);
        }
      })
      .finally(() => {
        //更改loading狀態
        dispatch({
          type: PROJECT_ACTION.REQUEST_PROJECT_SUCCESS,
        } as RequestProjectSuccess);
      });
  };
};

/**
 * 重置計畫
 */
export const resetProject = (): ResetProject => {
  return {
    type: PROJECT_ACTION.RESET_PROJECT,
  };
};

/**
 * 請求計畫檔案
 */
export const requestFiles = (userId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: PROJECT_ACTION.REQUEST_FILES } as RequestFiles);
    await getProjectFilesLink(userId).then((res) => {
      if (!res.data) {
        dispatch({
          type: PROJECT_ACTION.RECEIVE_FILES,
          payload: [] as string[],
        } as ReceiveFiles);
        return;
      }

      dispatch({
        type: PROJECT_ACTION.RECEIVE_FILES,
        payload: res.data,
      } as ReceiveFiles);
    });
  };
};

export type ProjectActionTypes =
  | SetMostGroup
  | SetProjectType
  | FetchMostAndProjectType
  | RequestProject
  | RequestProjectSuccess
  | ReceiveProject
  | ResetProject
  | RequestFiles
  | ReceiveFiles
