import { Dispatch } from "redux";

import {
  deleteOtherBudget as delOtherBudget,
  getBudgetCount,
  getBudgets,
  getOtherBudgets,
  getOtherBudgetsCount,
} from "@/services/budgetService";
import { Budget, OtherBudget } from "@/types";

export enum BUDGET_ACTION {
  REQUEST_OTHER_BUDGET_LIST = "REQUEST_OTHER_BUDGET_LIST",
  SET_OTHER_BUDGET_LIST = "SET_OTHER_BUDGET_LIST",
  ADD_OTHER_BUDGET = "ADD_OTHER_BUDGET",
  DELETE_OTHER_BUDGET = "DELETE_OTHER_BUDGET",

  REQUEST_BUDGET_LIST = "REQUEST_BUDGET_LIST",
  SET_BUDGET_LIST = "SET_BUDGET_LIST",

  REQUEST_BUDGET_TOTAL = "REQUEST_BUDGET_TOTAL",
  SET_BUDGET_TOTAL = "SET_BUDGET_TOTAL",

  REQUEST_OTHER_BUDGET_TOTAL = "REQUEST_OTHER_BUDGET_LIST",
  SET_OTHER_BUDGET_TOTAL = "SET_OTHER_BUDGET_TOTAL",
}

type RequestOtherBudgetList = {
  type: BUDGET_ACTION.REQUEST_OTHER_BUDGET_LIST;
};

type SetOtherBudgetList = {
  type: BUDGET_ACTION.SET_OTHER_BUDGET_LIST;
  payload: {
    otherBudget: OtherBudget[];
  };
};

type AddOtherBudget = {
  type: BUDGET_ACTION.ADD_OTHER_BUDGET;
};

type DeleteOtherBudget = {
  type: BUDGET_ACTION.DELETE_OTHER_BUDGET;
};

type RequestBudgetList = {
  type: BUDGET_ACTION.REQUEST_BUDGET_LIST;
};

type SetBudgetList = {
  type: BUDGET_ACTION.SET_BUDGET_LIST;
  payload: {
    budgets: Budget[];
  };
};

type RequestBudgetTotal = {
  type: BUDGET_ACTION.REQUEST_BUDGET_TOTAL;
};

type SetBudgetTotal = {
  type: BUDGET_ACTION.SET_BUDGET_TOTAL;
  payload: {
    budgetTotal: number;
  };
};

type RequestOtherBudgetTotal = {
  type: BUDGET_ACTION.REQUEST_OTHER_BUDGET_TOTAL;
};

type SetOtherBudgetTotal = {
  type: BUDGET_ACTION.SET_OTHER_BUDGET_TOTAL;
  payload: {
    otherBudgetTotal: number;
  };
};

/**
 * 取得其他預算列表
 * @param userId
 */
export const requestOtherBudgetList = (userId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: BUDGET_ACTION.REQUEST_OTHER_BUDGET_LIST,
    } as RequestOtherBudgetList);

    //設置其他預算列表
    await getOtherBudgets(userId).then((res) => {
      if (!res.data.message) {
        dispatch({
          type: BUDGET_ACTION.SET_OTHER_BUDGET_LIST,
          payload: {
            otherBudget: res.data,
          },
        } as SetOtherBudgetList);
      }
    });

    //設置總金額
    await getOtherBudgetsCount(userId).then((res) => {
      dispatch({
        type: BUDGET_ACTION.SET_OTHER_BUDGET_TOTAL,
        payload: {
          otherBudgetTotal: res.data,
        },
      } as SetOtherBudgetTotal);
    });
  };
};

/**
 * 新增其他預算
 */
export const addOtherBudget = (): AddOtherBudget => ({
  type: BUDGET_ACTION.ADD_OTHER_BUDGET,
});

/**
 * 刪除其他預算
 * @param id
 * @param userId
 */
export const deleteOtherBudget = (id: number, userId: string) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: BUDGET_ACTION.DELETE_OTHER_BUDGET });
    delOtherBudget(id).then((_res) => {
      dispatch(requestOtherBudgetList(userId) as any);
    });
  };
};

/**
 * 取得工讀計畫列表
 */
export const requestBudgetList = (userId: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({ type: BUDGET_ACTION.REQUEST_BUDGET_LIST } as RequestBudgetList);
    await getBudgets(userId).then((res) => {
      if (!res.data.message) {
        dispatch({
          type: BUDGET_ACTION.SET_BUDGET_LIST,
          payload: { budgets: res.data },
        } as SetBudgetList);
      }
    });

    //取得總金額
    await getBudgetCount(userId).then((res) => {
      dispatch({
        type: BUDGET_ACTION.SET_BUDGET_TOTAL,
        payload: { budgetTotal: res.data },
      } as SetBudgetTotal);
    });
  };
};

export type BudgetActionTypes =
  | RequestOtherBudgetList
  | SetOtherBudgetList
  | AddOtherBudget
  | DeleteOtherBudget
  | RequestBudgetList
  | SetBudgetList
  | RequestBudgetTotal
  | SetBudgetTotal
  | RequestOtherBudgetTotal
  | SetOtherBudgetTotal;
