import { AUTH_ITEMS } from "@/reducers/authReducer";

/**
 * 刪除localStorage後重新整理並登出
 */
export const useLogout = () => {
  return (url?: string) => {
    localStorage.removeItem(AUTH_ITEMS.USER_INFO);
    window.location.replace(url ? url : "/");
  };
};
