import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  List,
  ListItem,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

import { getHasFile, getProjectFilesLink } from "@/services/projectService";
import { ProjectContext } from "@/views/Admin/ProjectManager/ProjectManager";

import classes from "./FileDialog.module.scss";
import { MuiSpin } from "..";

const fileTitle = ["個人資料", "計畫書", "佐證"];

const FileDialog: React.FC<DialogProps> = ({ open, onClose }) => {
  const { currentProject } = useContext(ProjectContext);
  const [links, setLinks] = useState<string[]>([]);
  const [hasFile, setHasFile] = useState(false);
  const [loading, setLoading] = useState(true);

  /**
   * 取得檔案
   */
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const hasFile: boolean = (await getHasFile(currentProject.userId)).data;
        setHasFile(hasFile);

        if (hasFile) {
          const links: string[] = (await getProjectFilesLink(currentProject.userId)).data;
          setLinks(links);
        }
      } catch {}
      setLoading(false);
    })();
  }, [currentProject.userId]);

  useEffect(() => {
    return () => {
      console.log("object");
    };
  }, []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          overflowY: "unset",
        },
      }}
    >
      <DialogTitle>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ flex: "1" }}>檢視檔案（{currentProject.userName}）</div>
          <div>
            <Button onClick={onClose as any}>
              <Close />
            </Button>
          </div>
        </div>
      </DialogTitle>
      <DialogContent className={classes.noOverFlow} style={{ height: 176, padding: "0 24px" }}>
        <MuiSpin spinning={loading}>
          {hasFile ? (
            <List>
              {links.map((link, i) => (
                <ListItem key={i}>
                  <Grid container>
                    <Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
                      {fileTitle[i]}：
                    </Grid>
                    <Grid item xs={8} style={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        href={`/file/${link}`}
                        variant="outlined"
                        target="_blank"
                        color="primary"
                      >
                        查看
                      </Button>
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
            </List>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                color: "#ccc",
                marginTop: "40px",
              }}
            >
              未上傳檔案
            </div>
          )}
        </MuiSpin>
      </DialogContent>
    </Dialog>
  );
};

export default FileDialog;
