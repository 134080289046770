import React, { useContext, useEffect, useState } from "react";
import { Button } from "@material-ui/core";

import { RpMust } from "@/constants";

import { FileContext } from "./UploadFiles";
import { RpAlert } from "..";

type UploadSectionProps = {
  title: string;
  index: number;
  file?: File;
};

const UploadSection: React.FC<UploadSectionProps> = ({ title, index }) => {
  // const [file, setFile] = useState<File | null>();
  const { x, setXX, files, setFileData } = useContext(FileContext);

  useEffect(() => {
    console.log("files update", files);
    console.log("x update", x);
  }, [files, x]);

  // useEffect(() => {
  //   //判斷該不該清空
  //   // console.log("判斷該不該清空", file, index);
  //   console.log(isEmptyId, index);
  //   if (isEmptyId.includes(index)) {
  //     console.log(`isEmptyId.includes(${index})`);
  //     setFile(null);
  //     setIsEmptyId([]);
  //   }
  // }, [isEmptyId]);

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    setXX(Math.random());
    const eFile = e.target.files![0];

    //不能超過15MB
    if (eFile.size > RpMust.fileMaxSize) {
      RpAlert.error(`檔案不可超過${RpMust.sizeConvert(RpMust.fileMaxSize)}`);
    }

    // setFile(eFile);
    setFileData(eFile, index);
  };

  return (
    <section className="file">
      <div>{title}</div>
      <div>
        <input
          type="file"
          name="file"
          accept="application/pdf"
          id={`file-${index}`}
          style={{ display: "none" }}
          onChange={handleUpload}
        />
        <label htmlFor={`file-${index}`}>
          <Button size="small" color="primary" variant="contained" component="span">
            選擇檔案
          </Button>
        </label>{" "}
        <label className="file-name-label">{files[index]?.name}</label>
      </div>
    </section>
  );
};

export default UploadSection;
