import React, { useState } from "react";
import { Button, Collapse, Link, List, ListItem, TableCell, TableRow } from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";

import classes from "./style.module.scss";
import { renderDescription } from "./TeacherScoreDialog/TeacherScoreInput";
import { TeacherScoreType } from "./TeacherScore";

type ScoreBoardItemType = {
  t: TeacherScoreType;
  edit?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
};

const ScoreBoardItem: React.FC<ScoreBoardItemType> = ({
  t,
  edit = true,
  onEdit = () => {},
  onDelete = () => {},
}) => {
  const [ocCollapseOpen, setOcCollapseOpen] = useState(false);

  const base64Href = btoa(
    `/api/project/teacherScore/file/${t.evidence.fileName}`
  );

  return (
    <React.Fragment>
      <TableRow className={classes.unsetBorder}>
        <TableCell>
          {t.teacherPerformance.class} <br /> ({t.teacherPerformance.className})
        </TableCell>
        <TableCell>
          {renderDescription(t.teacherPerformance.description)}
        </TableCell>
        <TableCell align="center">{t.option}</TableCell>
        <TableCell>
          <List>
            <ListItem>
              佐證名稱：{t.evidence.title}
              {t.teacherPerformance.method === 3 && "元"}
            </ListItem>
            <ListItem>
              佐證證明：
              <Link
                href={`/file/${base64Href}`}
                target="_blank"
                color="primary"
              >
                點擊查看
              </Link>
            </ListItem>
            {t.otherContent && (
              <ListItem>
                <Button
                  color="primary"
                  variant="text"
                  size="small"
                  onClick={() => setOcCollapseOpen(!ocCollapseOpen)}
                >
                  <div
                    style={{
                      position: "relative",
                      top: 3,
                      display: "inline-block",
                    }}
                  >
                    {ocCollapseOpen ? <ArrowDropUp /> : <ArrowDropDown />}
                  </div>
                  {ocCollapseOpen ? "隱藏" : "顯示"}補充內容
                </Button>
              </ListItem>
            )}
          </List>
        </TableCell>
        <TableCell align="center">{t.score}</TableCell>
        {edit && (
          <TableCell align="center">
            <div style={{ marginBottom: "5px" }}>
              <Button color="primary" variant="contained" onClick={onEdit}>
                編輯
              </Button>
            </div>
            <div>
              <Button color="secondary" variant="contained" onClick={onDelete}>
                刪除
              </Button>
            </div>
          </TableCell>
        )}
      </TableRow>
      <TableRow style={{ paddingBottom: 0, paddingTop: 0 }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}></TableCell>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}></TableCell>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}></TableCell>
        <TableCell
          colSpan={3}
          style={{
            paddingLeft: 32,
            paddingBottom: 0,
            paddingTop: 0,
          }}
        >
          <Collapse in={ocCollapseOpen} timeout="auto" unmountOnExit>
            <div style={{ paddingBottom: "10px" }}>
              <h3 style={{ fontWeight: "bold" }}>補充內容</h3>
              {t.otherContent &&
                t.otherContent.split(/\n/g).map((content, i) => (
                  <React.Fragment key={i}>
                    {content}
                    <br />
                  </React.Fragment>
                ))}
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default ScoreBoardItem;
