import clsx from "clsx";
import React from "react";
import Scrollbar from "react-perfect-scrollbar";
import {
  Divider,
  Drawer,
  DrawerProps,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core/";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import {
  AccountCircle,
  AccountTree,
  Description,
  ExitToApp,
  People,
  Settings,
  Storage,
  Toc,
  Home,
  School,
} from "@material-ui/icons";
import { Omit } from "@material-ui/types";
import { useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";

import { logout } from "@/action/authAction";
import { ROUTES } from "@/constants";

type Category = {
  id: string;
  children: {
    id: string;
    icon: React.ReactNode;
    path: string;
    disabled?: boolean;
  }[];
};

const categories: Category[] = [
  {
    id: "計畫",
    children: [
      { id: "計畫管理", icon: <AccountTree />, path: ROUTES.ADMIN.PROJECT.INDEX },
      {
        id: "計畫類型編輯",
        icon: <Description />,
        path: ROUTES.ADMIN.PROJECT_TYPE,
      },
      // {
      //   id: "教師績效編輯",
      //   icon: <Storage />,
      //   path: ROUTES.ADMIN.TEACHER_SCORE,
      // },
    ],
  },
  {
    id: "結案報告",
    children: [{ id: "結案報告管理", icon: <AccountTree />, path: ROUTES.ADMIN.REPORT.INDEX }],
  },
  {
    id: "審查委員",
    children: [
      {
        id: "內審委員管理",
        icon: <People />,
        path: ROUTES.ADMIN.REVIEWER.IN,
      },
      {
        id: "外審委員管理",
        icon: <People />,
        path: ROUTES.ADMIN.REVIEWER.OUT,
      },
    ],
  },
  {
    id: "使用者",
    children: [
      {
        id: "使用者管理",
        icon: <People />,
        path: ROUTES.ADMIN.USER.INDEX,
      },
      {
        id: "學院管理",
        disabled: true,
        icon: <School />,
        path: ROUTES.ADMIN.USER.COLLEGE,
      },
      // {
      //   id: "匯入使用者",
      //   icon: <AccountCircle />,
      //   path: ROUTES.ADMIN.USER.IMPORT,
      // },
    ],
  },
  {
    id: "系統管理",
    children: [
      { id: "Log 記錄", icon: <Toc />, path: ROUTES.ADMIN.LOG },
      { id: "設定", icon: <Settings />, path: ROUTES.ADMIN.SETTING },
    ],
  },
  {
    id: "其他",
    children: [
      { id: "回到首頁", icon: <Home />, path: "/" },
      { id: "登出", icon: <ExitToApp />, path: "" },
    ],
  },
];

const styles = (theme: Theme) =>
  createStyles({
    categoryHeader: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    categoryHeaderPrimary: {
      color: theme.palette.common.white,
    },
    item: {
      paddingTop: 1,
      paddingBottom: 1,
      color: "rgba(255, 255, 255, 0.7)",
      "&:hover,&:focus": {
        backgroundColor: "rgba(255, 255, 255, 0.08)",
      },
    },
    itemCategory: {
      backgroundColor: "#232f3e",
      boxShadow: "0 -1px 0 #404854 inset",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    firebase: {
      fontSize: 24,
      color: theme.palette.common.white,
    },
    itemActiveItem: {
      color: "#4fc3f7",
    },
    itemPrimary: {
      fontSize: "inherit",
    },
    itemIcon: {
      minWidth: "auto",
      marginRight: theme.spacing(2),
    },
    divider: {
      marginTop: theme.spacing(2),
    },
  });

export interface NavigatorProps extends Omit<DrawerProps, "classes">, WithStyles<typeof styles> {}

function Navigator(props: NavigatorProps) {
  const { classes, ...other } = props;
  const dispatch = useDispatch();
  const currentPath = useLocation();
  const history = useHistory();

  return (
    <Drawer variant="permanent" {...other}>
      {/* <Scrollbar> */}
      <List disablePadding>
        <Link to={ROUTES.ADMIN.PROJECT.INDEX}>
          <ListItem className={clsx(classes.firebase, classes.item, classes.itemCategory)}>
            校內專題-管理系統
          </ListItem>
        </Link>
        {categories.map(({ id, children }) => (
          <React.Fragment key={id}>
            <ListItem className={classes.categoryHeader}>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                {id}
              </ListItemText>
            </ListItem>
            {children?.map(({ id: childId, icon, disabled, path }) => {
              const isCurrentPath: boolean = currentPath.pathname === path;

              const listItem = () => (
                <ListItem
                  disabled={disabled}
                  button
                  className={clsx(classes.item, isCurrentPath && classes.itemActiveItem)}
                >
                  <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                  <ListItemText classes={{ primary: classes.itemPrimary }}>{childId}</ListItemText>
                </ListItem>
              );

              return (
                <Link
                  key={childId}
                  to={path}
                  onClick={(e) => {
                    isCurrentPath && e.preventDefault();

                    if (childId === "登出") {
                      window.location.href = "/";
                      dispatch(logout());
                    }
                  }}
                >
                  {listItem()}
                </Link>
              );
            })}
            <Divider className={classes.divider} />
          </React.Fragment>
        ))}
      </List>
      {/* </Scrollbar> */}
    </Drawer>
  );
}

export default withStyles(styles)(Navigator);
