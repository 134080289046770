import React, { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import { getAllTeacherPerformance } from "@/services/projectService";
import { TeacherPerformance } from "@/types";

import TeacherPerformanceRow from "./TeacherPerformanceRow";

const TeacherPerformanceTable: React.FC = () => {
  const [teacherPerformance, setTeacherPerformance] = useState<
    TeacherPerformance[]
  >([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getAllTeacherPerformance().then((res) => {
      setTeacherPerformance(res.data);
      setLoading(false);
    });
  }, []);

  /**
   * 渲染骨架屏
   */
  const renderSkeleton = () => {
    let count = 7;
    let rows = [];
    let cells = [];

    for (let i = 0; i < count; i++) {
      cells.push(
        <TableCell key={i}>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
        </TableCell>
      );
    }

    for (let i = 0; i < count; i++) {
      rows.push(<TableRow key={i}>{cells}</TableRow>);
    }

    return rows;
  };

  /**
   * 表格頭
   */
  const renderTableHeadRow = () => (
    <TableRow>
      <TableCell component="th" style={{ width: "4rem", fontWeight: "bold" }}>
        選取
      </TableCell>
      <TableCell component="th" style={{ width: "4rem", fontWeight: "bold" }}>
        序號
      </TableCell>
      <TableCell component="th" style={{ width: "4rem", fontWeight: "bold" }}>
        分類
      </TableCell>
      <TableCell component="th" style={{ width: "6rem", fontWeight: "bold" }}>
        分類名稱
      </TableCell>
      <TableCell component="th" style={{ width: "4rem", fontWeight: "bold" }}>
        加分
      </TableCell>
      <TableCell component="th" style={{ fontWeight: "bold", width: "39rem" }}>
        每項目10分，每項目最多認列二筆，每項最高20分
      </TableCell>
      <TableCell component="th" style={{ fontWeight: "bold" }}>
        選項
      </TableCell>
    </TableRow>
  );

  return (
    <Table stickyHeader>
      <TableHead>{renderTableHeadRow()}</TableHead>
      <TableBody>
        {loading
          ? renderSkeleton()
          : teacherPerformance.map((t, i) => (
              <TeacherPerformanceRow key={t.id} teacherPerformance={t} i={i} />
            ))}
      </TableBody>
    </Table>
  );
};

export default TeacherPerformanceTable;
