import { RpMustUser } from "@/types";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import {
  deleteUser,
  getAllTitle,
  getAllUnit,
  getAllUser,
  postUser,
  putUser,
} from "@/services/userService";
import { RpAlert } from "@/components";

const UserManager: React.FC = () => {
  const [userList, setUserList] = useState<RpMustUser[]>([]);
  const [titleLookup, setTitleLookup] = useState({});
  const [unitLookup, setUnitLookup] = useState<any>({});

  async function fetchData() {
    const [userListRes, lookupRes, titleRes] = await Promise.all([
      getAllUser(),
      getAllUnit(),
      getAllTitle(),
    ]);
    const newLookup: any = {};
    const newTitleLookup: any = {};

    // Create object for college+department
    (lookupRes.data as { deptId: number; name: string }[]).forEach((x) => {
      newLookup[x.deptId] = x.name;
    });

    // Create object for title
    (titleRes.data as { titleId: number; name: string }[]).forEach((x) => {
      newTitleLookup[x.titleId] = x.name;
    });

    const newUserList: RpMustUser[] = (userListRes.data as RpMustUser[]).map((x) => {
      return {
        ...x,
        unit: Object.keys(newLookup).find((key) => newLookup[key] === x.unit)!,
        title: Object.keys(newTitleLookup).find((key) => newTitleLookup[key] === x.title)!,
      };
    });

    setUserList(newUserList);
    setUnitLookup(newLookup);
    setTitleLookup(newTitleLookup);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <MaterialTable
      title="使用者管理"
      options={{
        pageSize: 20,
        paginationType: "stepped",
        actionsColumnIndex: -1,
        pageSizeOptions: [20, 50, 100, 200],
      }}
      localization={{
        header: {
          actions: "操作",
        },
        body: {
          editRow: {
            deleteText: "確定要刪除嗎？",
          },
        },
      }}
      columns={[
        { title: "工號", field: "userId", editable: "onAdd" },
        { title: "姓名", field: "name" },
        { title: "Email", field: "email" },
        { title: "單位", field: "unit", lookup: unitLookup },
        { title: "職稱", field: "title", lookup: titleLookup },
      ]}
      data={userList}
      editable={{
        onRowAdd: async (newData) => {
          var result = await postUser({
            userId: newData.userId,
            name: newData.name,
            email: newData.email,
            deptId: Number(newData.unit),
            titleId: Number(newData.title),
          });
          console.log(result);

          if (result.data.message) {
            RpAlert.error(result.data.message);
          } else {
            RpAlert.show("新增成功");
            await fetchData();
          }
        },
        onRowUpdate: async (newData, oldData) => {
          var result = await putUser({
            userId: newData.userId,
            name: newData.name,
            email: newData.email,
            deptId: Number(newData.unit),
            titleId: Number(newData.title),
          });
          console.log(result);

          if (result.data.message) {
            RpAlert.error(result.data.message);
          } else {
            RpAlert.show("修改成功");
            await fetchData();
          }
        },
        onRowDelete: async (oldData) => {
          var result = await deleteUser(oldData.userId);
          if (result.data.message) {
            RpAlert.error(result.data.message);
          } else {
            RpAlert.show("刪除成功");
            await fetchData();
          }
        },
      }}
    />
  );
};

export default UserManager;
