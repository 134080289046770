import {
  RECEIVE_CURRENT_PROJECT,
  RECEIVE_IN_REVIEWER,
  RECEIVE_SCORE_CLASS,
  RECEIVE_SCORE_SHEET,
  REQUEST_IN_REVIEWER,
  RESET_CURRENT_PROJECT,
  RESET_SCORE_SHEET,
  ReviewerActionTypes,
  SET_RECOMMEND_AND_MESSAGE,
  SET_SCORE_ARRAY,
} from "@/action/reviewerAction";
import { InReviewer, InReviewerMsg, Project, RpMustUser, ScoreClass } from "@/types";

type ReviewerState = {
  isLoading: boolean;
  inReviewers: InReviewer[];
  outReviewer: any[];
  inReviewerMsgs: InReviewerMsg[];
  outReviewerMsgs: any[];
  current: {
    project: Project;
    user: RpMustUser;
    inReviewer?: InReviewer;
    link: string;
    scoreSheet: {
      scoreClass: ScoreClass[];
      scoreArray: {
        id: number;
        value: number;
      }[];
      recommend: boolean;
      message?: string;
      isSubmit: boolean;
    };
  };
};

const init: ReviewerState = {
  isLoading: false,
  inReviewers: [],
  outReviewer: [],
  inReviewerMsgs: [],
  outReviewerMsgs: [],
  current: {
    project: {} as Project,
    user: {} as RpMustUser,
    inReviewer: {} as InReviewer,
    link: "",
    scoreSheet: {
      scoreClass: [],
      scoreArray: [],
      recommend: true,
      message: "",
      isSubmit: false,
    },
  },
};

export default (state = init, action: ReviewerActionTypes): ReviewerState => {
  switch (action.type) {
    case REQUEST_IN_REVIEWER:
      return { ...state, isLoading: true };
    case RECEIVE_IN_REVIEWER:
      return { ...state, inReviewers: action.payload, isLoading: false };
    case RECEIVE_CURRENT_PROJECT:
      return {
        ...state,
        current: {
          ...state.current,
          project: action.payload.project,
          user: action.payload.user,
          link: action.payload.link,
        },
      };
    case RESET_CURRENT_PROJECT:
      return {
        ...state,
        current: {
          ...state.current,
          project: {} as Project,
          user: {} as RpMustUser,
        },
      };
    case SET_RECOMMEND_AND_MESSAGE:
      return {
        ...state,
        current: {
          ...state.current,
          scoreSheet: {
            ...state.current.scoreSheet,
            recommend: action.payload.recommend,
            message: action.payload.message,
          },
        },
      };
    case SET_SCORE_ARRAY:
      let arr = state.current.scoreSheet.scoreArray;

      arr[action.payload.index] = {
        id: action.payload.id,
        value: action.payload.value,
      };

      return {
        ...state,
        current: {
          ...state.current,
          scoreSheet: {
            ...state.current.scoreSheet,
            scoreArray: arr,
          },
        },
      };
    case RESET_SCORE_SHEET:
      return {
        ...state,
        current: {
          ...state.current,
          scoreSheet: {
            scoreClass: [],
            scoreArray: [],
            recommend: true,
            message: "",
            isSubmit: false,
          },
        },
      };
    case RECEIVE_SCORE_SHEET:
      const { scoreArray, ...rest } = action.payload;

      //防止分數被覆蓋
      if (scoreArray.length < 1) {
        return {
          ...state,
          current: {
            ...state.current,
            scoreSheet: {
              ...state.current.scoreSheet,
              ...rest,
            },
          },
        };
      }

      return {
        ...state,
        current: {
          ...state.current,
          scoreSheet: {
            ...state.current.scoreSheet,
            ...action.payload,
          },
        },
      };
    case RECEIVE_SCORE_CLASS:
      return {
        ...state,
        current: {
          ...state.current,
          scoreSheet: {
            ...state.current.scoreSheet,
            scoreClass: action.payload,
          },
        },
      };
    default:
      return state;
  }
};
