import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import { Container } from "@material-ui/core";

import { StoreTypes } from "@/store";
import { useTitle } from "@/hooks";
import { checkAuth } from "@/services/loginService";
import { RpMust } from "@/constants";
import { logout } from "@/action/authAction";
import { Loading } from "@/components";

import Dashboard from "../Admin/Dashboard/Dashboard";

const Admin: React.FC = ({ children }) => {
  const { roles } = useSelector((state: StoreTypes) => state.auth);
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useTitle(RpMust.dashboardTitle);

  useEffect(() => {
    checkAuth()
      .then((res) => {
        if (res.data) {
          return;
        }
        dispatch(logout());
      })
      .catch(() => dispatch(logout()));
  });

  if (!roles.includes("Admin")) {
    return <Redirect to={`/login?url=${pathname}`} />;
  }

  return (
    <Dashboard>
      <Container>{children}</Container>
    </Dashboard>
  );
};

export default Admin;
