import moment from "moment";
import AutoSizer from "react-virtualized-auto-sizer";
import React, { memo, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FixedSizeList } from "react-window";
import { useDispatch } from "react-redux";
import {
  Button,
  Checkbox,
  Grid,
  List,
  ListItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { ArrowBack, AttachFile, AttachMoney, Done, Edit, Print, Storage } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

import { requestInReviewer } from "@/action/reviewerAction";
import { ROUTES } from "@/constants";

import ProjectToolbar from "./ProjectToolbar";
import ReviewerState from "./ReviewerState";
import classes from "./style.module.scss";
import UserInfo from "./UserInfo";
import ProjectPagination from "./ProjectPagination";
import { ProjectContext } from "./ProjectManager";
import { Project } from "@/types";

type ProjectItemProps = {
  project: Project;
};

const ProjectItem: React.FC<ProjectItemProps> = memo(({ project }) => {
  const history = useHistory();
  const { handleChangeProject, handleReject: reject } = useContext(ProjectContext);

  const attachFile = useMemo(() => <AttachFile />, []);
  const storage = useMemo(() => <Storage />, []);
  const attachMoney = useMemo(() => <AttachMoney />, []);
  const done = useMemo(() => <Done />, []);
  const print = useMemo(() => <Print />, []);
  const edit = useMemo(() => <Edit />, []);
  const arrowBack = useMemo(() => <ArrowBack />, []);

  const handleShowFile = useCallback(() => {
    handleChangeProject(project, "file");
  }, []);

  const handleShowTeacherScore = useCallback(() => {
    handleChangeProject(project, "teacherScoreInfo");
  }, []);

  const handleShowBudget = useCallback(() => {
    handleChangeProject(project, "budget");
  }, []);

  const handlePrint = useCallback(() => {
    handleChangeProject(project, "print");
  }, []);

  const handleReject = useCallback(() => {
    reject(project.userId);
  }, []);

  const handleShowPrintBudget = useCallback(() => {
    handleChangeProject(project, "print-budget");
  }, []);

  return (
    <TableRow hover>
      <TableCell align="center">
        <Checkbox color="primary" />
      </TableCell>
      <TableCell>
        <UserInfo project={project} />
      </TableCell>
      <TableCell>
        <List>
          <ListItem>計畫名稱：{project.name}</ListItem>
          <ListItem>衍生成果：{project.property}</ListItem>
          <ListItem>
            類型：
            <>
              {project.type} <br />
              {project.type.startsWith("個人") &&
                (project.personalType === 0
                  ? "- 前一年度申請科技部計畫(含一般專題及產學專題)，未獲補助者"
                  : "- 前一年度以本校名義投稿於有審稿制度之專業期刊(不含掠奪性期刊)，未獲通過")}
            </>
          </ListItem>
          <ListItem>學門分類：{project.most}</ListItem>
          <ListItem>
            是否為新進教師：
            {project.isNewTeacher
              ? `是 （${moment(project.appointmentDate).format("YYYY/MM")}）`
              : "否，已到校超過三年以上"}
          </ListItem>
          <ListItem>
            申請時間：
            {moment(project.date).format("YYYY/MM/DD HH:mm")}
          </ListItem>
          <ListItem>
            <Grid container spacing={2}>
              <Grid item>
                <Tooltip title="檢視個人檔案與計畫書">
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    startIcon={attachFile}
                    onClick={handleShowFile}
                  >
                    檔案
                  </Button>
                </Tooltip>
              </Grid>
              {/* <Grid item>
                <Tooltip title="檢視教師績效">
                  <Button
                    disabled
                    variant="contained"
                    size="small"
                    color="primary"
                    startIcon={storage}
                    onClick={handleShowTeacherScore}
                  >
                    教師績效
                  </Button>
                </Tooltip>
              </Grid> */}
              <Grid item>
                <Tooltip title="檢視預算表">
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    startIcon={attachMoney}
                    onClick={handleShowBudget}
                  >
                    預算
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title="列印預算表">
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    startIcon={print}
                    onClick={handleShowPrintBudget}
                  >
                    列印預算
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          </ListItem>
        </List>
      </TableCell>
      <TableCell align="left">
        {/* 審查委員 */}
        <ReviewerState disabled={!project.submit} userId={project.userId} />
      </TableCell>
      <TableCell>
        <div style={{ textAlign: "center" }}>
          {project.submit ? (
            <React.Fragment>
              <Button fullWidth disabled variant="outlined" size="small" startIcon={done}>
                已送出
              </Button>
              <Tooltip title={`列印${project.userName}的申請表`}>
                <Button
                  fullWidth
                  variant="contained"
                  size="small"
                  color="primary"
                  startIcon={print}
                  style={{ marginTop: 10 }}
                  onClick={handlePrint}
                >
                  申請表
                </Button>
              </Tooltip>
            </React.Fragment>
          ) : (
            <Button
              fullWidth
              disabled
              variant="contained"
              size="small"
              color="primary"
              startIcon={edit}
              onClick={() => history.push(`${ROUTES.ADMIN.PROJECT.EDIT}/${project.userId}`)}
            >
              編輯
            </Button>
          )}
        </div>
        <div
          style={{
            textAlign: "center",
            marginTop: project.submit ? 10 : "0",
          }}
        >
          {project.submit ? (
            <Tooltip title="退回計畫，讓老師可以重新編輯">
              <Button
                fullWidth
                variant="contained"
                size="small"
                color="secondary"
                startIcon={arrowBack}
                onClick={handleReject}
              >
                退回
              </Button>
            </Tooltip>
          ) : null}
        </div>
      </TableCell>
    </TableRow>
  );
});

const ProjectTable: React.FC = () => {
  const { rowPerPage, page, currentProjects } = useContext(ProjectContext);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestInReviewer());
  }, [dispatch]);

  return (
    <React.Fragment>
      <TableContainer component={Paper} style={{ marginTop: 16 }}>
        <ProjectToolbar />
        <Table className={classes.table}>
          <colgroup>
            <col style={{ width: "8%" }} />
            <col style={{ width: "24%" }} />
            <col style={{ width: "auto" }} />
            <col style={{ width: "20%" }} />
            <col style={{ width: "11%" }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell align="center">核定</TableCell>
              <TableCell style={{ paddingLeft: 32 }}>申請人資訊</TableCell>
              <TableCell style={{ paddingLeft: 32 }}>計畫資訊</TableCell>
              <TableCell align="center">審查委員</TableCell>
              <TableCell align="center">狀態</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentProjects.length === 0 && (
              <TableRow>
                <TableCell colSpan={5}>
                  <div style={{ display: "flex", justifyContent: "center" }}>無資料</div>
                </TableCell>
              </TableRow>
            )}
            {currentProjects.slice((page - 1) * rowPerPage, page * rowPerPage).map((project) => (
              <ProjectItem project={project} key={project.userId} />
            ))}
          </TableBody>
        </Table>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "15px 0",
          }}
        >
          <ProjectPagination />
        </div>
      </TableContainer>
    </React.Fragment>
  );
};

export default memo(ProjectTable);
