import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";

import { deleteOtherBudget, requestOtherBudgetList } from "@/action/budgetAction";
import { useRpMustUser } from "@/hooks";
import { StoreTypes } from "@/store";
import { OtherBudget } from "@/types";
import { MuiSpin, RpAlert } from "@/components/";

import EditDialog from "./EditDialog";

const OtherTable: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [otherBudget, setOtherBudget] = useState<OtherBudget>({} as OtherBudget);
  const { userId } = useRpMustUser();
  const { otherBudgets, otherBudgetTotal, isLoading } = useSelector(
    (state: StoreTypes) => state.budget
  );
  const dispatch = useDispatch();

  const handleClose = () => setOpen(false);

  useEffect(() => {
    // 取得預算資料
    dispatch(requestOtherBudgetList(userId));
  }, [userId, dispatch]);

  return (
    <div>
      <MuiSpin spinning={isLoading}>
        <Table>
          <caption style={{ textAlign: "right" }}>總合：{otherBudgetTotal} 元</caption>
          <TableHead>
            <TableRow>
              <TableCell>項次</TableCell>
              <TableCell>品名</TableCell>
              <TableCell>用途</TableCell>
              <TableCell>單位</TableCell>
              <TableCell>數量</TableCell>
              <TableCell>單價</TableCell>
              <TableCell>小計</TableCell>
              <TableCell style={{ width: "13%" }} align="center">
                操作
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {otherBudgets.length > 0 ? (
              otherBudgets.map((o, i) => (
                <TableRow key={o.id} hover>
                  <TableCell>{i + 1}</TableCell>
                  <TableCell>{o.name}</TableCell>
                  <TableCell>{o.detail}</TableCell>
                  <TableCell>{o.unit}</TableCell>
                  <TableCell>
                    {o.quantity}
                    {o.unit}
                  </TableCell>
                  <TableCell>{o.price}元</TableCell>
                  <TableCell>{o.quantity * o.price}元</TableCell>
                  <TableCell align="center">
                    <div style={{ marginBottom: "5px" }}>
                      <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          setOtherBudget(o);
                          setOpen(true);
                        }}
                      >
                        編輯
                      </Button>
                    </div>
                    <div>
                      <Button
                        color="secondary"
                        size="small"
                        variant="contained"
                        onClick={() => {
                          if (window.confirm("確認刪除？")) {
                            dispatch(deleteOtherBudget(o.id, userId));
                            RpAlert.show("刪除成功！");
                          }
                        }}
                      >
                        刪除
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  無資料
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </MuiSpin>

      <EditDialog otherBudget={otherBudget} open={open} onClose={handleClose} />
    </div>
  );
};

export default OtherTable;
