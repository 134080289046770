import { User } from "@/types";

import axios from "./axiosService";

const URLS = {
  OUT_REVIEWER: {
    INDEX: "/outReviewer",
    MSG: {
      INDEX: "/outReviewer/msg/",
      ASSIGN: "/outReviewer/msg/assign/",
    },
    LOGIN: "/login/outReviewer",
  },
};

/**
 * 登入
 * @param reviewer
 */
export const postOutReviewerLogin = (reviewer: User) =>
  axios.post(URLS.OUT_REVIEWER.LOGIN, reviewer);

/**
 * 取得外審委員資料(單筆)
 * @param id
 */
export const getOutReviewer = (id: string | number) =>
  axios.get(URLS.OUT_REVIEWER.INDEX + "/" + id);

/**
 * 取得所有外審委員資料
 */
export const getOutReviewers = () => axios.get(URLS.OUT_REVIEWER.INDEX);

/**
 * 確認資料
 * @param data
 */
export const putOutReviewer = (id: string, data: any) =>
  axios.put(URLS.OUT_REVIEWER.INDEX + "/" + id, data);

/**
 * 指派
 */
export const postOutReviewerAssign = (outReviewerId: number, userId: string) =>
  axios.post(URLS.OUT_REVIEWER.INDEX + "/assign", null, { params: { outReviewerId, userId } });

/**
 * 修改指派的委員
 * @param outReviewerId
 * @param userId
 */
export const putOutReviewerAssign = (msgId: number, outReviewerId: number, userId: string) =>
  axios.put(URLS.OUT_REVIEWER.INDEX + "/assign", null, {
    params: { outReviewerId, userId, msgId },
  });

/**
 * 取得外審訊息(msg id)
 */
export const getOutReviewerMsg = (id: number | string) =>
  axios.get(URLS.OUT_REVIEWER.INDEX + `/msg/${id}`);

/**
 * 依計畫編號取得外審訊息
 * @param userId
 */
export const getOutReviewerMsgFromProject = (userId: string) =>
  axios.get(URLS.OUT_REVIEWER.MSG.INDEX + "user/" + userId);

/**
 * 送出審查表
 * @param data
 */
export const postOutReviewerMsgScore = (data: any) =>
  axios.post(URLS.OUT_REVIEWER.MSG.INDEX + "score/", data);

/**
 * 取得外審分數資料
 * @param id
 * @param userId
 */
export const getOutReviewerMsgScore = (id: number | string, userId: string) =>
  axios.get(URLS.OUT_REVIEWER.MSG.INDEX + "score/", { params: { id, userId } });

/**
 * 新增外審
 * @param data
 */
export const postOutReviewer = (data: any) => axios.post(URLS.OUT_REVIEWER.INDEX, data);

/**
 * 取得銀行資訊
 */
export const getBank = () => axios.get(URLS.OUT_REVIEWER.INDEX + "/bank");
