import React, { useEffect, useState } from "react";
import { List, ListItem } from "@material-ui/core";

import { useIfMounted } from "@/hooks";
import { getUser } from "@/services/userService";
import { Project, RpMustUser } from "@/types";

const UserInfo: React.FC<{ project: Project }> = ({ project }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<RpMustUser>({} as any);
  const ifMounted = useIfMounted();

  useEffect(() => {
    ifMounted(() => setLoading(true));
    getUser(project.userId).then((res) => {
      ifMounted(() => {
        setUser(res.data);
        setLoading(false);
      });
    });
  }, [ifMounted, project.userId]);

  return (
    <List>
      <ListItem>員工代號：{project.userId}</ListItem>
      <ListItem>姓名：{project.userName}</ListItem>
      <ListItem>單位：{loading ? "讀取中..." : user.unit}</ListItem>
      <ListItem>手機：{project.phone}</ListItem>
      <ListItem>分機：{project.ext}</ListItem>
    </List>
  );
};

export default UserInfo;
