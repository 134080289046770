import { lazy } from "react";

import AdminMain from "./Admin/Admin";
import AdminDashboard from "./Admin/Dashboard/Dashboard";
import AdminImportUser from "./Admin/ImportUser/ImportUser";
import AdminLog from "./Admin/Log/Log";
import AdminProjectEdit from "./Admin/ProjectEdit/ProjectEdit";
import AdminProjectManager from "./Admin/ProjectManager/ProjectManager";
import AdminProjectType from "./Admin/ProjectType/ProjectType";
import AdminReportManager from "./Admin/ReportManager/ReportManager";
import AdminReviewerIn from "./Admin/Reviewer/In/In";
import AdminReviewerOut from "./Admin/Reviewer/Out/Out";
import AdminSetting from "./Admin/Setting/Setting";
import AdminUserManager from "./Admin/UserManager/UserManager";

export { default as Login } from "./Login/Login";

export const Home = lazy(() => import("./Home/Home"));

export const NotFound = lazy(() => import("./NotFound/NotFound"));

export const File = lazy(() => import("./File/File"));

//計畫
export const Project = lazy(() => import("./Project/Project"));

export const ProjectReadme = lazy(() => import("./Project/Readme/Readme"));

export const ProjectViewer = lazy(() => import("./Project/Viewer/Viewer"));

export const ProjectPrint = lazy(() => import("./Project/Print/Print"));

export const ProjectAdd = lazy(() => import("./Project/Add/Add"));

export const ProjectManager = lazy(() => import("./Project/Manager/Manager"));

/**
 * 系統管理
 */
export const Admin = {
  /**
   * 主介面(含路由)
   */
  Index: AdminMain,

  /**
   * 主介面(Dashboard)
   */
  Dashboard: AdminDashboard,

  /**
   * 計畫管理
   */
  ProjectManager: AdminProjectManager,

  /**
   * 計畫編輯
   */
  ProjectEdit: AdminProjectEdit,

  /**
   * 計畫類型
   */
  ProjectType: AdminProjectType,

  /**
   * 結案報告管理
   */
  ReportManager: AdminReportManager,

  /**
   * 設定
   */
  Setting: AdminSetting,

  /**
   * 匯入使用者
   */
  ImportUser: AdminImportUser,

  /**
   * 使用者管理
   */
  UserManager: AdminUserManager,

  /**
   * 內審委員管理
   */
  ReviewerIn: AdminReviewerIn,

  /**
   * 外審委員管理
   */
  ReviewerOut: AdminReviewerOut,

  /**
   * Log記錄檔
   */
  Log: AdminLog,
};

/**
 * 結案報告上傳
 */
export const Report = {
  Index: lazy(() => import("./Report/Report")),
  Readme: lazy(() => import("./Report/Readme/Readme")),
  Add: lazy(() => import("./Report/Add/Add")),
};

//reviewer審查委員
export const Reviewer = lazy(() => import("./Reviewer/InReviewer"));

//開始審查
//內審
export const StartReviewInReviewer = lazy(
  () => import("./Reviewer/StartReview/InReviewer/InReviewer")
);

/**
 * 外審
 */
export const OutReviewer = lazy(() => import("./OutReviewer/OutReviewer"));

/**
 * 外審清單
 */
export const OutReviewerList = lazy(() => import("./OutReviewer/List"));

/**
 * 外審表格
 */
export const OutReviewerEdit = lazy(() => import("./OutReviewer/Edit"));
