import axios from "axios";

import store from "@/store";

//預設url
const baseURL = `${window.location.origin}/api/`;
// const baseURL =
//   process.env.NODE_ENV !== "production"
//     ? "http://localhost:50382/api/"
//     : `${window.location.origin}/api/`;

//初始化axios
const service = axios.create({
  baseURL: baseURL,
  timeout: 30000,
});

service.interceptors.request.use(
  (config) => {
    //每個header都加上token
    const token = getToken();
    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * 從redux取得token
 */
function getToken() {
  return store.getState().auth.token;
}

export default service;
