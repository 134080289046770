import { getAllLYProject } from "@/services/reportService";
import {
  Card,
  CardContent,
  CardHeader,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";

type Report = {
  chId: string;
  unit: string;
  userName: string;
  projectName: string;
  isSubmit: boolean;
  link: string[];
};

const ReportManager: React.FC = () => {
  const [reports, setReports] = useState<Report[]>([]);
  useEffect(() => {
    getAllLYProject().then((res) => {
      setReports(res.data);
    });
  }, []);
  return (
    <Card>
      <CardHeader title="結案報告管理" />
      <CardContent>
        {/* <MUIDataTable
          title="結案報告管理"
          columns={[
            {
              name: "index",
              label: "序號",
            },
            {
              name: "chId",
              label: "計畫編號",
            },
            { name: "unit", label: "單位" },
            { name: "userName", label: "姓名" },
            { name: "projectName", label: "計畫名稱" },
            {
              name: "link",
              label: "檔案連結",
              options: {
                customBodyRender: (props) => (
                  <div>
                    <a href={`/file/${btoa(`/api/report/file/${props}`)}`}>檢視檔案</a>
                  </div>
                ),
              },
            },
            {
              name: "status",
              label: "狀態",
              options: {
                customBodyRender: (props) => {
                  console.log(props);
                  return <div>{props}</div>;
                },
              },
            },
          ]}
          data={reports}
          options={{
            elevation: 0, // 無陰影
            download: false, // 無下載csv
            print: false, // 列印
            selectableRowsHeader: false, // checkbox
            selectableRowsHideCheckboxes: true,
            viewColumns: false,
            filter: false,
          }}
        /> */}
        <Table>
          <colgroup>
            <col style={{ width: "5%" }} />
            <col style={{ width: "14%" }} />
            <col style={{ width: "19%" }} />
            <col style={{ width: "8%" }} />
            <col style={{ width: "auto" }} />
            <col style={{ width: "11%" }} />
            <col style={{ width: "11%" }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell>序號</TableCell>
              <TableCell>計畫編號</TableCell>
              <TableCell>單位</TableCell>
              <TableCell>姓名</TableCell>
              <TableCell>計畫名稱</TableCell>
              <TableCell>檔案連結</TableCell>
              <TableCell>狀態</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reports.map((report, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{report.chId}</TableCell>
                <TableCell>{report.unit}</TableCell>
                <TableCell>{report.userName}</TableCell>
                <TableCell>{report.projectName}</TableCell>
                <TableCell>
                  {report.link.map(
                    (l, index) =>
                      l && (
                        <div key={index}>
                          <Link href={`/api/report/file/${l}`} target="_blank">
                            檢視檔案
                          </Link>
                        </div>
                      )
                  )}
                </TableCell>
                <TableCell>{report.isSubmit ? "已送出" : "編輯中"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default ReportManager;
