import React from "react";
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";

import { MuiSpin } from "@/components";
import { Budget } from "@/types";

type PartTimeTableType = {
  budgets: Budget[];
  loading: boolean;
  total: number;
  onEdit: (b: Budget) => void;
  onDelete: (id: number) => void;
};

const PartTimeTable: React.FC<PartTimeTableType> = ({
  budgets,
  loading,
  total,
  onEdit,
  onDelete,
}) => {
  return (
    <MuiSpin spinning={loading}>
      <Table>
        <caption style={{ textAlign: "right" }}>總合：{total} 元</caption>
        <TableHead>
          <TableRow>
            <TableCell>項次</TableCell>
            <TableCell>工讀內容</TableCell>
            <TableCell>時數</TableCell>
            <TableCell>時薪</TableCell>
            <TableCell>合計</TableCell>
            <TableCell style={{ width: "20%" }} align="center">
              操作
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {budgets.length !== 0 ? (
            budgets.map((b, i) => (
              <TableRow key={i} hover>
                <TableCell>{i + 1}</TableCell>
                <TableCell>{b.detail}</TableCell>
                <TableCell>{b.hours}</TableCell>
                <TableCell>{b.salary}元</TableCell>
                <TableCell>{b.hours * b.salary}元</TableCell>
                <TableCell align="center">
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={() => onEdit(b)}
                  >
                    編輯
                  </Button>{" "}
                  <Button
                    color="secondary"
                    size="small"
                    variant="contained"
                    onClick={() => {
                      window.confirm("確認刪除？") && onDelete(b.id);
                    }}
                  >
                    刪除
                  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6} style={{ textAlign: "center" }}>
                無資料
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </MuiSpin>
  );
};

export default PartTimeTable;
