import ReactDOM from "react-dom";
import React, { useState } from "react";
import { Snackbar } from "@material-ui/core";
import { Alert, Color } from "@material-ui/lab";

interface IRpAlert {
  show: (msg: any, severity?: Color) => void;
  error: (msg: any) => void;
}

let _alert: any = {};

const MuiAlert: React.FC<{ msg: string; severity: Color }> = ({
  msg,
  severity,
}) => {
  const [open, setOpen] = useState(true);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const onExited = () => {
    remove();
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
      autoHideDuration={1800}
      disableWindowBlurListener={true}
      onClose={handleClose}
      onExited={onExited}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        elevation={6}
        variant="filled"
      >
        {msg}
      </Alert>
    </Snackbar>
  );
};

/**
 * 從body刪除alert組件
 */
const remove = () => {
  ReactDOM.unmountComponentAtNode(_alert.container);
  _alert.dom.remove();
  _alert = {};
};

export class RpAlert {
  private static showComponent(msg: any, severity: Color = "success") {
    if (_alert.dom || _alert.container) {
      remove();
    }

    let div = document.createElement("div");
    let dom = document.querySelector("body")?.appendChild(div);

    _alert.container = div;
    _alert.dom = dom;
    ReactDOM.render(<MuiAlert msg={String(msg)} severity={severity} />, div);
  }

  static error(msg: any) {
    this.showComponent(msg, "error");
  }
  static show(msg: any, severity?: Color) {
    this.showComponent(msg, severity);
  }
}
