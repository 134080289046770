import React, { useState } from "react";
import {
  Button,
  List,
  ListItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { postImportUser } from "@/services/userService";
import { MuiSpin, RpAlert } from "@/components";

type ImportResult = {
  success: boolean;
  userId: string;
  email: string;
  name: string;
  department: string;
  title: string;
  reason: string;
};

const ImportUser: React.FC = () => {
  const [file, setFile] = useState<File>();
  const [result, setResult] = useState<{
    total: number; //總共幾筆
    success: number; //成功數量
    failed: number; //失敗數量
    logs: ImportResult[];
  }>({
    total: 0,
    success: 0,
    failed: 0,
    logs: [],
  });
  const [loading, setLoading] = useState(false);

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const eFile = e.target.files![0];
    setFile(eFile);
  };

  const handleClick = () => {
    if (!file || file.size < 1) {
      RpAlert.error("請上傳檔案");
      return;
    }

    const data = new FormData();

    data.append("file", file);

    setLoading(true);
    postImportUser(data)
      .then((res) => {
        setResult(res.data);
        setLoading(false);
      })
      .catch(() => {
        RpAlert.error("匯入失敗，請檢查Excel格式");
        setLoading(false);
      });
  };

  return (
    <Paper>
      <MuiSpin spinning={loading}>
        <List>
          <ListItem>
            匯入使用者：
            <input
              type="file"
              name="file"
              id="file"
              accept=".xlsx"
              onChange={handleUpload}
            />
            <br />
            <Button variant="contained" color="primary" onClick={handleClick}>
              上傳
            </Button>
          </ListItem>
          {result.total > 0 ? (
            <ListItem>
              成功：{result.success} <br />
              失敗：{result.failed} <br />
              總共 {result.total} 筆
            </ListItem>
          ) : null}
          <ListItem>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow
                    style={{ borderTop: "1px solid rgba(224, 224, 224, 1)" }}
                  >
                    <TableCell>結果</TableCell>
                    <TableCell>使用者代號</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>名稱</TableCell>
                    <TableCell>院系</TableCell>
                    <TableCell>職稱</TableCell>
                    <TableCell>失敗原因</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {result.logs.length > 0 ? (
                    result.logs.map((r, i) => (
                      <TableRow
                        key={i}
                        hover
                        style={{
                          background: r.success
                            ? "rgb(237, 247, 237)"
                            : "rgb(253, 236, 234)",
                        }}
                      >
                        <TableCell>{r.success ? "成功" : "失敗"}</TableCell>
                        <TableCell>{r.userId}</TableCell>
                        <TableCell>{r.email}</TableCell>
                        <TableCell>{r.name}</TableCell>
                        <TableCell>{r.department}</TableCell>
                        <TableCell>{r.title}</TableCell>
                        <TableCell>{r.reason}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        請先匯入
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </ListItem>
        </List>
      </MuiSpin>
    </Paper>
  );
};

export default ImportUser;
