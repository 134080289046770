import React from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Button, Divider, Grid, List, ListItem, Typography } from "@material-ui/core";

import { StoreTypes } from "@/store";
import { ROUTES } from "@/constants";

import { MuiSpin } from "..";

const TotalBoard: React.FC = () => {
  const { otherBudgetTotal, budgetTotal, isLoading } = useSelector(
    (state: StoreTypes) => state.budget
  );
  const history = useHistory();

  return (
    <MuiSpin spinning={isLoading}>
      <List>
        <ListItem
          button
          style={{ height: 52 }}
          component={Link}
          to={`${ROUTES.PROJECT.MANAGER_BUDGET.OTHER}`}
        >
          <Grid container>
            <Grid item xs={8}>
              研究耗材及其他費用
            </Grid>
            <Grid item xs={2}>
              <Typography color="primary">進入編列</Typography>
            </Grid>
            <Grid item xs={2}>
              {otherBudgetTotal}元
            </Grid>
          </Grid>
        </ListItem>
        <Divider />
        <ListItem
          button
          style={{ height: 52 }}
          component={Link}
          to={`${ROUTES.PROJECT.MANAGER_BUDGET.PART_TIME}`}
        >
          <Grid container>
            <Grid item xs={8}>
              工讀生費用
            </Grid>
            <Grid item xs={2}>
              <Typography color="primary">進入編列</Typography>
            </Grid>
            <Grid item xs={2}>
              <span
                style={{
                  color: budgetTotal > (otherBudgetTotal + budgetTotal) / 2 ? "red" : "",
                }}
              >
                {budgetTotal}元
              </span>
            </Grid>
          </Grid>
        </ListItem>
        <Divider />
        <ListItem>
          <Grid container>
            <Grid item xs={10}>
              總計
            </Grid>
            <Grid item xs={2}>
              <span
                style={{
                  color: budgetTotal > (otherBudgetTotal + budgetTotal) / 2 ? "red" : "",
                }}
              >
                {otherBudgetTotal + budgetTotal}元
              </span>
            </Grid>
          </Grid>
        </ListItem>
        <ListItem style={{ display: "flex", justifyContent: "center" }}>
          <Button
            color="primary"
            variant="contained"
            disabled={otherBudgetTotal < 1 || budgetTotal > (otherBudgetTotal + budgetTotal) / 2}
            onClick={() => history.push(ROUTES.PROJECT.MANAGER + "/submit")}
          >
            預算編列完成
          </Button>
        </ListItem>
      </List>
    </MuiSpin>
  );
};

export default TotalBoard;
