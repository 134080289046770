import React from "react";
import { Redirect, Switch } from "react-router";

import { AuthorizeRoute } from "@/components";
import { ROUTES } from "@/constants";
import { Admin } from "@/views/";

const AdminRouter: React.FC = () => {
  return (
    <Switch>
      <AuthorizeRoute path={ROUTES.ADMIN.PROJECT.INDEX} component={Admin.ProjectManager} exact />
      <AuthorizeRoute path={ROUTES.ADMIN.REPORT.INDEX} component={Admin.ReportManager} exact />
      <AuthorizeRoute path={`${ROUTES.ADMIN.PROJECT.EDIT}/:userId`} component={Admin.ProjectEdit} />
      <AuthorizeRoute path={ROUTES.ADMIN.PROJECT_TYPE} component={Admin.ProjectType} />
      <AuthorizeRoute path={ROUTES.ADMIN.REVIEWER.IN} component={Admin.ReviewerIn} />
      <AuthorizeRoute path={ROUTES.ADMIN.REVIEWER.OUT} component={Admin.ReviewerOut} />
      <AuthorizeRoute path={ROUTES.ADMIN.SETTING} component={Admin.Setting} />
      <AuthorizeRoute path={ROUTES.ADMIN.USER.INDEX} component={Admin.UserManager} exact />
      <AuthorizeRoute path={ROUTES.ADMIN.USER.IMPORT} component={Admin.ImportUser} />
      <AuthorizeRoute path={ROUTES.ADMIN.TEACHER_SCORE} component={Admin.Log} />
      <AuthorizeRoute path={ROUTES.ADMIN.LOG} component={Admin.Log} />
      <Redirect to={ROUTES.ADMIN.PROJECT.INDEX} from={ROUTES.ADMIN.INDEX} exact />
    </Switch>
  );
};

export default AdminRouter;
