import { User } from "@/types";

import axios from "./axiosService";

//登入--/api/login
export const login = (user: User) => {
  return axios.post("/login/user/", user);
};

/**
 * 登入審查委員
 * @param reviewer
 */
export const loginReviewer = (reviewer: User) => {
  return axios.post("/login/reviewer/", reviewer);
};

//驗證token--/api/login/check-jwt
export const checkAuth = () => {
  return axios.get("/login/check-jwt");
};
