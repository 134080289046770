import React from "react";
import { Switch } from "react-router-dom";

import { AuthorizeRoute } from "@/components";
import { ROUTES } from "@/constants";
import { Reviewer } from "@/views";

const ReviewerRouter: React.FC = () => {
  return (
    <Switch>
      <AuthorizeRoute
        path={ROUTES.REVIEWER.IN_REVIEWER.INDEX + "/:reviewerId?"}
        component={Reviewer}
      />
    </Switch>
  );
};

export default ReviewerRouter;
