import { AUTH_ACTION, AuthActionTypes } from "@/action/authAction";
import { Role, RpMustUser } from "@/types";

type AuthState = {
  loading: boolean;
  isAuthenticated: boolean;
  token: string;
  user: RpMustUser;
  roles: Role[];
};

export enum AUTH_ITEMS {
  USER_INFO = "USER_INFO",
}

const auth = JSON.parse(localStorage.getItem(AUTH_ITEMS.USER_INFO) as string) || {};

const init = (): AuthState => {
  const isAuthenticated: boolean =
    typeof auth.token !== "undefined" && typeof auth.user !== "undefined";

  const user: RpMustUser = auth.user || {
    userId: "",
    unit: "",
    title: "",
    name: "",
    email: "",
  };

  const token: string = auth.token || "";

  return {
    loading: false,
    isAuthenticated: isAuthenticated,
    token: token,
    user,
    roles: auth.roles ? auth.roles : [],
  };
};

export default (state = init(), action: AuthActionTypes): AuthState => {
  switch (action.type) {
    case AUTH_ACTION.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
      };
    case AUTH_ACTION.LOGIN_SUCCESS_REVIEWER:
      const { reviewer, token, roles } = action.payload.data;

      const newObj: AuthState = {
        loading: false,
        roles,
        token,
        user: {
          title: "",
          unit: "",
          email: reviewer.account,
          name: reviewer.name,
          userId: String(reviewer.id),
        },
        isAuthenticated: true,
      };

      localStorage.setItem(AUTH_ITEMS.USER_INFO, JSON.stringify(newObj));
      return {
        ...state,
        ...newObj,
      };
    case AUTH_ACTION.LOGOUT:
    case AUTH_ACTION.LOGIN_FAILED:
      localStorage.removeItem(AUTH_ITEMS.USER_INFO);
      return {
        loading: false,
        isAuthenticated: false,
        token: "",
        user: {
          userId: "",
          unit: "",
          title: "",
          name: "",
          email: "",
        },
        roles: [],
      };
    case AUTH_ACTION.SET_USER_INFO:
      localStorage.setItem(AUTH_ITEMS.USER_INFO, JSON.stringify(action.payload.user));
      return {
        ...state,
        ...action.payload.user,
      };
    default:
      return state;
  }
};
