import { useEffect } from "react";

type TitleType = (() => string) | string;

/**
 * 更改標題
 * @param title 標題
 */
export const useTitle = (title: TitleType) => {
  useEffect(() => {
    if (typeof title === "function") {
      document.title = title();
    } else if (typeof title === "string") {
      document.title = title;
    }
  }, [title]);
};
