import React from "react";
import { Spin } from "antd";
import { CircularProgress } from "@material-ui/core";
import { SpinProps } from "antd/lib/spin";

const MuiSpin: React.FC<SpinProps> = ({ children, ...props }) => {
  return (
    <Spin {...props} indicator={<CircularProgress color="primary" />}>
      {children}
    </Spin>
  );
};

export default MuiSpin;
