import moment from "moment";
import React, { useEffect, useState } from "react";

import { getProject, getTeacherScore } from "@/services/projectService";
import { getBudgetCount, getOtherBudgetsCount } from "@/services/budgetService";
import { getUser } from "@/services/userService";
import { Project, RpMustUser } from "@/types";

import classes from "./PrintProject.module.scss";
import { TeacherScoreType } from "../TeacherScore/TeacherScore";
import { MuiSpin, RpAlert } from "..";

type PrintProjectType = {
  userId: string;
  shadow?: boolean;
  style?: React.CSSProperties;
};

const PrintProject: React.FC<PrintProjectType> = ({ userId, style, shadow = false }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<RpMustUser>({} as any);
  const [project, setProject] = useState<Project>({} as any);
  const [budgetTotal, setBudgetTotal] = useState(0);
  const [otherBudgetTotal, setOtherBudgetTotal] = useState(0);
  const [teacherScore, setTeacherScore] = useState<TeacherScoreType[]>([]);

  useEffect(() => {
    (async () => {
      setLoading(true);

      try {
        const project = (await getProject(userId)).data;
        const teacherScore = (await getTeacherScore(userId)).data;
        const user = (await getUser(userId)).data;
        const budgetTotal = (await getBudgetCount(userId)).data;
        const otherBudgetTotal = (await getOtherBudgetsCount(userId)).data;

        setUser(user);
        setProject(project);
        setTeacherScore(teacherScore);
        setBudgetTotal(budgetTotal);
        setOtherBudgetTotal(otherBudgetTotal);
        setLoading(false);
      } catch {
        RpAlert.error("請求資料發生錯誤，請重新整理後再試一次");
      }
    })();
  }, [userId]);

  return (
    <MuiSpin spinning={loading}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          className={classes["print-box"]}
          style={{ boxShadow: shadow ? "1px 1px 4px 1px" : "", ...style }}
        >
          <h3>明新學校財團法人明新科技大學校內專題研究計畫申請表</h3>
          <table cellSpacing="1" cellPadding="8">
            <tbody>
              {/* 計畫類型 */}
              <tr>
                <td rowSpan={4} className={classes.title} style={{ width: "13%" }}>
                  計畫類型
                </td>
              </tr>

              {/* 計畫類型 */}
              <tr>
                <td colSpan={1} className={classes.title}>
                  申請類型
                </td>
                <td colSpan={4}>{project.type}</td>
              </tr>
              <tr>
                <td colSpan={1} className={classes.title}>
                  衍生成果
                </td>
                <td colSpan={4}>{project.property}</td>
              </tr>
              <tr>
                <td colSpan={1} className={classes.title}>
                  學門分類
                </td>
                <td colSpan={4}>{project.most}</td>
              </tr>

              {/* 申請人所屬單位 */}
              <tr>
                <td className={classes.title}>
                  申請人
                  <br />
                  所屬單位
                </td>
                <td colSpan={4}>{user.unit}</td>
              </tr>

              {/* 計畫主持人 */}
              <tr>
                <td rowSpan={2} className={classes.center}>
                  計畫
                  <br />
                  主持人
                </td>
                <td style={{ width: "9%" }}>
                  <div className={classes.center}>姓名</div>
                </td>
                <td colSpan={3}>
                  ( {user.userId} ) {user.name + user.title}
                </td>
              </tr>
              <tr>
                <td>
                  <div className={classes.center}>聯絡方式</div>
                </td>
                <td colSpan={3}>
                  分機：{project.ext} ， 手機：{project.phone} ( {user.email} )
                </td>
              </tr>

              {/* 計畫名稱 */}
              <tr>
                <td className={classes.center}>
                  中文計畫
                  <br />
                  名稱
                </td>
                <td colSpan={4}>{project.name}</td>
              </tr>

              {/* 教師年資 */}
              <tr>
                <td className={classes.center}>
                  教師服務
                  <br />
                  年資
                </td>
                <td colSpan={4}>
                  {project.isNewTeacher
                    ? `新進教師（${moment(project.appointmentDate).format("YYYY/MM")}-至今）`
                    : "申請人已到校超過三年以上"}
                </td>
              </tr>

              {/* 教師績效 */}
              {/* <tr>
                <td className={classes.center} style={{ padding: 0 }}>
                  教師績效
                  <br />
                  自選項目
                </td>
                <td style={{ padding: 0 }}>
                  <div className={classes.center}>
                    滿分 30 分
                    <br />
                    總計：
                    {teacherScore.reduce((a, b) => a + b.score, 0) > 30
                      ? 30
                      : teacherScore.reduce((a, b) => a + b.score, 0)}
                    分
                  </div>
                </td>
                <td colSpan={3} style={{ padding: 0 }}>
                  <div className={classes["score-container"]}>
                    {teacherScore &&
                      teacherScore.length > 0 &&
                      teacherScore.map((t, i) => (
                        <div
                          className={classes.row}
                          key={i}
                          style={{
                            height: teacherScore.length === 1 ? 60 : "auto",
                          }}
                        >
                          <div className={`${classes.col} ${classes.title}`}>
                            {`${t.teacherPerformance.class}(${
                              t.teacherPerformance.className
                            })-${
                              t.teacherPerformance.description.split("。")[0]
                            }-${t.evidence.title}`}
                            {t.teacherPerformance.method === 3 && "元"}
                          </div>
                          <div
                            className={`${classes.col} ${classes.content}`}
                            style={{ justifyContent: "center" }}
                          >
                            {t.score}分
                          </div>
                        </div>
                      ))}
                    {teacherScore.length === 0 ? "　無教師績效" : null}
                  </div>
                </td>
              </tr> */}

              {/* 經費 */}
              <tr>
                <td rowSpan={3}>
                  <div className={classes.center}>
                    申請計畫
                    <br />
                    經費
                  </div>
                </td>
                <td>
                  <div className={classes.center}>項目</div>
                </td>
                <td style={{ width: "21%" }} colSpan={2}>
                  <div className={classes.center}>研究耗材與其他費用</div>
                </td>
                <td style={{ width: "22%" }}>
                  <div className={classes.center}>工讀生費用</div>
                </td>
              </tr>

              {/* 經費 */}
              <tr>
                <td>
                  <div className={classes.center}>預算金額</div>
                </td>
                <td colSpan={2}>
                  <div className={classes.center}>{otherBudgetTotal}元</div>
                </td>
                <td>
                  <div className={classes.center}>{budgetTotal}元</div>
                </td>
              </tr>

              <tr>
                <td colSpan={4}>
                  <div className={classes.center}>總計： {otherBudgetTotal + budgetTotal}元</div>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <table style={{ width: "100%" }} cellSpacing={2} cellPadding={2}>
            <tbody>
              <tr>
                <td style={{ width: "13%", height: "17px" }}>
                  <div className={classes.center}>
                    計　畫
                    <br />
                    主持人
                    <br />
                    簽　章
                  </div>
                </td>
                <td style={{ width: "33%" }} valign="top">
                  <p>
                    茲同意已完成6小時之學術研究倫理課程，否則依本校教師學術研究倫理教育課程實施要點，放棄本年度校內專題申請。
                  </p>
                  <p>&nbsp;</p>
                </td>
                <td style={{ width: "13%" }}>
                  <div className={classes.center}>申請日期</div>
                </td>
                <td style={{ width: "41%" }}>
                  <div className={classes.center}>
                    {moment(project.date).format("YYYY年MM月DD日")}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className={classes.center}>單位主管</div>
                </td>
                <td>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                </td>
                <td>
                  <div className={classes.center}>院主管</div>
                </td>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
          {
            "申請表請送紙本並經 計畫主持人簽章-->系主任核章-->院長核章-->送院助理，統一送交研發處研技中心。"
          }
        </div>
      </div>
    </MuiSpin>
  );
};

export default PrintProject;
