import * as Yup from "yup";
import React from "react";
import { ErrorMessage, Formik } from "formik";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  TextField,
} from "@material-ui/core";

import { MuiSpin } from "@/components";

type UserIdInputDialogProps = {
  email: string;
  password: string;
  loading: boolean;
  onLogin: (value: any) => void;
} & DialogProps;

/**
 * 輸入員工代號Dialog
 */
const UserIdInputDialog: React.FC<UserIdInputDialogProps> = ({
  open,
  email,
  password,
  loading = false,
  onClose,
  onLogin,
}) => {
  return (
    <Dialog
      open={open}
      disableBackdropClick={loading}
      disableEscapeKeyDown={loading}
      onClose={onClose}
    >
      <DialogTitle>請輸入員工代號</DialogTitle>
      <Formik
        initialValues={{
          userId: "",
        }}
        validationSchema={Yup.object({
          userId: Yup.string().required("請輸入員工代號"),
        })}
        onSubmit={(v) => {
          const data = { email, password, userId: v.userId };
          onLogin(data);
        }}
      >
        {({ handleSubmit, getFieldProps }) => (
          <React.Fragment>
            <DialogContent>
              <MuiSpin spinning={loading}>
                <DialogContentText>僅第一次登入需要</DialogContentText>
                <form onSubmit={handleSubmit}>
                  <div>
                    <TextField
                      variant="outlined"
                      disabled={loading}
                      margin="normal"
                      size="small"
                      fullWidth
                      label="員工代號 (EX:A00XXX)"
                      {...getFieldProps("userId")}
                    />
                    <div style={{ color: "red" }}>
                      <ErrorMessage name="userId" />
                    </div>
                  </div>
                </form>
              </MuiSpin>
            </DialogContent>
            <DialogActions style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                color="primary"
                disabled={loading}
                onClick={() => handleSubmit()}
              >
                確認
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      </Formik>
    </Dialog>
  );
};

export default UserIdInputDialog;
