import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { requestBudgetList, requestOtherBudgetList } from "@/action/budgetAction";
import { requestFiles, requestProject } from "@/action/projectAction";
import { useRpMustUser } from "@/hooks";
import { StoreTypes } from "@/store";
import { ProjectResult } from "@/types";

/**
 * 取得計畫資料
 */
export const useProject = (currentUserId?: string): ProjectResult => {
  const { isLoading, hasProject, data: project } = useSelector(
    (state: StoreTypes) => state.project
  );
  const user = useRpMustUser();
  const dispatch = useDispatch();

  const userId = currentUserId ? currentUserId : user.userId;

  useEffect(() => {
    // if (isEmpty(project.name)) {
    dispatch(requestProject(userId));
    dispatch(requestFiles(userId));
    dispatch(requestBudgetList(userId));
    dispatch(requestOtherBudgetList(userId));
    // }
    // }, [userId, dispatch, project.name]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { isLoading, hasProject, project };
};
