import React from "react";

import { RpMust } from "@/constants";

const Contact: React.FC = () => {
  return (
    <div style={{ textAlign: "center" }}>
      如有任何疑問，請與 研發處研技中心聯絡人 {RpMust.contact.name} 聯繫
      <br />
      電話： {RpMust.contact.tel}
      <br />
      email：{RpMust.contact.email}
    </div>
  );
};

export default Contact;
