import _ from "lodash";
import React, { useContext, useRef } from "react";
import { IconButton, InputAdornment, TextField, Tooltip } from "@material-ui/core";
import { Close, Search } from "@material-ui/icons";

import { ProjectContext } from "./ProjectManager";

const SearchInput: React.FC = () => {
  const { projects, setCurrentProjects, onPageChange } = useContext(ProjectContext);
  const inputRef = useRef<HTMLInputElement>();

  const startSearch = (text: string) => {
    onPageChange(1);
    if (text === "") {
      setCurrentProjects(projects);
      return;
    }

    const arr = projects.filter((p) => {
      let isTrue: boolean = false;
      const pArr = Object.values(p);

      pArr.forEach((el) => {
        if (el && el.toString().toLowerCase().includes(text.toLowerCase())) {
          isTrue = true;
          return;
        }
      });

      return isTrue;
    });

    setCurrentProjects(arr);
  };

  /**
   * 延遲搜尋，防止卡頓
   */
  const searchDebounce = _.debounce(startSearch, 500);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const text = e.target.value.trim();
    searchDebounce(text);
  };

  return (
    <Tooltip title="輸入想查詢的資料，例如員工代號，姓名，計畫名稱等等...">
      <TextField
        size="small"
        placeholder="搜尋"
        inputRef={inputRef}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" style={{ marginTop: -3 }}>
              <Search />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                onClick={() => {
                  if (inputRef.current) {
                    inputRef.current.value = "";
                    setCurrentProjects(projects);
                  }
                }}
              >
                <Close />
              </IconButton>
            </InputAdornment>
          ),
        }}
        onChange={handleChange}
      />
    </Tooltip>
  );
};

export default SearchInput;
