import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

import { getTeacherScore } from "@/services/projectService";

import ScoreBoardItem from "../TeacherScore/ScoreBoardItem";
import { TeacherScoreType } from "../TeacherScore/TeacherScore";
import { MuiSpin } from "..";

type TeacherScoreInfoDialogProps = {
  userId: string;

  /**
   * 老師的名稱
   */
  name?: string;
} & DialogProps;

const TeacherScoreInfoDialog: React.FC<TeacherScoreInfoDialogProps> = ({
  userId,
  name = "",
  open,
  onClose,
}) => {
  const [loading, setLoading] = useState(true);
  const [teacherScore, setTeacherScore] = useState<TeacherScoreType[]>([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (!userId) {
      return;
    }
    setLoading(true);
    getTeacherScore(userId).then((res) => {
      setLoading(false);
      if (res.data.message) {
        return;
      }
      setTeacherScore(res.data);
      let scoreTotal = res.data.reduce((a: any, b: any) => a + b.score, 0);
      setTotal(scoreTotal);
    });

    return () => {
      setTeacherScore([]);
    };
  }, [userId]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ flex: "1" }}>教師績效 {name && `（${name}）`}</div>
          <div>
            <Button onClick={onClose as any}>
              <Close />
            </Button>
          </div>
        </div>
      </DialogTitle>
      <DialogContent style={{ overflowY: "overlay" as any }}>
        <MuiSpin spinning={loading}>
          <div>分數：{total} （最高採計30分）</div>
          <Table style={{ tableLayout: "fixed" }}>
            <colgroup>
              <col style={{ width: "10%" }} />
              <col style={{ width: "40%" }} />
              <col style={{ width: "12%" }} />
              <col style={{ width: "28%" }} />
              <col style={{ width: "10%" }} />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>分類</TableCell>
                <TableCell style={{ width: "16rem" }}>項目</TableCell>
                <TableCell align="center">選項</TableCell>
                <TableCell style={{ paddingLeft: 32 }}>佐證</TableCell>
                <TableCell align="center">分數</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teacherScore && teacherScore.length > 0 ? (
                teacherScore.map((t, i) => <ScoreBoardItem key={i} t={t} edit={false} />)
              ) : (
                <TableRow>
                  <TableCell colSpan={6} style={{ textAlign: "center" }}>
                    無資料
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </MuiSpin>
      </DialogContent>
      <DialogActions style={{ display: "flex", justifyContent: "center" }}>
        <Button variant="contained" color="primary" onClick={onClose as any}>
          確認
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TeacherScoreInfoDialog;
