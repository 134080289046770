import React, { useContext } from "react";
import { Radio, TableCell, TableRow } from "@material-ui/core";

import { TeacherPerformance } from "@/types";

import classes from "../style.module.scss";
import { TeacherScoreContext } from "../TeacherScore";

type TeacherPerformanceRowProps = {
  teacherPerformance: TeacherPerformance;
  i: number;
};

const TeacherPerformanceRow: React.FC<TeacherPerformanceRowProps> = ({
  teacherPerformance,
  i,
}) => {
  const {
    radio,
    changeRadio,
    changeTeacherPerformance,
    onConfirm,
  } = useContext(TeacherScoreContext);

  const handleChange = () => {
    changeRadio(teacherPerformance.id);
    changeTeacherPerformance(teacherPerformance);
  };

  const handleDoubleClick = () => {
    handleChange();
    onConfirm();
  };

  return (
    <TableRow
      hover
      className={classes["no-select"]}
      style={{
        cursor: "pointer",
        background: radio === teacherPerformance.id ? "#ccc" : "",
      }}
      title="點兩下選擇項目"
      onClick={handleChange}
      onDoubleClick={handleDoubleClick}
    >
      <TableCell>
        <Radio
          size="small"
          color="primary"
          checked={teacherPerformance.id === radio}
          value={teacherPerformance.id}
          onChange={handleChange}
        />
      </TableCell>
      <TableCell>{i + 1}</TableCell>
      <TableCell>{teacherPerformance.class}</TableCell>
      <TableCell>{teacherPerformance.className}</TableCell>
      <TableCell>
        {teacherPerformance.options.map((option) => (
          <div key={option.id}>+{option.score}</div>
        ))}
      </TableCell>
      <TableCell>
        {teacherPerformance.description.split("。")[0]} <br /> {/*說明 */}
        {teacherPerformance.description.split("。")[1]} <br />
        {/*此項最多得XX分 */}
      </TableCell>
      <TableCell>
        {teacherPerformance.options.map((option) => (
          <div key={option.id}>{option.name}</div>
        ))}
      </TableCell>
    </TableRow>
  );
};

export default TeacherPerformanceRow;
