import React, { memo, useContext, useEffect, useRef } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import { MuiSpin } from "@/components";

import classes from "../style.module.scss";
import TeacherPerformanceTable from "./TeacherPerformanceTable";
import TeacherScoreInput from "./TeacherScoreInput";
import { TeacherScoreContext } from "../TeacherScore";

type TeacherScoreDialogProps = {
  open: boolean;
  add?: boolean;
  edit?: boolean;
  onClose: () => void;
  onClickOther?: () => void;
  onConfirm?: () => void;
  onAdd?: () => void;
  onEdit?: () => void;
};

const TeacherScoreDialog: React.FC<TeacherScoreDialogProps> = ({
  open,
  add = false,
  edit = false,
  onClose,
  onClickOther = () => {},
  onConfirm = () => {},
  onAdd = () => {},
  onEdit = () => {},
}) => {
  const { loading, selectId, radio } = useContext(TeacherScoreContext);
  const scroll = useRef(0);
  const ref = useRef<HTMLDivElement>(null);

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const { scrollTop } = e.target as HTMLDivElement;
    if (scrollTop > 1) {
      scroll.current = scrollTop;
    }
  };

  useEffect(() => {
    //回復滾動的位置
    const div = ref.current as HTMLDivElement;
    if (div) {
      div.scrollTop = scroll.current;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [add]);

  return (
    <Dialog
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown={loading}
      open={open}
      maxWidth="lg"
      onClose={onClose}
    >
      <DialogTitle>
        <div className={classes["dialog-container"]}>
          <div style={{ flex: "1" }}>請選擇績效項目</div>
          <div>
            <Button disabled={loading} onClick={onClose}>
              <Close />
            </Button>
          </div>
        </div>
      </DialogTitle>
      <DialogContent
        style={{ paddingTop: add || edit ? "8px" : "0" }}
        ref={ref}
        onScroll={handleScroll}
      >
        <MuiSpin spinning={loading}>
          <div style={{ display: add || edit ? "none" : "block" }}>
            <TeacherPerformanceTable />
          </div>
          {add || edit ? <TeacherScoreInput /> : null}
        </MuiSpin>
      </DialogContent>
      <DialogActions style={{ display: "flex", justifyContent: "center" }}>
        {add || edit ? (
          <Button
            variant="contained"
            color="inherit"
            disabled={loading}
            onClick={edit ? onClose : onClickOther}
          >
            {edit ? "取消" : "返回"}
          </Button>
        ) : null}
        {add ? (
          <Button
            disabled={selectId === 0 || loading}
            variant="contained"
            color="primary"
            onClick={onAdd}
          >
            新增
          </Button>
        ) : edit ? (
          <Button disabled={loading} variant="contained" color="primary" onClick={onEdit}>
            修改
          </Button>
        ) : (
          <Button
            disabled={radio < 1 || loading}
            variant="contained"
            color="primary"
            size="large"
            onClick={onConfirm}
          >
            繼續
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default memo(TeacherScoreDialog);
