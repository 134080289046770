import React from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Button,
  Container,
  createMuiTheme,
  ThemeProvider,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { ExitToApp } from "@material-ui/icons";

import { ROUTES, RpMust } from "@/constants";
import { useLogout, useRpMustUser, useTitle } from "@/hooks";

const OutReviewerLayout: React.FC = ({ children }) => {
  const logout = useLogout();
  const { name } = useRpMustUser();
  useTitle("外審委員審查系統");
  return (
    <ThemeProvider
      theme={createMuiTheme({
        palette: {
          primary: {
            main: "#ef6c00",
          },
        },
      })}
    >
      <AppBar position="static" color="primary" elevation={0}>
        <Toolbar style={{ display: "flex", justifyContent: "center" }}>
          <Typography align="center" variant="h6" style={{ flex: "1" }}>
            <Link to={`${ROUTES.REVIEWER.OUT_REVIEWER.INDEX}`} style={{ color: "#fff" }}>
              明新科技大學 外審委員審查系統 ( {name} )
            </Link>
          </Typography>
          <Button
            variant="text"
            style={{ color: "#fff" }}
            startIcon={<ExitToApp />}
            onClick={() => logout()}
          >
            登出
          </Button>
        </Toolbar>
      </AppBar>

      <Container style={{ marginTop: "25px", height: "calc(100% - 104px)" }}>
        <div style={{ maxWidth: "768px", margin: "0 auto" }}>{children}</div>
      </Container>
    </ThemeProvider>
  );
};

export default OutReviewerLayout;
