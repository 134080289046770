import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";

import { requestBudgetList } from "@/action/budgetAction";
import { StoreTypes } from "@/store";
import { MuiSpin } from "@/components";

const PartTimeTable: React.FC<{ userId: string }> = ({ userId }) => {
  const dispatch = useDispatch();
  const { budgets, budgetTotal, isLoading } = useSelector(
    (state: StoreTypes) => state.budget
  );

  useEffect(() => {
    //抓取工讀預算
    dispatch(requestBudgetList(userId));
  }, [dispatch, userId]);

  return (
    <MuiSpin spinning={isLoading}>
      <Table>
        <colgroup>
          <col style={{ width: "7%" }} />
          <col />
          <col style={{ width: "12%" }} />
          <col style={{ width: "13%" }} />
          <col style={{ width: "10%" }} />
        </colgroup>
        <caption style={{ textAlign: "right" }}>總合：{budgetTotal} 元</caption>
        <TableHead>
          <TableRow>
            <TableCell>項次</TableCell>
            <TableCell>工讀內容</TableCell>
            <TableCell>時數</TableCell>
            <TableCell>時薪</TableCell>
            <TableCell>合計</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {budgets.length !== 0 ? (
            budgets.map((b, i) => (
              <TableRow hover key={i}>
                <TableCell>{i + 1}</TableCell>
                <TableCell>{b.detail}</TableCell>
                <TableCell>{b.hours}小時</TableCell>
                <TableCell>{b.salary}元</TableCell>
                <TableCell>{b.hours * b.salary}元</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5} style={{ textAlign: "center" }}>
                無資料
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </MuiSpin>
  );
};

export default memo(PartTimeTable);
