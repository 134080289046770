export const RpMust = {
  title: "校內專題研究計畫系統",
  dashboardTitle: "校內專題管理系統",
  contact: {
    name: "賴理玫小姐",
    email: "rose60@must.edu.tw",
    unit: "研發處研技中心",
    tel: "(03)5593142轉2619",
  },
  year: 110,
  minSalary: 160,
  url: {
    reviewer: "/110年校內專題研究計畫評審作業要點.pdf",
    academic: "/明新科技大學獎勵教師學術著作要點.pdf",
    most: "/科技部學門專長分類表.pdf",
    class: "/教師學術研究倫理教育課程實施要點.pdf",
    performanceTable: "/教師績效認列一覽表.pdf",
    project: "/計畫書.docx",
    personInfo: "/個人資料.docx",
    projectType: "/計畫類型.pdf",
    paperWord: "/結案報告格式.docx",
    paperPPT: "/成果海報格式.pptx",
    paperReadme: "/成果報告撰寫格式須知.docx",
    paperAuth: "/上網授權書.docx",
    minSalary: "https://www.mol.gov.tw/topic/3067/5990/13171/19154/",
    frdp: "http://frdp.must.edu.tw/",
  },
  fileMaxSize: 15728640,
  lastUpdate: "2020/08/01",
  sizeConvert: (limit: number) => {
    var size = "";
    if (limit < 0.1 * 1024) {
      //小於0.1KB轉成B
      size = limit.toFixed(2) + "B";
    } else if (limit < 0.1 * 1024 * 1024) {
      //小於0.1MB轉成KB
      size = (limit / 1024).toFixed(2) + "KB";
    } else if (limit < 0.1 * 1024 * 1024 * 1024) {
      //如果小於0.1GB轉成MB
      size = (limit / (1024 * 1024)).toFixed(2) + "MB";
    } else {
      //其他轉成GB
      size = (limit / (1024 * 1024 * 1024)).toFixed(2) + "GB";
    }

    var sizeStr = size + "";
    var len = sizeStr.indexOf("\\.");
    var dec = sizeStr.substr(len + 1, 2);
    if (dec === "00") {
      //當小數點後為00時，去掉小數部分
      return sizeStr.substring(0, len) + sizeStr.substr(len + 3, 2);
    }
    return sizeStr;
  },
};

export interface RpMustSetting {
  /**
   * 標題
   */
  title: string;

  /**
   * 後台管理系統標題
   */
  dashboardTitle: string;

  /**
   * 聯絡資訊
   */
  contact: {
    /**
     * 聯絡人名稱
     */
    name: string;

    /**
     * 聯絡郵件
     */
    email: string;

    /**
     * 聯絡單位
     */
    unit: string;

    /**
     * 聯絡電話
     */
    tel: string;
  };

  /**
   * 民國年
   */
  year: number;

  /**
   * 最低薪資
   */
  minSalary: number;

  /**
   * 超連結檔案
   */
  url: {
    /**
     * 校內專題研究計畫評審作業要點
     */
    reviewer: string;

    /**
     * 明新科技大學獎勵教師學術著作要點
     */
    academic: string;

    /**
     * 國科會學門專長分類表
     */
    most: string;

    /**
     * 教師學術研究倫理教育課程實施要點
     */
    class: string;

    /**
     * 教師績效認列一覽表
     */
    performanceTable: string;

    /**
     * 計畫書
     */
    project: string;

    /**
     * 個人資料
     */
    personInfo: string;

    /**
     * 計畫類型
     */
    projectType: string;

    /**
     * 結案報告格式
     */
    paperWord: string;

    /**
     *
     */
    paperPPT: string;

    /**
     * 最低工資的勞動部網址
     */
    minSalary: string;

    /**
     * 研發能量系統
     */
    frdp: string;
  };
  /**
   * 檔案最大大小
   */
  fileMaxSize: number;

  /**
   * 最後更新日期
   */
  lastUpdate: string;

  /**
   * 轉換大小
   */
  sizeConvert: (limit: number) => string;
}
