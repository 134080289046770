import thunk from "redux-thunk";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import rootReducer from "./reducers";

const composeEnhancers = composeWithDevTools({ trace: true });

export default createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
);

export type StoreTypes = ReturnType<typeof rootReducer>;
