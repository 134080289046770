import * as Yup from "yup";
import React, { useEffect } from "react";
import { Button, Grid, TextField } from "@material-ui/core";
import { useFormik } from "formik";

import { MuiSpin } from "@/components";
import { OtherBudget } from "@/types";

import classes from "./style.module.scss";

type OtherFormProps = {
  mode: "add" | "edit";
  otherBudget?: OtherBudget;
  loading: boolean;
  setData?: (v: any) => void;
  onFinish: (value: any) => void;
};

const Star = () => <span style={{ color: "red" }}>* </span>;

const OtherForm: React.FC<OtherFormProps> = ({ mode, loading, otherBudget, setData, onFinish }) => {
  const {
    values,
    errors,
    touched,
    resetForm,
    submitForm,
    setFieldValue,
    getFieldProps,
    handleSubmit,
  } = useFormik({
    initialValues: {
      name: "",
      detail: "",
      quantity: "",
      unit: "",
      price: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().trim("請勿留空").required("必填"),
      detail: Yup.string().required("必填"),
      quantity: Yup.number().min(1, "數量必須大於0").typeError("請填寫正確數量").required("必填"),
      unit: Yup.string().required("必填").max(3, "單位字數不可大於3"),
      price: Yup.number().min(1, "金額必須大於0").typeError("請填寫正確金額").required("必填"),
    }),
    onSubmit: onFinish,
  });

  useEffect(() => {
    if (setData) {
      setData({ submitForm, resetForm });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (mode === "edit" && otherBudget) {
      setFieldValue("name", otherBudget.name);
      setFieldValue("detail", otherBudget.detail);
      setFieldValue("quantity", otherBudget.quantity);
      setFieldValue("unit", otherBudget.unit);
      setFieldValue("price", otherBudget.price);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, otherBudget]);

  return (
    <MuiSpin spinning={loading}>
      <form style={{ overflow: "hidden" }} onSubmit={handleSubmit}>
        <Grid className={classes.mb} container justify="center">
          <Grid className={classes.center} item md="auto">
            <Star /> 品名：
          </Grid>
          <Grid item sm={9}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              helperText={errors.name && touched.name && errors.name}
              error={!!errors.name && !!touched.name && !!errors.name}
              {...getFieldProps("name")}
            />
          </Grid>
        </Grid>
        <Grid className={classes.mb} container justify="center">
          <Grid className={classes.center} item md="auto">
            <Star /> 用途：
          </Grid>
          <Grid item sm={9}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              helperText={errors.detail && touched.detail && errors.detail}
              error={!!errors.detail && !!touched.detail && !!errors.detail}
              {...getFieldProps("detail")}
            />
          </Grid>
        </Grid>
        <Grid className={classes.mb} container justify="center" spacing={3}>
          <Grid item md={3}>
            <div className={classes.des}>
              <Star />
              數量
            </div>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              helperText={errors.quantity && touched.quantity && errors.quantity}
              error={!!errors.quantity && !!touched.quantity && !!errors.quantity}
              {...getFieldProps("quantity")}
            />
          </Grid>
          <Grid item md={2}>
            <div className={classes.des}>
              <Star />
              單位
            </div>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              helperText={errors.unit && touched.unit && errors.unit}
              error={!!errors.unit && !!touched.unit && !!errors.unit}
              {...getFieldProps("unit")}
            />
          </Grid>
          <Grid item md={3}>
            <div className={classes.des}>
              <Star />
              單價
            </div>
            <TextField

              fullWidth
              variant="outlined"
              size="small"
              helperText={errors.price && touched.price && errors.price}
              error={!!errors.price && !!touched.price && !!errors.price}
              style={{textAlign:"center"}}
              {...getFieldProps("price")}
            />
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid item xs={12} style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
            小計：
            {isNaN(Number(values.price) * Number(values.quantity))
              ? 0
              : Number(values.price) * Number(values.quantity)}
            元
          </Grid>
          {mode === "add" && (
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center", margin: "18px 0" }}
            >
              <Button type="submit" variant="contained" color="primary">
                新增
              </Button>
            </Grid>
          )}
        </Grid>
      </form>
    </MuiSpin>
  );
};

export default OtherForm;
