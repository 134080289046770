import axios from "./axiosService";

const urls = {
  setting: "/admin/setting/",
  currentState: "/admin/setting/currentState",
  log: "/admin/log",
  db: {
    index: "/database",
  },
};

/**
 * 取得所有設定
 */
export const getAllSetting = () => axios.get(urls.setting);

/**
 * 取得log
 */
export const getLogs = () => axios.get(urls.log);

/**
 * 備份資料庫
 */
export const postBackupDb = (option: "bak" | "script") => axios.post(`${urls.db.index}/${option}`);

/**
 * 更新目前狀態
 */
export const postCurrentState = (state: number) => axios.post(`${urls.currentState}/${state}`);
