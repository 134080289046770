import React, { useContext } from "react";
import { Card, CardActions, CardContent, CardHeader, Grid } from "@material-ui/core";

import { ProjectContext } from "./ProjectManager";

/**
 * 計畫狀態
 */
const ProjectStatus: React.FC = () => {
  const { projects } = useContext(ProjectContext);

  return (
    <Grid container spacing={3}>
      <Grid item xs="auto">
        <Card style={{ width: "10rem" }}>
          <CardHeader
            title={
              <div
                style={{
                  color: "rgba(0,0,0,0.57)",
                  width: "100%",
                  textAlign: "center",
                  paddingBottom: 0,
                }}
              >
                計畫數量
              </div>
            }
          />
          <CardContent style={{ padding: "0 16px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ fontSize: "38px", textAlign: "center", color: "rgba(33,150,243)" }}>
                {projects.length}
              </div>
            </div>
          </CardContent>
          <CardActions>
            <div style={{ textAlign: "center", width: "100%", color: "rgba(76,175,80)" }}>
              已送出：
              <span style={{ fontWeight: "bolder" }}>
                {projects.filter((p) => p.submit).length}
              </span>
            </div>
          </CardActions>
        </Card>
      </Grid>
      {/* <Grid item xs="auto">
        <StatusSection>已送出：{projects.filter((p) => p.submit).length}</StatusSection>
      </Grid> */}
    </Grid>
  );
};

export default ProjectStatus;
