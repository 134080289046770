import React from "react";
import { Link as RouterLink, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { Breadcrumbs, Button, Divider, IconButton, Link } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";

import { ROUTES } from "@/constants";

import TotalBoard from "./TotalBoard";
import { Other, PartTime } from "..";

const Budget: React.FC = () => {
  const location = useLocation();
  const history = useHistory();

  return (
    <React.Fragment>
      {ROUTES.PROJECT.MANAGER_BUDGET.INDEX !== location.pathname && (
        <React.Fragment>
          <Breadcrumbs>
            <IconButton
              component={RouterLink}
              color="inherit"
              size="small"
              to={ROUTES.PROJECT.MANAGER_BUDGET.INDEX}
            >
              <ArrowBack />
            </IconButton>
            <Link
              component={RouterLink}
              color="inherit"
              to={ROUTES.PROJECT.MANAGER_BUDGET.INDEX}
            >
              預算
            </Link>
            {ROUTES.PROJECT.MANAGER_BUDGET.OTHER === location.pathname && (
              <div style={{ color: "#000" }}>研究耗材及其他費用</div>
            )}
            {ROUTES.PROJECT.MANAGER_BUDGET.PART_TIME === location.pathname && (
              <div style={{ color: "#000" }}>工讀生費用</div>
            )}
          </Breadcrumbs>
          <Divider style={{ marginTop: 10, marginBottom: 30 }} />
        </React.Fragment>
      )}

      <Switch>
        <Route
          path={ROUTES.PROJECT.MANAGER_BUDGET.INDEX}
          exact
          component={TotalBoard}
        />
        <Route path={ROUTES.PROJECT.MANAGER_BUDGET.OTHER} component={Other} />
        <Route
          path={ROUTES.PROJECT.MANAGER_BUDGET.PART_TIME}
          component={PartTime}
        />
      </Switch>
      <Route
        path={[
          ROUTES.PROJECT.MANAGER_BUDGET.OTHER,
          ROUTES.PROJECT.MANAGER_BUDGET.PART_TIME,
        ]}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push(ROUTES.PROJECT.MANAGER_BUDGET.INDEX)}
          >
            編列完成
          </Button>
        </div>
      </Route>
    </React.Fragment>
  );
};

export default Budget;
