import React, { useContext, useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";

import { deleteTeacherScore, updateTeacherScore } from "@/services/projectService";
import { useMountEffect } from "@/hooks";

import ScoreBoardItem from "./ScoreBoardItem";
import TeacherScoreDialog from "./TeacherScoreDialog/TeacherScoreDialog";
import { TeacherScoreContext, TeacherScoreType } from "./TeacherScore";
import { RpAlert } from "..";

const ScoreBoard: React.FC = () => {
  const {
    selectId,
    score,
    evidence,
    teacherScore,
    otherContent,
    setTotal,
    setLoading,
    getTeacherScoreData,
    changeTeacherPerformance,
    setEvidence,
    setSelectId,
    setOtherContent,
    setContextScore,
    validEvidence,
    reset,
  } = useContext(TeacherScoreContext);

  const [id, setId] = useState(0);
  const [open, setOpen] = useState(false);

  // 第一次渲染時抓教師績效分數資料
  useMountEffect(() => {
    getTeacherScoreData();
  });

  //分數總和
  useEffect(() => {
    let scoreTotal = teacherScore.reduce((a, b) => a + b.score, 0);
    setTotal(scoreTotal);
  }, [setTotal, teacherScore]);

  /**
   * 刪除
   * @param id
   */
  const handleDelete = (id: number) => {
    if (!window.confirm("確定刪除？")) {
      return;
    }
    setLoading(true);
    deleteTeacherScore(id).then((res) => {
      if (res.data) {
        RpAlert.show("刪除成功！");
      } else {
        RpAlert.error("刪除失敗，該筆資料不存在！");
      }
      getTeacherScoreData();
    });
  };

  /**
   * 開啟dialog
   * @param t
   */
  const handleOpen = (t: TeacherScoreType) => {
    reset();

    //id
    setId(t.id);

    //選項
    setSelectId(t.selectId);

    //教師績效
    changeTeacherPerformance(t.teacherPerformance);

    //佐證
    setEvidence({
      title: t.evidence.title,
      file: null,
      isNew: false,
    });

    //其他內容
    setOtherContent(t.otherContent);

    //分數
    setContextScore(t.score);

    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleEdit = () => {
    const { success, message } = validEvidence();

    if (!success) {
      RpAlert.error(message);
      return;
    }

    setLoading(true);
    const data = new FormData();

    data.append("selectId", selectId as any); //選擇項目
    data.append("score", score as any); //分數
    data.append("evidenceTitle", evidence.title); //佐證名稱
    data.append("file", evidence.file as any); //檔案
    otherContent && data.append("otherContent", otherContent); //補充說明 (可選)

    updateTeacherScore(id, data).then((res) => {
      if (res.data.success) {
        RpAlert.show("修改成功");
        setOpen(false);
      } else {
        RpAlert.error(`修改失敗！${res.data.message}`);
      }
      getTeacherScoreData();
      setLoading(false);
    });
  };
  return (
    <React.Fragment>
      <div style={{ marginBottom: "20px" }}>
        <Table
          style={{
            tableLayout: "fixed",
            wordBreak: "break-all",
          }}
        >
          <colgroup>
            <col style={{ width: "4%" }} />
            <col style={{ width: "9%" }} />
            <col style={{ width: "3%" }} />
            <col style={{ width: "9%" }} />
            <col style={{ width: "3%" }} />
            <col style={{ width: "4%" }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "6rem" }}>分類</TableCell>
              <TableCell style={{ width: "16rem" }}>項目</TableCell>
              <TableCell align="center">選項</TableCell>
              <TableCell align="center">佐證名稱</TableCell>
              <TableCell align="center">分數</TableCell>
              <TableCell align="center">操作</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teacherScore.length > 0 ? (
              teacherScore.map((t, i) => (
                <ScoreBoardItem key={t.id} t={t} onEdit={() => handleOpen(t)} onDelete={() => handleDelete(t.id)} />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} style={{ textAlign: "center" }}>
                  請選擇教師績效
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <TeacherScoreDialog open={open} edit onEdit={handleEdit} onClose={handleClose} />
    </React.Fragment>
  );
};

export default ScoreBoard;
