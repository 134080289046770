import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";

import { MuiSpin } from "..";

type SubmitErrorsDialogProps = {
  errors: string[];
  loading: boolean;
  onConfirm:()=>void
} & DialogProps;

const SubmitErrorsDialog: React.FC<SubmitErrorsDialogProps> = ({
  open,
  errors,
  loading,
  onClose,
  onConfirm
}) => {
  return (
    <Dialog open={open} disableBackdropClick>
      <DialogTitle>
        <Close style={{ position: "relative", top: 5, color: "red" }} />{" "}
        無法送出
      </DialogTitle>
      <DialogContent>
        <MuiSpin spinning={loading}>
          您必須先修正以下錯誤，才能送出計畫
          {errors &&
            errors.map((e, i) => (
              <Alert key={i} severity="error" style={{ margin: "5px 0" }}>
                {e}
              </Alert>
            ))}
        </MuiSpin>
      </DialogContent>
      <DialogActions style={{ display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={onConfirm}
        >
          知道了
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubmitErrorsDialog;
