import React from "react";
import { Divider, Grid, ListItem } from "@material-ui/core";

type DetailItemProps = {
  title: string;
  content: string;
};

const DetailItem: React.FC<DetailItemProps> = ({ title, content }) => (
  <React.Fragment>
    <ListItem>
      <Grid container>
        <Grid item xs={3}>
          {title}
        </Grid>
        <Grid item>{content}</Grid>
      </Grid>
    </ListItem>
    <Divider />
  </React.Fragment>
);

export default DetailItem;
