import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router";

import { ROUTES } from "@/constants";
import { Report } from "@/views";
import { AuthorizeRoute, Loading } from "@/components";

const ReportRouter: React.FC = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <AuthorizeRoute path={ROUTES.REPORT.README} component={Report.Readme} />
        <AuthorizeRoute path={ROUTES.REPORT.ADD} component={Report.Add} />
        <Redirect to={ROUTES.REPORT.README} from={ROUTES.REPORT.INDEX} exact />
      </Switch>
    </Suspense>
  );
};

export default ReportRouter;
