import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider } from "@material-ui/core";

import { deleteBudget, postBudgets } from "@/services/budgetService";
import { useRpMustUser } from "@/hooks";
import { StoreTypes } from "@/store";
import { Budget } from "@/types";
import { requestBudgetList } from "@/action/budgetAction";
import { RpAlert } from "@/components";

import EditDialog from "./EditDialog";
import PartTimeForm from "./PartTimeForm";
import PartTimeTable from "./PartTimeTable";

const PartTime: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [budget, setBudget] = useState({} as Budget);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState<any>({});
  const { userId } = useRpMustUser();
  const { isLoading, budgets, budgetTotal } = useSelector((state: StoreTypes) => state.budget);
  const dispatch = useDispatch();

  /**
   * 刪除工讀預算
   */
  const handleDelBudget = (id: number): void => {
    deleteBudget(id)
      .then((res) => {
        if (res.data.message) {
          RpAlert.error(res.data.message);
          return;
        }

        dispatch(requestBudgetList(userId));
        RpAlert.show("刪除成功！");
      })
      .catch(() => RpAlert.error("刪除失敗！"));
  };

  /**
   * 編輯工讀預算
   * @param budget
   */
  const handleEditBudget = (budget: Budget): void => {
    setOpen(true);
    setBudget(budget);
  };

  const handleFinish = (value: any) => {
    setLoading(true);
    postBudgets(value, userId)
      .then((res) => {
        setLoading(false);
        if (res.data.message) {
          RpAlert.error(res.data.message);
          return;
        }

        //獲取工讀預算資料
        dispatch(requestBudgetList(userId));
        RpAlert.show("新增成功！");
        form.resetForm();
      })
      .catch(() => RpAlert.error("新增失敗"));
  };

  const handleClose = () => setOpen(false);

  useEffect(() => {
    dispatch(requestBudgetList(userId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <React.Fragment>
      <PartTimeForm mode="add" loading={loading} setData={setForm} onFinish={handleFinish} />
      <Divider />
      <PartTimeTable
        budgets={budgets}
        loading={isLoading}
        total={budgetTotal}
        onEdit={handleEditBudget}
        onDelete={handleDelBudget}
      />
      <EditDialog budget={budget} open={open} onClose={handleClose} />
    </React.Fragment>
  );
};

export default PartTime;
