import React from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  TextField,
  TableHead,
  DialogTitle,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RpAlert } from "..";
import { postOutReviewer } from "@/services/outReviewerService";

const Star = () => <span style={{ color: "red" }}>*</span>;

const AddNewDialog: React.FC<{ loadOrs: () => void } & DialogProps> = ({
  open,
  onClose,
  loadOrs,
}) => {
  const {
    values,
    isValid,
    handleSubmit,
    getFieldProps,
    submitForm,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: Math.random().toString(36).slice(-8),
      idNumber: "",
      phone: "",
      school: "",
      address: "",
      sendAddress: "",
      bankNumber: "",
      branchNumber: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required().trim(),
    }),
    onSubmit: (value) => {
      if (!isValid) {
        RpAlert.error("新增失敗！");
        return;
      }
      postOutReviewer(value).then((res) => {
        if (res.data) {
          RpAlert.show("新增成功！");
          resetForm();
          (onClose as any)();
        } else {
          RpAlert.error("新增失敗！");
        }
        loadOrs();
      });
    },
  });
  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>
        新增外審委員
        <Button
          style={{
            position: "absolute",
            right: 10,
            top: 10,
          }}
          onClick={onClose as any}
        >
          <Close />
        </Button>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Table>
            <colgroup>
              <col style={{ width: "20%" }} />
              <col style={{ width: "80%" }} />
            </colgroup>
            <TableBody>
              <TableRow>
                <TableCell>帳號</TableCell>
                <TableCell>
                  <b> 自動生成 </b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  密碼 <Star />
                </TableCell>
                <TableCell>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    {...getFieldProps("password")}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>姓名</TableCell>
                <TableCell>
                  <TextField variant="outlined" fullWidth size="small" {...getFieldProps("name")} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    {...getFieldProps("email")}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>身份證字號</TableCell>
                <TableCell>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    {...getFieldProps("idNumber")}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>學校</TableCell>
                <TableCell>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    {...getFieldProps("school")}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>手機</TableCell>
                <TableCell>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    {...getFieldProps("phone")}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>地址</TableCell>
                <TableCell>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    {...getFieldProps("address")}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>寄件地址</TableCell>
                <TableCell>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    {...getFieldProps("sendAddress")}
                  />
                </TableCell>
              </TableRow>
              <TableRow style={{ background: "#e0fac3" }}>
                <TableCell>銀行帳號</TableCell>
                <TableCell>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    {...getFieldProps("bankNumber")}
                  />
                </TableCell>
              </TableRow>
              <TableRow style={{ background: "#e0fac3" }}>
                <TableCell>分行代號</TableCell>
                <TableCell>
                  <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    {...getFieldProps("branchNumber")}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </form>
      </DialogContent>
      <DialogActions style={{ display: "flex", justifyContent: "center" }}>
        <Button variant="contained" color="primary" onClick={() => submitForm()}>
          新增
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddNewDialog;
