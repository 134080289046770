import { MostGroup, Project, ProjectType } from "@/types";

import axios from "./axiosService";

const baseUrl = "/project";
const URLS = {
  PROJECT: {
    INDEX: baseUrl,
    README: `${baseUrl}/readme`,
    TYPES: `${baseUrl}/type`,
    MOST_GROUP: `${baseUrl}/most-group`,
    MOST: `${baseUrl}/most`,
    FILE: `${baseUrl}/file`,
    FILES: `${baseUrl}/files`,
    EVIDENCE_FILE: `${baseUrl}/evidence-file`,
    IUC: `${baseUrl}/iuc`,
    SUBMIT: `${baseUrl}/submit`,
    TEACHER_PERFORMANCE: `${baseUrl}/teacherPerformance/`,
    TEACHER_SCORE: `${baseUrl}/teacherScore`,
    REJECT: `${baseUrl}/reject`,
  },
};

/**
 * 取得所有計畫類型
 */
export const getProjectTypes = () => axios.get<ProjectType[]>(URLS.PROJECT.TYPES);

/**
 * 取得學門分類
 */
export const getMostGroup = () => axios.get<MostGroup[]>(URLS.PROJECT.MOST_GROUP);

/**
 * 是否有計畫
 * @param userId
 */
export const getCheckHasProject = (userId: string) =>
  axios.get<boolean>(`${URLS.PROJECT.INDEX}/check/has/${userId}`);

/**
 * 新增計畫
 * @param project 計畫
 */
export const addProject = (project: any) => axios.post(URLS.PROJECT.INDEX, project);

/**
 * 修改計畫
 * @param project
 */
export const updateProject = (project: any, userId: string) =>
  axios.put(URLS.PROJECT.INDEX + "/" + userId, project);

/**
 * 取得計畫
 * @param userId 使用者代號
 */
export const getProject = (userId: string) => axios.get(URLS.PROJECT.INDEX + "/" + userId);

/**
 * 上傳計畫檔案
 * @param files
 * @param userId
 */
export const postProjectFile = (files: FormData, userId: string) =>
  axios.post(URLS.PROJECT.FILES + "/" + userId, files);

/**
 * 上傳計畫檔案
 * @param files
 * @param userId
 */
export const postProjectEvidenceFile = (file: FormData, userId: string) =>
  axios.post(URLS.PROJECT.EVIDENCE_FILE + "/" + userId, file);

/**
 * 取得計畫類型
 * @param userId
 */
export const getProjectType = (userId: string) =>
  axios.get<number>(URLS.PROJECT.TYPES + "/" + userId);

/**
 * 新增計畫類型
 */
export const postProjectType = (name: string) =>
  axios.post<boolean>(`${URLS.PROJECT.TYPES}/${name}`);

/**
 * 修改計畫類型
 * @param id
 */
export const putProjectType = (value: ProjectType) => axios.put<boolean>(URLS.PROJECT.TYPES, value);

/**
 * 刪除計畫類型
 * @param id
 */
export const deleteProjectType = (id: number) => axios.delete(`${URLS.PROJECT.TYPES}/${id}`);

/**
//  * 取得研究性質/衍生成果
 * @param userId
 */
export const getProjectProperty = (userId: string) =>
  axios.get<string>(URLS.PROJECT.INDEX + "/property/" + userId);

/**
 * 取得學門分類
 * @param userId
 */
export const getProjectMost = (userId: string) =>
  axios.get<Number[]>(URLS.PROJECT.INDEX + "/most/" + userId);

/**
 * 取得國科會計畫
 */
export const getMostProject = (userId: string) =>
  axios.get<string>(URLS.PROJECT.INDEX + "/most-project/" + userId);

/**
 * 是否為新進教師
 * @param userId
 */
export const getProjectIsNewTeacher = (userId: string) =>
  axios.get<boolean>(URLS.PROJECT.INDEX + "/isNewTeacher/" + userId);

/**
 * 取得檔案
 * @param fileName
 */
export const getProjectFile = (fileName: string) =>
  axios.get(URLS.PROJECT.FILE + "/" + fileName, { responseType: "blob" }); //<-responseType很重要，一定要加blob！才能接到pdf檔之類的文檔流

/**
 * 是否有檔案
 * @param userId
 */
export const getHasFile = (userId: string) => axios.get(`${URLS.PROJECT.FILES}/has/${userId}`);

/**
 * 是否有佐證檔案
 * @param userId
 */
export const getHasEvidenceFile = (userId: string) =>
  axios.get(`${URLS.PROJECT.FILES}/has/${userId}?evidence=true`);

/**
 * 取得檔案連結
 * @param userId
 */
export const getProjectFilesLink = (userId: string) => axios.get(URLS.PROJECT.FILES + "/" + userId);

/**
 * 產學
 * @param value
 * @param userId
 */
export const postProjectIuc = (value: any, userId: string) =>
  axios.post(URLS.PROJECT.IUC + "/" + userId, value);

/**
 * 送出計畫
 * @param userId
 */
export const postSubmitProject = (userId: string) => axios.post(URLS.PROJECT.SUBMIT + "/" + userId);

/**
 * 檢查計畫錯誤
 * @param userId
 */
export const getCheckErrors = (userId: string) =>
  axios.get<string[]>(`${URLS.PROJECT.SUBMIT}/check/${userId}`);

/**
 * 退回計畫
 * @param userId
 */
export const postRejectProject = (userId: string) => axios.post(`${URLS.PROJECT.REJECT}/${userId}`);

/**
 * 取得所有計畫
 */
export const getAllProject = () => axios.get<Project[]>(URLS.PROJECT.INDEX);

/**
 * 取得所有教師績效
 */
export const getAllTeacherPerformance = () => axios.get(URLS.PROJECT.TEACHER_PERFORMANCE);

/**
 * 取得教師績效分數
 * @param userId
 */
export const getTeacherScore = (userId: string) =>
  axios.get(URLS.PROJECT.TEACHER_SCORE + "/" + userId);

/**
 * 新增教師績效分數
 * @param userId
 * @param value
 */
export const addTeacherScore = (userId: string, value: any) =>
  axios.post(URLS.PROJECT.TEACHER_SCORE + "/" + userId, value);

/**
 * 刪除教師績效分數
 * @param id
 */
export const deleteTeacherScore = (id: number) =>
  axios.delete(URLS.PROJECT.TEACHER_SCORE + "/" + id);

/**
 * 修改教師績效分數
 */
export const updateTeacherScore = (id: number, value: any) =>
  axios.put(URLS.PROJECT.TEACHER_SCORE + "/" + id, value);

/**
 * 取得教師績效總分
 * @param userId
 */
export const getTeacherScoreTotal = (userId: string) =>
  axios.get<number>(URLS.PROJECT.TEACHER_SCORE + "/total/" + userId);

/**
 * 上傳申請計畫的README
 * @param html
 */
export const postProjectReadme = (html: FormData) => axios.post<string>(URLS.PROJECT.README, html);
