import React, { memo, useCallback, useEffect, useState } from "react";
import { Button, ListItem, TextField } from "@material-ui/core/";
import { Autocomplete } from "@material-ui/lab";
import { useSelector } from "react-redux";

import { ROUTES } from "@/constants";
import { getInReviewerMsgsFromUserId, postInReviewerMsg } from "@/services/inReviewerService";
import { StoreTypes } from "@/store";
import { InReviewer, InReviewerMsg } from "@/types";
import { ProjectContext } from "@/views/Admin/ProjectManager/ProjectManager";

import { RpAlert } from "..";

type InReviewerListItemProps = {
  order: number;
  onLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const InReviewerListItem: React.FC<InReviewerListItemProps> = ({
  order,
  onLoading: setIsLoading,
}) => {
  const { currentProject } = React.useContext(ProjectContext);
  const { inReviewers } = useSelector((state: StoreTypes) => state.reviewer);
  const [inReviewerMsg, setInReviewerMsg] = useState<InReviewerMsg>({} as InReviewerMsg);
  const [inReviewerId, setInReviewerId] = useState<number>(0);
  const [inReviewer, setInReviewer] = useState<InReviewer>();

  const getMsgData = useCallback(async () => {
    setIsLoading(true);

    await getInReviewerMsgsFromUserId(currentProject.userId).then((res) => {
      setIsLoading(false);

      //防止空的資料
      if (!res.data[order - 1]) {
        return;
      }

      //設定審查委員Id
      setInReviewerId(res.data[order - 1].reviewerId);

      //設定內審資訊
      setInReviewerMsg(res.data[order - 1]);

      setInReviewer(inReviewers.find((i) => i.inReviewerId === res.data[order - 1].reviewerId)!);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, currentProject.userId, setIsLoading]);

  const handleEditClick = () => {
    setIsLoading(true);
    //分配審查委員
    postInReviewerMsg({
      userId: currentProject.userId,
      inReviewerId: inReviewerId,
      order,
    })
      .then((res) => {
        if (res.data) {
          RpAlert.show("修改成功");
        } else {
          RpAlert.error("修改失敗");
        }
        getMsgData();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getMsgData();
  }, [order, currentProject.userId, getMsgData]);

  return (
    <ListItem>
      <Autocomplete
        id={`in-reviewer-${order}`}
        options={inReviewers}
        value={inReviewer}
        getOptionLabel={(option) => option.name}
        style={{ width: 110 }}
        renderInput={(params) => <TextField {...params} label={`內審${order}`} />}
        onChange={(e, value) => {
          console.log(e);
          setInReviewerId(value?.inReviewerId as number);
        }}
      />
      {/* <FormControl style={{ minWidth: "120px" }}>
        <InputLabel id={`in-reviewer-${order}`}>內審{order}</InputLabel>
        <Select
          labelId={`in-reviewer-${order}`}
          id={`in-reviewer-${order}-select`}
          value={inReviewerId}
          onChange={(e) => setInReviewerId(e.target.value as number)}
          input={<Input />}
        >
          <MenuItem value={0}>未分配</MenuItem>
          {inReviewers.map((inReviewer) => (
            <MenuItem
              key={inReviewer.inReviewerId}
              value={inReviewer.inReviewerId}
            >
              {inReviewer.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}

      <Button
        variant="contained"
        color="primary"
        style={{ marginLeft: "10px", marginRight: "10px" }}
        onClick={handleEditClick}
      >
        修改
      </Button>

      {inReviewerMsg.lastDate ? (
        <Button
          color="primary"
          variant="outlined"
          href={`${ROUTES.REVIEWER.IN_REVIEWER.INDEX}/${inReviewerId}/${currentProject.userId}/${order}`}
          target="_blank"
        >
          已審查
        </Button>
      ) : (
        <Button color="default" variant="outlined">
          未審查
        </Button>
      )}

      {
        <Button color="secondary" variant="outlined" style={{ marginLeft: "10px" }}>
          退回
        </Button>
      }
    </ListItem>
  );
};

export default memo(InReviewerListItem);
