import React, { memo, useContext, useEffect, useState } from "react";
import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";

import { RpMust } from "@/constants";

import EvidenceInput from "./EvidenceInput";
import OtherContentInput from "./OtherContentInput";
import { TeacherScoreContext } from "../TeacherScore";

/**
 * 渲染格式化後的項目說明
 * @param description 項目說明
 */
export const renderDescription = (description: string) => {
  let arr: string[] = description.split("。");
  let front: string = arr[0];
  let scoreArr = arr[1] && arr[1].split("；");

  return (
    <React.Fragment>
      {front} <br />
      {scoreArr &&
        scoreArr.map((s, i) => (
          <React.Fragment key={i}>
            {s} <br />
          </React.Fragment>
        ))}
    </React.Fragment>
  );
};

const TeacherScoreInput: React.FC = () => {
  const {
    teacherPerformance,
    selectId,
    evidence,
    otherContent,
    score: contextScore,
    setSelectId,
    setContextScore,
    setOtherContent,
  } = useContext(TeacherScoreContext);
  const [radio, setRadio] = useState(() => {
    if (selectId !== 0) {
      return selectId;
    } else if (teacherPerformance.options.length <= 1) {
      return teacherPerformance.options[0].id;
    } else {
      return 0;
    }
  });
  const [showOtherContent, setShowOtherContent] = useState(
    otherContent ? true : false
  );

  /**
   * 計算金額分數
   */
  const calcMoneyScore = (): number => {
    const max = teacherPerformance.maxScore;
    let money = Number(evidence.title);

    if (isNaN(money)) {
      return 0;
    }

    //需要向下取整，因為若不取整可能會出現小數點，以及分數會錯誤。
    //例如: 每一萬元2分，但輸入19999元會出現3分(正確為2分，20000才是4分)
    money = Math.floor(money / 10000) * teacherPerformance.options[0].score;
    let result = money > max ? max : money;
    return result;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = parseInt(e.target.value);
    let score = teacherPerformance.options.find((o) => o.id === value)?.score;

    setRadio(value);
    setSelectId(value); //選項id
    setContextScore(Number(score)); //Context的分數
  };

  // 如果選項只有一個的話就自動選取
  useEffect(() => {
    if (teacherPerformance.options.length > 1) {
      return;
    }

    let value = radio;
    let option = teacherPerformance.options.find((o) => o.id === value);
    setContextScore(Number(option?.score));
    setSelectId(Number(option?.id));
  }, [
    radio,
    teacherPerformance.options,
    teacherPerformance.method,
    setSelectId,
    setContextScore,
  ]);

  useEffect(() => {
    const method = teacherPerformance.method;
    let score = -1;
    if (method === 3) {
      //金額
      score = calcMoneyScore();
    }

    if (score !== -1) {
      setContextScore(score);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evidence, teacherPerformance.method]);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>分類</TableCell>
          <TableCell>分類名稱</TableCell>
          <TableCell style={{ width: "10rem" }}>選項</TableCell>
          <TableCell style={{ width: "16rem" }}>項目</TableCell>
          <TableCell style={{ width: "30rem" }}>
            {teacherPerformance.method === 3 ? (
              <div>金額</div>
            ) : (
              <div>佐證名稱（如：{teacherPerformance.fieldText}）</div>
            )}
          </TableCell>
          <TableCell align="center">分數</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>{teacherPerformance.class}</TableCell>
          <TableCell>{teacherPerformance.className}</TableCell>
          <TableCell>
            <RadioGroup name="radios" value={radio} onChange={handleChange}>
              {teacherPerformance.options.map((option) => (
                <FormControlLabel
                  key={option.id}
                  label={option.name}
                  value={option.id}
                  control={<Radio size="small" color="primary" />}
                />
              ))}
            </RadioGroup>
          </TableCell>
          <TableCell>
            {renderDescription(teacherPerformance.description)}
          </TableCell>
          <TableCell>
            <EvidenceInput />
            {showOtherContent ? (
              <OtherContentInput
                onDelete={() => {
                  setShowOtherContent(false);
                  setOtherContent("");
                }}
              />
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<Add />}
                  onClick={() => setShowOtherContent(true)}
                >
                  新增補充說明
                </Button>
              </div>
            )}
            <div style={{ color: "red", marginTop: "10px" }}>
              <ul>
                <li>佐證資料請合併成一個pdf檔上傳</li>
                <li>
                  檔案大小請勿超過 {RpMust.sizeConvert(RpMust.fileMaxSize)}
                </li>
              </ul>
            </div>
          </TableCell>
          <TableCell style={{ textAlign: "center" }}>{contextScore}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default memo(TeacherScoreInput);
