import React, { memo, useEffect, useRef, useState } from "react";
import { Route, RouteProps, useLocation } from "react-router";
import { useSelector } from "react-redux";

import { StoreTypes } from "@/store";
import { checkAuth } from "@/services/loginService";
import { useIfMounted, useLogout } from "@/hooks";

import { Loading } from "..";

const PrivateRoute: React.FC<RouteProps> = memo((props) => {
  const [loading, setLoading] = useState(true);
  const { isAuthenticated } = useSelector((state: StoreTypes) => state.auth);
  const ifMounted = useIfMounted();
  const logout = useLogout();
  const location = useLocation();
  const ref = useRef(true);

  const loginUrl = `/login?url=${location.pathname}`;

  useEffect(() => {
    //檢查token是否過期
    const fetchAuth = async () => {
      await checkAuth()
        .then((res) => {
          if (res.data) {
            return;
          }

          //ref是防止重複跳出"請重新登入"的訊息
          if (ref.current) {
            logout(loginUrl);
          }

          ref.current = false;
        })
        .catch(() => logout(loginUrl))
        .finally(() => ifMounted(() => setLoading(false)));
    };

    fetchAuth();
  });

  const { children, ...restProps } = props;

  return (
    <React.Fragment>
      {loading && !isAuthenticated ? (
        <Loading />
      ) : (
        <Route {...restProps}>{children}</Route>
      )}
    </React.Fragment>
  );
});

export default PrivateRoute;
