const ROUTES = {
  LOGIN: "/login",
  FILE: "/file",
  PROJECT: {
    INDEX: "/project",
    ADD: "/project/add",
    VIEWER: "/project/viewer",
    README: "/project/readme",
    MANAGER: "/project/manager",
    MANAGER_BUDGET: {
      INDEX: "/project/manager/budget",
      OTHER: "/project/manager/budget/other",
      PART_TIME: "/project/manager/budget/part-time",
    },
    PRINT: "/project/print",
  },
  REPORT: {
    INDEX: "/report",
    README: "/report/readme",
    ADD: "/report/add",
  },
  ADMIN: {
    INDEX: "/admin",
    PROJECT: {
      INDEX: "/admin/project",
      EDIT: "/admin/project/edit",
    },
    REPORT: {
      INDEX: "/admin/report",
    },
    PROJECT_TYPE: "/admin/project-type",
    SETTING: "/admin/setting",
    REVIEWER: {
      IN: "/admin/reviewer/in",
      OUT: "/admin/reviewer/out",
    },
    USER: {
      INDEX: "/admin/user",
      IMPORT: "/admin/user/import",
      COLLEGE: "/admin/user/college",
    },
    TEACHER_SCORE: "/admin/teacher-score",
    LOG: "/admin/log",
  },
  REVIEWER: {
    INDEX: "/reviewer",
    IN_REVIEWER: {
      INDEX: "/reviewer/in",
      START_REVIEW: "/reviewer/in/start-reviewer",
    },
    OUT_REVIEWER: {
      INDEX: "/out-reviewer",
      LIST: "/out-reviewer/list",
      EDIT: "/out-reviewer/edit",
    },
  },
};

export { ROUTES };
