import { StoreTypes } from "@/store";
import moment from "moment";
import { useSelector } from "react-redux";

/**
 * 取得設定
 * @returns
 */
export const useSettings = () => {
  const {
    submitStartDate,
    submitDeadline,
    reviewerStartDate,
    reviewerDeadline,
    lastYearStartDate,
    lastYearDeadline,
  } = useSelector((state: StoreTypes) => state.setting);

  const canSubmitProject = moment().isBetween(submitStartDate, submitDeadline);
  const canReviewProject = moment().isBetween(reviewerStartDate, reviewerDeadline);
  const canSubmitLastYearProject = moment().isBetween(lastYearStartDate, lastYearDeadline);

  return {
    canSubmitProject,
    canReviewProject,
    canSubmitLastYearProject,
  };
};
