import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from "@material-ui/core";

type ZeroTipDialogProps = {
  /**
   * 只要關閉就好，不跳回編列頁面
   */
  justClose?: boolean;
  goto?: () => void;
  onConfirm?: () => void;
} & DialogProps;

const ZeroTipDialog: React.FC<ZeroTipDialogProps> = ({
  open,
  justClose = false,
  onClose,
  onConfirm = () => {},
  goto = () => {},
  ...rest
}) => {
  const handleClick = () => {
    (onClose as any)();
    goto();
  };

  return (
    <Dialog open={open} {...rest}>
      <DialogTitle>提示</DialogTitle>
      <DialogContent>您的教師績效分數是0分，確定要繼續嗎？</DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={justClose ? (onClose as any) : handleClick}
        >
          返回編列
        </Button>
        <Button color="primary" onClick={onConfirm}>
          繼續
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ZeroTipDialog;
