import React from "react";
import { CircularProgress, Typography } from "@material-ui/core";

const loadingStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  textAlign: "center",
};

const Loading: React.FC<{ height?: string | number | undefined }> = ({
  height = loadingStyle.height,
}) => {
  return (
    <div style={{ ...loadingStyle, height }}>
      <div>
        <CircularProgress color="primary" /> <br />
        <Typography color="primary">讀取中...</Typography>
      </div>
    </div>
  );
};

export default Loading;
