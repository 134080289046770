import React, { useEffect, useRef, useState } from "react";
// import MDEditor from "@uiw/react-md-editor";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemSecondaryAction,
  makeStyles,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";

import Database from "./Database";
import Schedule from "./Schedule";
import StaticFileUpload from "./StaticFileUpload";
import { useDispatch, useSelector } from "react-redux";
import { StoreTypes } from "@/store";
import moment from "moment";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import MaterialTable, { Column } from "material-table";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { postProjectReadme } from "@/services/projectService";
import { RpAlert } from "@/components";
import { postFileToWWWRoot } from "@/services/fileService";
import MDEditor2 from "@uiw/react-md-editor";
import MdEditor from "react-markdown-editor-lite";
import ReactMarkdown from "react-markdown";
import "react-markdown-editor-lite/lib/index.css";
import MarkdownIt from "markdown-it";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { postCurrentState } from "@/services/settingService";
import { requestSetting } from "@/action/settingAction";

type SwitchButtonProps = {
  startDate?: Date;
  deadline?: Date;
  title: string;
  active?: boolean;
  onClick: (num: number) => void;
  show: boolean;
  index: number;
};

const SwitchButton: React.FC<SwitchButtonProps> = ({
  startDate,
  deadline,
  title,
  active = false,
  onClick,
  show,
  index,
}) => {
  const style: CSSProperties = {
    color: active ? "#fff" : "",
    fontWeight: active ? "bold" : "unset",
  };

  return (
    <Button
      variant={active ? "contained" : "outlined"}
      color={active ? "primary" : "default"}
      fullWidth
      style={{ height: "100%" }}
      onClick={() => onClick(index)}
    >
      <div>
        <Typography variant="h6" style={style}>
          {title}
        </Typography>
        {show && startDate && deadline && (
          <Typography variant="subtitle2" color="textPrimary" style={style}>
            {moment(startDate).toDate().toLocaleDateString()} -{" "}
            {moment(deadline).toDate().toLocaleDateString()}
          </Typography>
        )}
      </div>
    </Button>
  );
};

const Editor: React.FC = () => {
  const settings = useSelector((state: StoreTypes) => state.setting);
  const [markdownText, setMarkdownText] = useState(settings.projectReadme);
  const mdParser = new MarkdownIt({ html: true });

  useEffect(() => {}, [markdownText]);

  function handleEditorChange({ html, text }: any) {
    console.clear();
    console.log("handleEditorChange", text);
    setMarkdownText(text);
  }

  // return (
  //   <MdEditor
  //     style={{ height: "500px" }}
  //     renderHTML={(text) => mdParser.render(text)}
  //     onChange={handleEditorChange}
  //     value={markdownText}
  //   />
  // );

  return (
    <>
      <ReactQuill
        theme="snow"
        value={markdownText}
        preserveWhitespace={true}
        modules={{
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "link"],

            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent

            [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],

            ["clean"], // remove formatting button
          ],
        }}
        onChange={(val) => {
          console.log(val);
          setMarkdownText(val);
        }}
        onBlur={(val) => {
          console.log("blur", val);
          const data = new FormData();
          data.append("html", markdownText); //選擇項目
          postProjectReadme(data)
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              RpAlert.error(err.message);
            });
        }}
      />
      <Divider style={{ margin: "10px 0" }} />
      <h1>預覽</h1>

      <div
        style={{ whiteSpace: "pre-wrap" }}
        dangerouslySetInnerHTML={{ __html: markdownText }}
      ></div>
      {/* <Box
        style={{ whiteSpace: "pre-wrap" }}
        dangerouslySetInnerHTML={{ __html: markdownText }}
      ></Box> */}
    </>
  );
};

const Setting: React.FC = () => {
  const {
    submitStartDate,
    submitDeadline,
    reviewerStartDate,
    reviewerDeadline,
    lastYearStartDate,
    lastYearDeadline,
  } = useSelector((state: StoreTypes) => state.setting);

  const dispatch = useDispatch();
  const [active, setActive] = useState<number>(4);
  const [isAuto, setIsAuto] = useState<"auto" | "manual">("manual");
  const settings = useSelector((state: StoreTypes) => state.setting);
  const [pages, setPages] = useState(1);
  const [tempData, setTempData] = useState<any[]>([
    {
      name: "年度",
      value: "111",
      enable: true,
      describe: "年度",
    },
    {
      name: "最低薪資",
      value: "168",
      enable: true,
      describe: "每年度的最低薪資設定",
    },
  ]);

  const handleClear = () => {
    var result = prompt("請輸入 確認 來完成這項操作！");
    if (result === "確認") {
      alert("清除成功！");
    }
  };

  const handleChangeCurrentState = async (state: number) => {
    const res = await postCurrentState(state);
    await dispatch(requestSetting());
    console.log(settings);
  };

  const tableRef = useRef<any>(null);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={
              <>
                <Grid container spacing={3} style={{ padding: 0 }}>
                  <Grid
                    item
                    style={{
                      padding: 0,
                      paddingLeft: 22,
                      alignItems: "center",
                      display: "inline-flex",
                      marginRight: 40,
                    }}
                  >
                    <Typography variant="h6">時程管理（系統開放後才可進行時程管理）</Typography>
                  </Grid>

                  <Grid item xs="auto" style={{ padding: 0 }}>
                    <RadioGroup row name="manager" defaultValue="manual">
                      <FormControlLabel
                        value="auto"
                        control={<Radio color="primary" />}
                        label="自動控制"
                        onChange={(e) => setIsAuto("auto")}
                        disabled
                      />
                      <FormControlLabel
                        value="manual"
                        control={<Radio color="primary" />}
                        label="手動控制"
                        onChange={(e) => setIsAuto("manual")}
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </>
            }
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs>
                <SwitchButton
                  index={1}
                  startDate={submitStartDate}
                  deadline={submitDeadline}
                  title="開始計畫申請"
                  active={settings.currentState === 1}
                  show={isAuto === "auto"}
                  onClick={(index) => isAuto === "manual" && handleChangeCurrentState(index)}
                />
              </Grid>

              <Grid item xs>
                <SwitchButton
                  index={2}
                  startDate={reviewerStartDate}
                  deadline={reviewerDeadline}
                  title="開始內審"
                  active={settings.currentState === 2}
                  show={isAuto === "auto"}
                  onClick={(index) => isAuto === "manual" && handleChangeCurrentState(index)}
                />
              </Grid>

              <Grid item xs>
                <SwitchButton
                  index={3}
                  startDate={reviewerStartDate}
                  deadline={reviewerDeadline}
                  title="開始外審"
                  active={settings.currentState === 3}
                  show={isAuto === "auto"}
                  onClick={(index) => isAuto === "manual" && handleChangeCurrentState(index)}
                />
              </Grid>

              <Grid item xs>
                <SwitchButton
                  index={4}
                  startDate={lastYearStartDate}
                  deadline={lastYearDeadline}
                  title="開始結案報告上傳"
                  active={settings.currentState === 4}
                  show={isAuto === "auto"}
                  onClick={(index) => isAuto === "manual" && handleChangeCurrentState(index)}
                />
              </Grid>

              <Grid item xs={2}>
                <SwitchButton
                  index={-1}
                  title="關閉系統"
                  active={settings.currentState === -1}
                  show={isAuto === "auto"}
                  onClick={(index) => isAuto === "manual" && handleChangeCurrentState(index)}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <MaterialTable
          ref={tableRef}
          title={
            <div>
              <h2 style={{ marginRight: "10" }}>檔案列表</h2>
              <input
                type="file"
                onChange={(s) => {
                  console.log(s.target.files);
                  if (window.confirm("確定要上傳檔案嗎？")) {
                    const data = new FormData();
                    data.append("file", s.target.files![0]);
                    postFileToWWWRoot(data)
                      .then((res) => {
                        alert("上傳成功！");
                      })
                      .catch((err) => {
                        alert("上傳失敗！");
                      });
                  }
                  s.target.value = null!;
                }}
              />
            </div>
          }
          options={{
            actionsColumnIndex: -1,
            pageSize: 10,
          }}
          localization={{
            header: {
              actions: "操作",
            },
            body: {
              editRow: {
                deleteText: "確定要刪除嗎？",
              },
            },
          }}
          data={[
            {
              link: "/個人資料.docx",
              name: "個人資料.docx",
              type: "docx",
            },
            {
              link: "/計畫書.docx",
              name: "計畫書.docx",
              type: "docx",
            },
            {
              link: "/上網授權書.docx",
              name: "上網授權書.docx",
              type: "docx",
            },
            {
              link: "/校內專題研究計畫評審作業要點.pdf",
              name: "校內專題研究計畫評審作業要點.pdf",
              type: "pdf",
            },
            {
              link: "/成果海報格式.pptx",
              name: "成果海報格式.pptx",
              type: "pptx",
            },
            {
              link: "/成果報告撰寫格式須知.docx",
              name: "成果報告撰寫格式須知.docx",
              type: "docx",
            },
            {
              link: "/明新科技大學獎勵教師學術著作要點.pdf",
              name: "明新科技大學獎勵教師學術著作要點.pdf",
              type: "pdf",
            },
            {
              link: "/教師學術研究倫理教育課程實施要點.pdf",
              name: "教師學術研究倫理教育課程實施要點.pdf",
              type: "pdf",
            },
            {
              link: "/教師績效認列一覽表.pdf",
              name: "教師績效認列一覽表.pdf",
              type: "pdf",
            },
            {
              link: "/結案報告格式.docx",
              name: "結案報告格式.docx",
              type: "docx",
            },
          ]}
          columns={[
            {
              title: "檢視",
              field: "link",
              render: (rowData) => {
                return (
                  <Link href={rowData.link} target="_blank">
                    檢視檔案
                  </Link>
                );
              },
            },
            { title: "檔案名稱", field: "name" },
            { title: "檔案類型", field: "type", editable: "never" },
          ]}
        />
      </Grid>

      {/* 資料庫設定 */}
      {/* <Grid item xs={12}>
        <MaterialTable
          title="資料庫設定"
          options={{
            actionsColumnIndex: -1,
          }}
          localization={{
            header: {
              actions: "操作",
            },
            body: {
              editRow: {
                deleteText: "確定要刪除嗎？",
              },
            },
          }}
          columns={[
            { title: "Name", field: "name" },
            { title: "Value", field: "value" },
            { title: "是否啟用", field: "enable" },
            { title: "功能描述", field: "describe" },
          ]}
          data={tempData}
        ></MaterialTable>
      </Grid> */}

      <Grid item xs={12}>
        <Card>
          <CardHeader title="內容編輯器" />
          <CardContent>
            {/* <Select id="content-select" value={pages} onChange={() => {}}>
              <MenuItem value={1}>申請計畫前的詳細說明</MenuItem>
              <MenuItem value={2}>上傳結案報告的詳細說明</MenuItem>
            </Select> */}
            <h2>實時編輯，滑鼠點擊編輯器以外的區域後即更新</h2>
            <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
            <Editor />
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardHeader
            title={`系統重置（當前年度需大於${new Date().getFullYear() - 1911}年度才可以重置）`}
          />
          <CardContent>
            <ul>
              <li style={{ color: "red" }}>為不可逆操作</li>
              <li>清除時需要輸入 '確認' 兩個字才可以清除</li>
            </ul>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Button disabled variant="contained" color="secondary" onClick={handleClear}>
                  一鍵清除（下方所有功能的快速版）
                </Button>
              </Grid>

              <Grid item xs={3}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Button
                      disabled
                      variant="contained"
                      color="primary"
                      onClick={handleClear}
                      fullWidth
                    >
                      清除所有計畫
                    </Button>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      disabled
                      variant="contained"
                      color="primary"
                      onClick={handleClear}
                      fullWidth
                    >
                      清除所有計畫檔案
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={3}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Button
                      disabled
                      variant="contained"
                      color="primary"
                      onClick={handleClear}
                      fullWidth
                    >
                      清除所有結案報告
                    </Button>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      disabled
                      variant="contained"
                      color="primary"
                      onClick={handleClear}
                      fullWidth
                    >
                      清除所有結案報告檔案
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={3}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Button
                      disabled
                      variant="contained"
                      color="primary"
                      onClick={handleClear}
                      fullWidth
                    >
                      清除所有內審審查結果
                    </Button>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      disabled
                      variant="contained"
                      color="primary"
                      onClick={handleClear}
                      fullWidth
                    >
                      清除所有外審審查結果
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );

  return (
    <div>
      {/* <Grid container spacing={3}>
        <Grid item xs={6}>
          <Grid container spacing={3} direction="column">
            <Grid item>
              <Card>
                <CardHeader title="設定" />
                <CardContent>
                  <List>
                    <ListItem>
                      登入是否需要密碼
                      <ListItemSecondaryAction>
                        <Switch
                          color="primary"
                          onChange={() => setNeedPw(!needPw)}
                          checked={needPw}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                      最低薪資
                      <ListItemSecondaryAction>
                        <TextField size="small" variant="outlined" value={158} />
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>
            <Grid item>
              <Schedule />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={3} direction="column">
            <Grid item>
              <StaticFileUpload />
            </Grid>
            <Grid item>
              <Database />
            </Grid>
          </Grid>
        </Grid>
      </Grid> */}
    </div>
  );
};

export default Setting;
