import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import Check from "@material-ui/icons/Check";
import LocalAtm from "@material-ui/icons/LocalAtm";
import MenuBook from "@material-ui/icons/MenuBook";
import Publish from "@material-ui/icons/Publish";
import Storage from "@material-ui/icons/Storage";
import React, { useState } from "react";
import { useParams } from "react-router";

import { Budget, ProjectDetail, SubmitProject, TeacherScore, UploadFiles } from "@/components";
import { useProject } from "@/hooks";

type ManagerTab = {
  icon?: JSX.Element;
  text: React.ReactNode;
  key: string;
  children: React.ReactNode;
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      style={{ display: value !== index ? "none" : "block" }}
      id={`scrollable-auto-tabpanel-${index}`}
      {...other}
    >
      <Box p={3}>
        <Typography component="span">{children}</Typography>
      </Box>
    </div>
  );
}

const ProjectEdit: React.FC = () => {
  const [value, setValue] = useState(0);
  const { userId } = useParams<{ userId: string }>();
  const projectResult = useProject(userId);

  const tabs: ManagerTab[] = [
    {
      icon: <MenuBook />,
      text: "管理計畫",
      key: "edit",
      children: <ProjectDetail />,
    },
    {
      icon: <Publish />,
      text: "計畫上傳",
      key: "upload",
      children: <UploadFiles />,
    },
    {
      icon: <Storage />,
      text: "教師績效",
      key: "score",
      children: <TeacherScore userId={userId} />,
    },
    // {
    //   icon: <LocalAtm />,
    //   text: "預算",
    //   key: "budget",
    //   children: <Budget />,
    // },
    {
      icon: <Check />,
      text: "送出計畫",
      key: "submit",
      children: <SubmitProject />,
    },
  ];

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Paper component="div">
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="auto"
        >
          {tabs.map((tab) => (
            <Tab label={tab.text} key={tab.key} icon={tab.icon} />
          ))}
        </Tabs>
      </AppBar>
      {tabs.map(({ key, children }, i) => (
        <TabPanel key={key} value={value} index={i}>
          {children}
        </TabPanel>
      ))}
    </Paper>
  );
};

export default ProjectEdit;
