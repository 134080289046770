import { BUDGET_ACTION, BudgetActionTypes } from "@/action/budgetAction";
import { Budget, OtherBudget } from "@/types";

type BudgetState = {
  isLoading: boolean;
  otherBudgets: OtherBudget[];
  otherBudgetTotal: number;
  budgets: Budget[];
  budgetTotal: number;
};

const init: BudgetState = {
  isLoading: true,
  otherBudgets: [],
  budgets: [],
  otherBudgetTotal: 0,
  budgetTotal: 0,
};

export default (state = init, action: BudgetActionTypes): BudgetState => {
  switch (action.type) {
    case BUDGET_ACTION.REQUEST_OTHER_BUDGET_LIST:
      return {
        ...state,
        isLoading: true,
      };
    case BUDGET_ACTION.SET_OTHER_BUDGET_LIST:
      return {
        ...state,
        isLoading: false,
        otherBudgets: action.payload.otherBudget,
      };
    case BUDGET_ACTION.REQUEST_BUDGET_LIST:
      return {
        ...state,
        isLoading: true,
      };
    case BUDGET_ACTION.SET_BUDGET_LIST:
      return {
        ...state,
        isLoading: false,
        budgets: action.payload.budgets,
      };
    case BUDGET_ACTION.SET_OTHER_BUDGET_TOTAL:
      return {
        ...state,
        otherBudgetTotal: action.payload.otherBudgetTotal,
      };
    case BUDGET_ACTION.SET_BUDGET_TOTAL:
      return {
        ...state,
        budgetTotal: action.payload.budgetTotal,
      };
    default:
      return state;
  }
};
