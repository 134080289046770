import { useCallback, useEffect, useRef } from "react";

/**
 * 防止出現 Can't perform state...的 Hook
 */
export const useIfMounted = () => {
  const isMounted = useRef(true);
  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );
  
  const ifMounted = useCallback((func) => {
    if (isMounted.current && func) {
      func();
    }
  }, []);

  return ifMounted;
};
