import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from "@material-ui/core";

import { RpMust } from "@/constants";

import classes from "./style.module.scss";

type LoginFailedDialogProps = {
  /**
   * 登入失敗次數
   */
  count: number;
} & DialogProps;

const LoginFailedDialog: React.FC<LoginFailedDialogProps> = ({ open, count, onClose }) => {
  return (
    <Dialog open={open} disableBackdropClick disableEscapeKeyDown onClose={onClose}>
      <DialogTitle>登入失敗提醒</DialogTitle>
      <DialogContent className={classes.failed}>
        <ul>
          <li>
            這是您<span style={{ color: "red" }}>第{count}次登入失敗</span>。
          </li>
          <li>
            若失敗過多次，學校的Mail系統會
            <span style={{ color: "blue" }}>暫時</span>
            鎖定住信箱，即使輸入正確的帳號/密碼也無法登入，請稍後再試。
          </li>
          <li>
            若輸入正確員工代號仍無法登入請聯絡 <br />
            {`${RpMust.contact.unit} ${RpMust.contact.name} ${RpMust.contact.tel}`}
          </li>
        </ul>
      </DialogContent>
      <DialogActions style={{ display: "flex", justifyContent: "center" }}>
        <Button variant="contained" color="primary" onClick={() => (onClose as any)()}>
          確認
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LoginFailedDialog;
