import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";

import { requestOtherBudgetList } from "@/action/budgetAction";
import { StoreTypes } from "@/store";
import { MuiSpin } from "@/components";

const OtherTable: React.FC<{ userId: string }> = ({ userId }) => {
  const dispatch = useDispatch();
  const { otherBudgets, otherBudgetTotal, isLoading } = useSelector(
    (state: StoreTypes) => state.budget
  );

  useEffect(() => {
    // 取得預算資料
    dispatch(requestOtherBudgetList(userId));
  }, [userId, dispatch]);

  return (
    <MuiSpin spinning={isLoading}>
      <Table>
        <caption style={{ textAlign: "right" }}>
          總合：{otherBudgetTotal} 元
        </caption>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: "18%" }}>品名</TableCell>
            <TableCell style={{ width: "25%" }}>用途</TableCell>
            <TableCell style={{ width: "10%" }}>單位</TableCell>
            <TableCell style={{ width: "13%" }}>數量</TableCell>
            <TableCell style={{ width: "13%" }}>單價</TableCell>
            <TableCell style={{ width: "13%" }}>小計</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {otherBudgets.length !== 0 ? (
            otherBudgets.map((o, i) => (
              <TableRow hover key={i}>
                <TableCell>{o.name}</TableCell>
                <TableCell>{o.detail}</TableCell>
                <TableCell>{o.unit}</TableCell>
                <TableCell>
                  {o.quantity}
                  {o.unit}
                </TableCell>
                <TableCell>{o.price}元</TableCell>
                <TableCell>{o.quantity * o.price}元</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6} style={{ textAlign: "center" }}>
                無資料
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </MuiSpin>
  );
};

export default memo(OtherTable);
