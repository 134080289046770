import { ROUTES } from "@/constants";
import { getOutReviewer, getOutReviewers } from "@/services/outReviewerService";
import { OutReviewer } from "@/types";
import DoneIcon from "@material-ui/icons/Done";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  List,
  ListItem,
  ListItemText,
  Link,
  DialogActions,
  DialogProps,
  Tooltip,
  Box,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { MuiSpin, RpAlert } from "..";
import AddNewDialog from "./AddNewDialog";
import MaterialTable from "material-table";

const OutReviewerList: React.FC = () => {
  const [add, setAdd] = useState(false);
  const [size, setSize] = useState<"sm" | "lg">("sm");
  const [imgState, setImgState] = useState(false);
  const [ors, setOrs] = useState<OutReviewer[]>([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentId, setCurrentId] = useState(0);
  const [currentOr, setCurrentOr] = useState<OutReviewer>({} as any);
  const {
    outReviewerId,
    account,
    address,
    bank,
    bankImage,
    email,
    idNumber,
    name,
    ok,
    password,
    phone,
    school,
    sendAddress,
  } = currentOr;

  const handleClick = (id: number) => () => {
    setCurrentId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setImgState(false);
      setSize("sm");
    }, 1);
  };

  const loadOrs = () => {
    getOutReviewers().then((res) => setOrs(res.data));
  };

  console.log("bank", bank);
  useEffect(() => {
    loadOrs();
  }, []);

  useEffect(() => {
    if (currentId === 0) {
      return;
    }
    setLoading(true);
    getOutReviewer(currentId).then((res) => {
      setLoading(false);
      setCurrentOr(res.data);
    });
  }, [currentId]);

  return (
    <>
      <MaterialTable
        title={
          <>
            <h2>
              外審委員管理
              <Button
                style={{ marginLeft: "10px" }}
                color="primary"
                variant="contained"
                onClick={() => setAdd(true)}
              >
                新增
              </Button>
            </h2>
          </>
        }
        options={{
          pageSize: 20,
          paginationType: "stepped",
          actionsColumnIndex: -1,
        }}
        localization={{
          header: {
            actions: "操作",
          },
          body: {
            editRow: {
              deleteText: "確定要刪除嗎？",
            },
          },
        }}
        detailPanel={(rowData) => {
          return (
            <Box>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Id</TableCell>
                    <TableCell>{rowData.outReviewerId}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>帳號</TableCell>
                    <TableCell>{rowData.account}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>密碼</TableCell>
                    <TableCell>{rowData.password}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>姓名</TableCell>
                    <TableCell>{rowData.name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Email</TableCell>
                    <TableCell>{rowData.email}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>身份證字號</TableCell>
                    <TableCell>{rowData.idNumber}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>學校</TableCell>
                    <TableCell>{rowData.school}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>手機</TableCell>
                    <TableCell>{rowData.phone}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>地址</TableCell>
                    <TableCell>{rowData.address}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>寄件地址</TableCell>
                    <TableCell>{rowData.sendAddress}</TableCell>
                  </TableRow>
                  <TableRow style={{ background: "#e0fac3" }}>
                    <TableCell>銀行帳號</TableCell>
                    <TableCell>{rowData.bank?.bankNumber}</TableCell>
                  </TableRow>
                  <TableRow style={{ background: "#e0fac3" }}>
                    <TableCell>分行代號</TableCell>
                    <TableCell>
                      {rowData.bank?.bankBranchId && rowData.bank?.bankName
                        ? `${rowData.bank.bankBranchId} ${rowData.bank.bankName}`
                        : "無資料"}
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ background: "#e0fac3" }}>
                    <TableCell>帳戶持有人</TableCell>
                    <TableCell>
                      {rowData.bank?.name !== null ? rowData.bank.name : "無資料"}
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ background: "#e0fac3" }}>
                    <TableCell>存證正本</TableCell>
                    <TableCell>
                      {!rowData.bankImage ? (
                        "無資料"
                      ) : (
                        <Link
                          title={`檢視${rowData.name}的存摺正本`}
                          target="_blank"
                          href={`${"/api/outReviewer/image/" + rowData.bankImage}`}
                        >
                          檢視
                        </Link>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>狀態</TableCell>
                    <TableCell>個人資料{rowData.ok ? "已填寫完成" : "尚未填寫完成"}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          );
        }}
        columns={[
          { title: "序號", field: "outReviewerId", editable: "never", width: "55px" },
          { title: "帳號", field: "account" },
          { title: "密碼", field: "password" },
          { title: "姓名", field: "name" },
          { title: "電話", field: "phone" },
          { title: "信箱", field: "email" },
          {
            title: "完成狀態",
            editable: "never",
            render: (rowData) => {
              return rowData.ok ? (
                <span style={{ color: "green" }}>
                  <Tooltip title="個人資料已填寫完成">
                    <DoneIcon />
                  </Tooltip>
                </span>
              ) : (
                <span></span>
              );
            },
          },
          // { title: "地址", field: "address" },
          // { title: "銀行", field: "bank" },
          // { title: "銀行圖片", field: "bankImage" },
          // { title: "身份證", field: "idNumber" },
          // { title: "寄送地址", field: "sendAddress" },
          // { title: "狀態", field: "ok", editable: "never" },
        ]}
        data={ors}
        editable={{
          onRowUpdate: (newInReviewer, oldData) =>
            new Promise((resolve, reject) => {
              RpAlert.error("修改失敗，未知的錯誤");
              resolve(1);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                RpAlert.error("刪除失敗，未知的錯誤");
                resolve(1);
              }, 1000);
            }),
        }}
      />
      {/* <Button variant="contained" color="primary" onClick={() => setAdd(true)}>
        新增外審委員
      </Button>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>帳號</TableCell>
            <TableCell>密碼</TableCell>
            <TableCell>姓名</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>學校</TableCell>
            <TableCell>手機</TableCell>
            <TableCell>詳情</TableCell>
            <TableCell>狀態</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ors.map((o) => (
            <TableRow hover key={o.outReviewerId}>
              <TableCell>{o.outReviewerId}</TableCell>
              <TableCell>{o.account}</TableCell>
              <TableCell>{o.password}</TableCell>
              <TableCell>{o.name}</TableCell>
              <TableCell>{o.email}</TableCell>
              <TableCell>{o.school}</TableCell>
              <TableCell>{o.phone}</TableCell>
              <TableCell>
                <Button variant="contained" color="primary" onClick={handleClick(o.outReviewerId)}>
                  檢視
                </Button>
              </TableCell>
              <TableCell>
                {o.ok && (
                  <span style={{ color: "green" }}>
                    <Tooltip title="個人資料已填寫完成">
                      <DoneIcon />
                    </Tooltip>
                  </span>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table> */}
      <Dialog maxWidth={size} fullWidth open={open} onClose={handleClose}>
        <DialogTitle>
          {loading ? "讀取中..." : currentOr.name}
          <Button
            style={{
              position: "absolute",
              right: 10,
              top: 10,
            }}
            onClick={handleClose}
          >
            <Close />
          </Button>
        </DialogTitle>
        <DialogContent>
          {bankImage && (
            <div style={{ display: imgState ? "flex" : "none", justifyContent: "center" }}>
              <img
                src={`${"/api/outReviewer/image/" + bankImage}`}
                alt=""
                style={{ width: "100%" }}
              />
            </div>
          )}

          {!imgState && (
            <MuiSpin spinning={loading}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Id</TableCell>
                    <TableCell>{outReviewerId}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>帳號</TableCell>
                    <TableCell>{account}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>密碼</TableCell>
                    <TableCell>{password}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>姓名</TableCell>
                    <TableCell>{name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Email</TableCell>
                    <TableCell>{email}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>身份證字號</TableCell>
                    <TableCell>{idNumber}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>學校</TableCell>
                    <TableCell>{school}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>手機</TableCell>
                    <TableCell>{phone}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>地址</TableCell>
                    <TableCell>{address}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>寄件地址</TableCell>
                    <TableCell>{sendAddress}</TableCell>
                  </TableRow>
                  <TableRow style={{ background: "#e0fac3" }}>
                    <TableCell>銀行帳號</TableCell>
                    <TableCell>{bank?.bankNumber}</TableCell>
                  </TableRow>
                  <TableRow style={{ background: "#e0fac3" }}>
                    <TableCell>分行代號</TableCell>
                    <TableCell>
                      {bank?.bankBranchId && bank?.bankName
                        ? `${bank?.bankBranchId} ${bank?.bankName}`
                        : "無資料"}
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ background: "#e0fac3" }}>
                    <TableCell>帳戶持有人</TableCell>
                    <TableCell>{bank?.name ? bank.name : name}</TableCell>
                  </TableRow>
                  <TableRow style={{ background: "#e0fac3" }}>
                    <TableCell>存證正本</TableCell>
                    <TableCell>
                      {!bankImage ? (
                        "沒有上傳圖片"
                      ) : (
                        <Link
                          title={`檢視${name}的存摺正本`}
                          onClick={() => {
                            setSize("lg");
                            setImgState(true);
                          }}
                        >
                          檢視
                        </Link>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>狀態</TableCell>
                    <TableCell>個人資料{ok ? "已填寫完成" : "尚未填寫完成"}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </MuiSpin>
          )}
        </DialogContent>
        <DialogActions>
          <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
            {imgState && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setImgState(false);
                    setSize("sm");
                  }}
                  style={{ marginRight: 20 }}
                >
                  返回
                </Button>

                <Tooltip title="在新視窗開啟原始圖片">
                  <Button
                    variant="contained"
                    color="default"
                    onClick={() => {
                      window.open(`${"/api/outReviewer/image/" + bankImage}`, "_blank");
                    }}
                  >
                    檢視原始圖片
                  </Button>
                </Tooltip>
              </>
            )}

            {!imgState && (
              <Button variant="contained" color="primary" disabled>
                編輯
              </Button>
            )}
          </div>
        </DialogActions>
      </Dialog>
      <AddNewDialog open={add} onClose={() => setAdd(false)} loadOrs={loadOrs} />
    </>
  );
};

export default OutReviewerList;
