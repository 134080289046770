import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, List, ListItem } from "@material-ui/core";

import { StoreTypes } from "@/store";
import { useRpMustUser } from "@/hooks";

import DetailItem from "./DetailItem";
import EditDialog from "./EditDialog";
import { MuiSpin } from "..";

const ProjectDetail: React.FC = () => {
  const { isLoading, data: project } = useSelector((state: StoreTypes) => state.project);
  const { unit } = useRpMustUser();
  const [open, setOpen] = useState(false);

  return (
    <MuiSpin spinning={isLoading}>
      <List style={{ paddingTop: 0 }}>
        <DetailItem title="申請人代號" content={project.userId} />
        <DetailItem title="申請人姓名" content={project.userName} />
        <DetailItem title="所屬單位" content={unit} />
        <DetailItem title="手機號碼" content={project.phone} />
        <DetailItem title="校內分機" content={project.ext} />
        <DetailItem title="計畫名稱" content={project.name} />
        <DetailItem title="衍生成果" content={project.property} />
        <DetailItem title="計畫類型" content={project.type} />
        <DetailItem title="學門分類" content={project.most} />
        <DetailItem
          title="是否為新進教師"
          content={
            project.isNewTeacher
              ? `是 （${moment(project.appointmentDate).format("YYYY/MM")}-至今）`
              : `否，已到校超過三年以上`
          }
        />
        <ListItem style={{ display: "flex", justifyContent: "center" }}>
          <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
            編輯
          </Button>
          <EditDialog open={open} handleOpen={setOpen} />
        </ListItem>
      </List>
    </MuiSpin>
  );
};

export default ProjectDetail;
