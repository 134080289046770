import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { ReplayRounded } from "@material-ui/icons";

import { deleteInReviewer, postInReviewer, putInReviewer } from "@/services/inReviewerService";
import { requestInReviewer } from "@/action/reviewerAction";
import { StoreTypes } from "@/store";
import { MuiSpin, RpAlert } from "@/components";

import EditTableRow from "./EditTableRow";
import classes from "./In.module.scss";
import MaterialTable from "material-table";
import { resolve } from "url";

const In: React.FC = () => {
  const [currentEdit, setCurrentEdit] = useState(0);
  const dispatch = useDispatch();
  const { inReviewers, isLoading } = useSelector((state: StoreTypes) => state.reviewer);

  useEffect(() => {
    dispatch(requestInReviewer());
  }, [dispatch]);

  const handleDelete = (id: number) => {
    if (!window.confirm("確認刪除？")) {
      return;
    }

    deleteInReviewer(id)
      .then((res) => {
        if (res.data) {
          RpAlert.show("刪除成功！");
        } else {
          RpAlert.error("刪除失敗！");
        }
      })
      .finally(() => dispatch(requestInReviewer()));
  };

  const rendermui = () => (
    <MUIDataTable
      columns={[
        {
          name: "inReviewerId",
          label: "序號",
        },
        {
          name: "account",
          label: "帳號",
        },
        {
          name: "password",
          label: "密碼",
        },
        {
          name: "name",
          label: "姓名",
        },
        {
          name: "actions",
          label: "操作",
          options: {
            filter: false,
            sort: false,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <Button
                    color="primary" /* onClick={() => setCurrentEdit(reviewer.inReviewerId)} */
                  >
                    編輯
                  </Button>{" "}
                  <Button
                    color="secondary" /* onClick={() => handleDelete(reviewer.inReviewerId)} */
                  >
                    刪除
                  </Button>
                </div>
              );
            },
          },
        },
      ]}
      data={inReviewers}
      title="內審委員管理"
      options={{
        selectableRowsHeader: false,
        selectableRows: "none",
      }}
    />
  );

  return (
    <>
      <MaterialTable
        title="內審委員管理"
        options={{
          pageSize: 10,
          paginationType: "stepped",
          actionsColumnIndex: -1,
        }}
        localization={{
          header: {
            actions: "操作",
          },
          body: {
            editRow: {
              deleteText: "確定要刪除嗎？",
            },
          },
        }}
        columns={[
          { title: "序號", field: "inReviewerId", editable: "never" },
          { title: "帳號", field: "account" },
          { title: "密碼", field: "password" },
          { title: "姓名", field: "name" },
        ]}
        data={inReviewers}
        editable={{
          onRowAdd: async (newData) => {
            const res = await postInReviewer(newData);
            if (res.data) {
              RpAlert.show("新增成功！");
            } else {
              RpAlert.error("新增失敗！");
            }
            dispatch(requestInReviewer());
          },
          onRowUpdate: async (newInReviewer, oldData) => {
            const res = await putInReviewer(newInReviewer);
            if (res.data) {
              RpAlert.show("修改成功！");
            } else {
              RpAlert.error(`修改失敗！\n${res.data.message}`);
            }
            dispatch(requestInReviewer());
          },
          onRowDelete: async (oldData) => {
            var res = await deleteInReviewer(oldData.inReviewerId);
            if (res.data) {
              RpAlert.show("刪除成功！");
            } else {
              RpAlert.error("刪除失敗！");
            }
            dispatch(requestInReviewer());
          },
        }}
      />

      {/* <Card>
        <CardHeader title="內審委員管理" />
        <CardContent>
          <MuiSpin spinning={isLoading}>
            <Grid container>
              <Grid item style={{ flex: "1" }}>
                <Button onClick={() => dispatch(requestInReviewer())} style={{ margin: "10px" }}>
                  <ReplayRounded />
                  刷新
                </Button>
              </Grid>
              <Grid item>
                <Button style={{ margin: "10px" }}>新增</Button>
              </Grid>
            </Grid>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className={classes["index-cell"]}>序號</TableCell>
                  <TableCell className={classes["account-cell"]}>帳號</TableCell>
                  <TableCell className={classes["password-cell"]}>密碼</TableCell>
                  <TableCell className={classes["name-cell"]}>名稱</TableCell>
                  <TableCell className={classes["action-cell"]}>操作</TableCell>
                </TableRow>
                {inReviewers.map((reviewer, i) => {
                  let editing: boolean = currentEdit === reviewer.inReviewerId;

                  return (
                    <React.Fragment key={i}>
                      {editing ? (
                        // 編輯
                        <EditTableRow
                          index={i + 1}
                          // 把編輯id設成0
                          onCancelEdit={() => setCurrentEdit(0)}
                          reviewer={reviewer}
                        />
                      ) : (
                        // 正常顯示
                        <TableRow hover>
                          <TableCell className={classes["index-cell"]}>{i + 1}</TableCell>
                          <TableCell>{reviewer.account}</TableCell>
                          <TableCell>{reviewer.password}</TableCell>
                          <TableCell>{reviewer.name}</TableCell>
                          <TableCell className={classes["action-cell"]}>
                            <Button
                              color="primary"
                              onClick={() => setCurrentEdit(reviewer.inReviewerId)}
                            >
                              編輯
                            </Button>{" "}
                            <Button
                              color="secondary"
                              onClick={() => handleDelete(reviewer.inReviewerId)}
                            >
                              刪除
                            </Button>
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </MuiSpin>
        </CardContent>
      </Card> */}
    </>
  );
};

export default In;
