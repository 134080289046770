import "antd/dist/antd.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./App.scss";
import "react-quill/dist/quill.snow.css";

import React, { memo, useEffect } from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

import { AppRouter } from "./routes/";
import { useDispatch } from "react-redux";
import { requestSetting } from "./action/settingAction";

//移除console.log
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
}

// 主題顏色
const theme = createMuiTheme({
  palette: {
    primary: { main: `#2196f3` },
    // secondary: { main: `#f50057` },
    secondary: { main: `#d60924` },
  },
  typography: {
    fontFamily: "微軟正黑體",
    fontSize: 14,
  },
});

const App: React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(requestSetting());
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <AppRouter />
    </ThemeProvider>
  );
};
export default memo(App);
