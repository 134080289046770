import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

import { getLogs } from "@/services/settingService";
import { ROUTES } from "@/constants";
import { useQuery } from "@/hooks";
import { MuiSpin } from "@/components";

import classes from "./Log.module.scss";

type LogType = {
  id: number;
  ip: string;
  logged: Date;
  level: string;
  message: string;
  location: string;
  exception: string;
};

const Log: React.FC = () => {
  const id = useQuery("detail");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [logData, setLogData] = useState<LogType[]>([]);
  const [open, setOpen] = useState(false);
  const [log, setLog] = useState<LogType>({} as any);
  const [pn, setPn] = useState({
    prev: 0,
    next: 0,
  });
  const history = useHistory();

  const columns = [
    { label: "Id", name: "id" },
    { label: "Ip", name: "ip" },
    { label: "記錄日期", name: "logged" },
    { label: "等級", name: "level" },
    { label: "訊息", name: "message" },
    { label: "位置", name: "location" },
    { label: "例外", name: "exception" },
  ];

  const handleClose = () => {
    setOpen(false);
    history.replace(ROUTES.ADMIN.LOG);
  };

  /**
   * 切換頁面(dialog)
   * @param index
   */
  const handlePageSwitch = (index: number) => {
    if (logData[index].id) {
      history.replace(`${ROUTES.ADMIN.LOG}?detail=${logData[index].id}`);
    }
  };

  //重新整理時以url來觸發dialog
  useEffect(() => {
    if (!id || id === "") {
      return;
    }
    setLoading(true);
    setOpen(true);

    const log = logData.find((log, i) => {
      if (log.id === Number(id)) {
        setPn({
          prev: logData[i - 1] && i - 1,
          next: logData[i + 1] && i + 1,
        });
        return true;
      }
      return false;
    });

    if (log) {
      setLog(log);
      setLoading(false);
    }
  }, [id, logData]);

  useEffect(() => {
    getLogs().then((res) => setLogData(res.data));
  }, []);

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        disableBackdropClick
        open={open}
        maxWidth="md"
        onClose={handleClose}
      >
        <DialogTitle>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flex: "1" }}>詳細資訊</div>
            <div>
              <Button onClick={handleClose}>
                <Close />
              </Button>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <MuiSpin spinning={loading}>
            <Table>
              <colgroup>
                <col style={{ width: "10%" }} />
                <col style={{ width: "90%" }} />
              </colgroup>
              <TableBody>
                {columns.map((c, i) => (
                  <TableRow key={i}>
                    <TableCell>{c.label}</TableCell>
                    <TableCell>{(log as any)[c.name]}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </MuiSpin>
        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "center" }}>
          {logData[pn.prev] && (
            <Button
              disabled={loading}
              variant="outlined"
              onClick={() => handlePageSwitch(pn.prev)}
            >
              上一個
            </Button>
          )}
          {logData[pn.next] && (
            <Button
              disabled={loading}
              variant="outlined"
              onClick={() => handlePageSwitch(pn.next)}
            >
              下一個
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Card>
        <CardHeader title="Log記錄檔" />
        <CardContent>
          <div>
            <TextField placeholder="搜尋" />
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={logData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={(_, newPage) => setPage(newPage)}
              onChangeRowsPerPage={(event) => {
                setRowsPerPage(parseInt(event.target.value, 10));
                setPage(0);
              }}
              labelDisplayedRows={({ from, to, count }) =>
                `第${from}筆~第${to}筆，共${count}筆`
              }
              labelRowsPerPage="每頁數量"
            />
          </div>
          <Table style={{ tableLayout: "fixed" }}>
            <colgroup>
              <col style={{ width: "2%" }} />
              <col style={{ width: "5%" }} />
              <col style={{ width: "5%" }} />
              <col style={{ width: "3%" }} />
              <col style={{ width: "13%" }} />
              <col style={{ width: "13%" }} />
              <col style={{ width: "4%" }} />
            </colgroup>
            <TableHead>
              <TableRow>
                {columns.map((c, i) => (
                  <TableCell key={i}>{c.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {logData.length > 0 ? (
                logData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((log, i) => (
                    <TableRow
                      key={i}
                      hover
                      className={classes.row}
                      onClick={() =>
                        history.replace(`${ROUTES.ADMIN.LOG}?detail=${log.id}`)
                      }
                    >
                      <TableCell className={classes["no-wrap"]}>{log.id}</TableCell>
                      <TableCell className={classes["no-wrap"]}>
                        {log.ip}
                      </TableCell>
                      <TableCell className={classes["no-wrap"]}>
                        {moment(log.logged).format("yyyy/MM/DD")}
                      </TableCell>
                      <TableCell className={classes["no-wrap"]}>
                        <span
                          style={{
                            color: log.level === "Info" ? "blue" : "red",
                          }}
                        >
                          {log.level}
                        </span>
                      </TableCell>
                      <TableCell>
                        <div className={classes.wrapper}>
                          <div className={classes["no-wrap"]}>
                            {log.message}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          overflow: "hidden",
                          overflowWrap: "break-word",
                        }}
                      >
                        {log.location}
                      </TableCell>
                      <TableCell>
                        <div className={classes.wrapper}>
                          <div className={classes["no-wrap"]}>
                            {log.exception}
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    無資料
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default Log;
