import "./UploadFiles.scss";

import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";

import { useRpMustUser } from "@/hooks";
import {
  getHasEvidenceFile,
  getHasFile,
  getProjectType,
  postProjectEvidenceFile,
  postProjectFile,
} from "@/services/projectService";
import { requestFiles } from "@/action/projectAction";
import { StoreTypes } from "@/store";

import UploadSection from "./UploadSection";
import { MuiSpin, RpAlert } from "..";

type FileContextType = {
  files: File[];
  x: number;
  setXX: (v: number) => void;
  isEmptyId: number[];
  setFileData: (file: File, index: number) => void;
  setIsEmptyId: (value: number[]) => void;
  clearFiles: () => void;
};

export const FileContext = createContext<FileContextType>({} as FileContextType);

export const fileTitle = ["個人資料", "計畫書"];

const UploadFiles: React.FC = () => {
  const [XX, setXX] = useState(0);

  const [radioValue, setRadioValue] = useState(0);
  const [typeId, setTypeId] = useState(-1);
  const [hasFile, setHasFile] = useState(false);
  const [hasFile2, setHasFile2] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [isEmptyId, setIsEmptyId] = useState<number[]>([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { userId } = useRpMustUser();
  const dispatch = useDispatch();
  const { files: fileLinks, data: project } = useSelector((store: StoreTypes) => store.project);

  useEffect(() => {
    console.log(project);
    if (project.personalType) {
      setRadioValue(project.personalType);
    }
  }, [project.personalType]);

  useEffect(() => {
    (async () => {
      //取得計劃類型id
      const id = (await getProjectType(userId)).data;
      setTypeId(id);
    })();
  }, [project.type, userId]);

  useEffect(() => {
    //抓取計畫檔案
    dispatch(requestFiles(userId));
    getHasFile(userId).then((res) => setHasFile(res.data));
    getHasEvidenceFile(userId).then((res) => setHasFile2(res.data));
  }, [userId, dispatch]);

  const clearFiles = (index?: number) => {
    if (index) {
      let arr = [...files];
      arr[index] = null as any;
      setFiles(arr);
    }
  };

  const setFileData = (file: File, index: number) => {
    console.log(files);
    let arr = files;
    arr[index] = file;

    console.log(arr);
    setFiles(arr);
  };

  const handleUpload = () => {
    let count = 2;
    //防止空檔案
    if (!files || files.length < count || !files[0] || !files[1]) {
      RpAlert.error("請選擇" + count + "個檔案");
      return;
    }

    setLoading(true);

    const data = new FormData();

    //將檔案新增到FormData
    files.forEach((file) => {
      data.append("files", file);
    });

    postProjectFile(data, userId)
      .then((res) => {
        if (res.data.message) {
          RpAlert.error("上傳失敗！" + res.data.message);
          return;
        }

        RpAlert.show("上傳成功！");

        //清除檔案
        let arr = [...files];
        arr[0] = null as any;
        arr[1] = null as any;
        setFiles(arr);

        //抓取計畫檔案
        dispatch(requestFiles(userId));
        getHasFile(userId).then((res) => setHasFile(res.data));
      })
      .catch(() => {
        RpAlert.error("上傳失敗！");
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  const handleUploadEvidence = () => {
    if (!files || files.length < 3 || !files[2]) {
      RpAlert.error("請選擇檔案");
      return;
    }
    setLoading(true);

    const data = new FormData();

    //將檔案新增到FormData
    data.append("file", files[2]);
    data.append("personalType", radioValue.toString());

    postProjectEvidenceFile(data, userId)
      .then((res) => {
        if (res.data.message) {
          RpAlert.error("上傳失敗！" + res.data.message);
          return;
        }

        RpAlert.show("上傳成功！");

        //清除檔案
        clearFiles(2);

        //抓取計畫檔案
        dispatch(requestFiles(userId));
        getHasEvidenceFile(userId).then((res) => setHasFile2(res.data));
      })
      .catch(() => {
        RpAlert.error("上傳失敗！");
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value);
    setRadioValue(Number(e.target.value));
  };

  /**
   * 上傳失敗的dialog
   */
  const renderDialog = () => {
    return (
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>錯誤</DialogTitle>
        <DialogContent>請上傳檔案</DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={() => setOpen(false)}>
            確定
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  /**
   * 渲染檔案連結按鈕
   */
  const renderFileLinkButton = () => {
    return fileLinks.map((link, i) => {
      return (
        link && (
          <Button key={link} variant="text" color="primary" href={`/file/${link}`} target="blank">
            {fileTitle[i]}
          </Button>
        )
      );
    });
  };

  return (
    <FileContext.Provider
      value={{ x: XX, setXX, files, isEmptyId, setFileData, setIsEmptyId, clearFiles }}
    >
      <MuiSpin spinning={loading}>
        {typeId === 1 && (
          <>
            <Card>
              <CardContent>
                <div className="upload-files-box">
                  <div className="title">佐證檔案（For 個人型計畫）</div>
                  <FormControl component="div">
                    <RadioGroup
                      aria-label="gender"
                      name="gender1"
                      value={radioValue}
                      onChange={handleRadioChange}
                    >
                      <FormControlLabel
                        style={{ marginLeft: "8rem" }}
                        value={0}
                        control={<Radio color="primary" />}
                        label="前一年度申請國科會計畫(含一般專題及產學專題)，未獲補助者"
                      />
                      <FormControlLabel
                        style={{ marginLeft: "8rem" }}
                        value={1}
                        control={<Radio color="primary" />}
                        label="前一年度以本校名義投稿於有審稿制度之專業期刊(不含掠奪性期刊)，未獲通過"
                      />
                    </RadioGroup>
                  </FormControl>

                  <UploadSection title="佐證檔案" index={2} />

                  <section className="show-files">
                    <div>檢視已上傳檔案</div>
                    <div>
                      {hasFile2 ? (
                        <Button
                          variant="text"
                          color="primary"
                          href={`/file/${fileLinks[2]}`}
                          target="blank"
                        >
                          佐證
                        </Button>
                      ) : (
                        <span style={{ color: "#888" }}>未上傳任何檔案</span>
                      )}
                    </div>
                  </section>

                  <footer>
                    <Button variant="contained" color="primary" onClick={handleUploadEvidence}>
                      上傳
                    </Button>
                  </footer>
                </div>
              </CardContent>
            </Card>
            <br />
            <br />
          </>
        )}

        <Card>
          <CardContent>
            <div className="upload-files-box">
              <div className="title">計畫檔案</div>
              <UploadSection title="個人資料" index={0} />

              <UploadSection title="計畫書" index={1} />

              <section className="show-files">
                <div>檢視已上傳檔案</div>
                <div>
                  {hasFile ? (
                    renderFileLinkButton()
                  ) : (
                    <span style={{ color: "#888" }}>未上傳任何檔案</span>
                  )}
                </div>
              </section>

              <footer>
                <Button variant="contained" color="primary" onClick={handleUpload}>
                  上傳
                </Button>
              </footer>
              {renderDialog()}
            </div>
          </CardContent>
        </Card>
      </MuiSpin>
    </FileContext.Provider>
  );
};

export default UploadFiles;
