import React, { memo, useContext, useEffect, useState } from "react";
import { IconButton, TextField } from "@material-ui/core";
import { Delete } from "@material-ui/icons";

import classes from "../style.module.scss";
import { TeacherScoreContext } from "../TeacherScore";

const OtherContentInput: React.FC<{ onDelete: () => void }> = ({
  onDelete,
}) => {
  const { otherContent, setOtherContent: setContentOnContext } = useContext(
    TeacherScoreContext
  );
  const [content, setContent] = useState(otherContent ? otherContent : "");

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => setContent(e.target.value);

  const handleBlur = () => {
    setContentOnContext(content);
  };

  useEffect(() => {
    setContent(content);
  }, [content]);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ flex: 1, marginBottom: 8 }}>
        <TextField
          fullWidth
          multiline
          rows={2}
          rowsMax={8}
          variant="outlined"
          size="small"
          placeholder="補充說明：填寫如產學編號、技轉編號、計畫編號等.."
          value={content}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      <div className={classes.delete}>
        <IconButton size="small" onClick={onDelete}>
          <Delete />
        </IconButton>
      </div>
    </div>
  );
};

export default memo(OtherContentInput);
