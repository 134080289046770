import React, { memo, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";

import { deleteProjectType, getProjectTypes, postProjectType, putProjectType } from "@/services/projectService";
import { useMountEffect } from "@/hooks";
import { ProjectType as PT } from "@/types";
import { MuiSpin, RpAlert } from "@/components";

import ProjectTypeDialog from "./ProjectTypeDialog";
import ProjectTypeInput from "./ProjectTypeInput";

const ProjectType: React.FC = () => {
  const [pt, setPt] = useState<PT>({} as any);
  const [active, setActive] = useState(false);
  const [direction, setDirection] = useState<"desc" | "asc" | undefined>("asc");
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [projectTypes, setProjectTypes] = useState<PT[]>([]);
  const [searchResult, setSearchResult] = useState<PT[]>([]);
  const [loading, setLoading] = useState(true);

  const getData = () => {
    getProjectTypes().then((res) => {
      setLoading(false);
      setProjectTypes(res.data);
    });
  };

  const search = (value: string) => {
    if (value.trim() === "") {
      setSearchResult(projectTypes);
      return;
    }

    setSearchResult(
      projectTypes.filter((pt) => {
        return (
          pt.name.indexOf(value) !== -1 ||
          pt.id.toString().indexOf(value) !== -1
        );
      })
    );
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    search(e.target.value);
    setSearchText(e.target.value);
  };

  const handleDelete = (id: number) => {
    setLoading(true);
    deleteProjectType(id).then((res) => {
      res.data ? RpAlert.show("刪除成功") : RpAlert.error("刪除失敗");
      getData();
    });
  };

  const handleAdd = async (name: string) => {
    try {
      const data = (await postProjectType(name)).data;
      return data;
    } catch {
      return false;
    } finally {
      getData();
    }
  };

  const handleOpen = (pt: PT) => {
    setPt(pt);
    setOpen(true);
  };

  const handleUpdate = (value: PT) => {
    putProjectType(value).then((res) => {
      if (res.data) {
        RpAlert.show("修改成功！");
        setOpen(false);
      } else {
        RpAlert.error("修改失敗！");
      }
      getData();
    });
  };

  useMountEffect(() => getData());

  useEffect(() => {
    setSearchResult(projectTypes);
    search(searchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectTypes, searchText]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={7}>
        <Card>
          <CardHeader title="計畫類型編輯" />
          <CardContent>
            <ProjectTypeDialog
              open={open}
              pt={pt}
              onClose={() => setOpen(false)}
              onUpdate={handleUpdate}
            />
            <MuiSpin spinning={loading}>
              <div style={{ width: "100%" }}>
                <TextField
                  size="small"
                  placeholder="搜尋"
                  style={{ float: "right" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        style={{ marginTop: -3 }}
                      >
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleChange}
                />
              </div>
              <Table style={{ tableLayout: "fixed" }}>
                <colgroup>
                  <col style={{ width: "13%" }} />
                  <col />
                  <col style={{ width: "25%" }} />
                </colgroup>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <TableSortLabel
                        active={active}
                        direction={direction}
                        onClick={() => {
                          setActive(true);
                          setDirection(direction === "desc" ? "asc" : "desc");
                          setSearchResult(() => {
                            if (direction === "desc") {
                              return searchResult.sort((a, b) => a.id - b.id);
                            } else {
                              return searchResult.sort((a, b) => b.id - a.id);
                            }
                          });
                        }}
                      >
                        編號
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>類型名稱</TableCell>
                    <TableCell align="center">操作</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchResult.length > 0 ? (
                    searchResult.map((p) => (
                      <TableRow key={p.id} hover>
                        <TableCell>
                          <div style={{ paddingLeft: "10px" }}>{p.id}</div>
                        </TableCell>
                        <TableCell>{p.name}</TableCell>
                        <TableCell>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              style={{ marginRight: "10px" }}
                              onClick={() => handleOpen(p)}
                            >
                              編輯
                            </Button>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() =>
                                window.confirm("確定要刪除？") &&
                                handleDelete(p.id)
                              }
                            >
                              刪除
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={3}>
                        無資料
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </MuiSpin>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={5}>
        <ProjectTypeInput onSubmit={handleAdd} />
        <Card style={{ margin: "16px 0" }}>
          <CardContent>
            注意
            <ul>
              <li>
                如出現刪除失敗則意味著還有計畫關聯著該計畫類型，請移除所有關聯的計畫後再刪除
              </li>
            </ul>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default memo(ProjectType);
