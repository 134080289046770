import React, { createContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { isEmpty } from "lodash";
import { Button, Container, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";

import { TeacherPerformance } from "@/types";
import { useRpMustUser } from "@/hooks";
import { ROUTES, RpMust } from "@/constants";
import { addTeacherScore, getTeacherScore } from "@/services/projectService";

import ScoreBoard from "./ScoreBoard";
import TeacherScoreDialog from "./TeacherScoreDialog/TeacherScoreDialog";
import ZeroTipDialog from "./ZeroTipDialog";
import { MuiSpin, RpAlert } from "..";

export type Evidence = {
  title: string;
  file: any;
  isNew: boolean;
};

export type TeacherScoreType = {
  id: number;
  selectId: number;
  teacherPerformance: TeacherPerformance;
  option: string;
  evidence: {
    title: string;
    fileName: string;
  };
  otherContent: string;
  score: number;
};

type TeacherScoreContextType = {
  /**
   * 績效選項
   */
  radio: number;

  /**
   * 分類選項
   */
  selectId: number;

  /**
   * 績效
   */
  teacherPerformance: TeacherPerformance;

  /**
   * 佐證
   */
  evidence: Evidence;

  /**
   * 績效分數
   */
  teacherScore: TeacherScoreType[];

  /**
   * 分數
   */
  score: number;

  /**
   * 總分
   */
  total: number;

  /**
   * 其他資訊
   */
  otherContent: string;
  loading: boolean;
  changeRadio: (value: number) => void;
  changeTeacherPerformance: (value: TeacherPerformance) => void;
  getTeacherScoreData: () => void;
  setEvidence: (value: Evidence) => void;
  setSelectId: (id: number) => void;
  setContextScore: (score: number) => void;
  setTotal: (value: number) => void;
  setLoading: (value: boolean) => void;

  /**
   * 設置其他內容
   */
  setOtherContent: (value: string) => void;

  /**
   * 繼續
   */
  onConfirm: () => void;

  /**
   * 驗證佐證是否合格
   */
  validEvidence: () => { success: boolean; message: string };

  /**
   * 重置
   */
  reset: () => void;
};

export const TeacherScoreContext = createContext<TeacherScoreContextType>({} as any);

const emptyEvidence: Evidence = {
  title: "",
  file: null,
  isNew: true,
};

const TeacherScore: React.FC<{ userId?: string }> = ({ userId = "" }) => {
  const user = useRpMustUser();
  const [teacherScore, setTeacherScore] = useState<TeacherScoreType[]>([]);
  const [teacherPerformance, setTeacherPerformance] = useState<TeacherPerformance>(
    {} as TeacherPerformance
  );
  const [otherContent, setOtherContent] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [score, setScore] = useState(0);
  const [total, setTotal] = useState(0);
  const [open, setOpen] = useState(false);
  const [isZero, setIsZero] = useState(false);
  const [add, setAdd] = useState(false);
  const [radio, setRadio] = useState(0);
  const [selectId, setSelectId] = useState(0);
  const [evidence, setEvidence] = useState<Evidence>({ ...emptyEvidence });
  const history = useHistory();

  userId = userId === "" ? user.userId : userId;

  /**
   * 重置
   */
  const reset = () => {
    //重置佐證內容
    setEvidence({ ...emptyEvidence });

    //重置選項
    setSelectId(0);

    //重置分數
    setScore(0);

    //重置其他內容
    setOtherContent("");
  };

  /**
   * 改變radio
   * @param value
   */
  const changeRadio = (value: number) => setRadio(value);

  /**
   * 改變績效選項
   * @param value
   */
  const changeTeacherPerformance = (value: TeacherPerformance) => setTeacherPerformance(value);

  /**
   * 取得教師績效分數
   */
  const getTeacherScoreData = () => {
    setLoading(true);
    getTeacherScore(userId).then((res) => {
      setTeacherScore(res.data);
      setLoading(false);
    });
  };

  /**
   * 確認
   */
  const handleConfirmClick = () => setAdd(true);

  /**
   * 驗證佐證內容(只驗證新的，即isNew為true的)
   */
  const validEvidence = () => {
    const fileTypes = [".pdf"];
    let message = "";
    if (isEmpty(evidence.title.trim())) {
      message = "請勿留空！";
    }

    //這邊要用Number()，不能用parseInt()，否則有些錯誤的格式照樣會被當成數字
    //EX: parseInt(202eeaa)被轉成202，而Number(202eeaa)會被轉成NaN
    else if (teacherPerformance.method === 3 && isNaN(Number(evidence.title))) {
      message = "請輸入正確的數值！";
    } else if (score < 1) {
      message = "分數為 0 無法新增！";
    } else if (evidence.isNew && !evidence.file) {
      message = "請上傳佐證檔案！";
    } else if (evidence.file) {
      const ext = evidence.file.name.split(".").pop(); //副檔名
      if (fileTypes.indexOf(`.${ext}`) === -1) {
        message = `檔案格式錯誤！`;
      }
    } else if (evidence.file && evidence.file?.size > RpMust.fileMaxSize) {
      message = `單個檔案不可以超過 ${RpMust.sizeConvert(RpMust.fileMaxSize)}！`;
    }

    return {
      success: isEmpty(message),
      message,
    };
  };

  /**
   * 新增教師績效分數
   */
  const handleAddClick = () => {
    const { success, message } = validEvidence();

    if (!success) {
      RpAlert.error(message);
      return;
    }

    const data = new FormData();
    data.append("selectId", selectId as any); //選擇項目
    data.append("score", score as any); //分數
    data.append("evidenceTitle", evidence.title); //佐證名稱
    data.append("file", evidence.file as any); //檔案
    otherContent && data.append("otherContent", otherContent); //補充說明 (可選)

    setLoading(true);
    addTeacherScore(userId, data)
      .then((res) => {
        if (res.data.success) {
          setOpen(false);
          setRadio(0);
          RpAlert.show("新增成功");
        } else {
          RpAlert.error("新增失敗！" + res.data.message);
        }
        getTeacherScoreData();
        setLoading(false);
      })
      .catch(() => {
        RpAlert.error("新增失敗！請重新整理網頁後再試一次！");
        setLoading(false);
      });
  };

  /**
   * 顯示新增績效的dialog
   */
  const handleShowDialog = () => {
    setAdd(false);
    setOpen(true);
  };

  /**
   * 關閉dialog
   */
  const handleClose = () => {
    setRadio(0);
    setOpen(false);
  };

  /**
   * 編列完成，跳到預算
   */
  const handleSuccess = () => {
    if (total < 1) {
      setIsZero(true);
    } else {
      gotoBudget();
    }
  };

  /**
   * 跳到預算
   */
  const gotoBudget = () => history.push(ROUTES.PROJECT.MANAGER_BUDGET.INDEX);

  // 清除剛剛緩存的資料
  useEffect(() => {
    reset();
  }, [radio]);

  return (
    <TeacherScoreContext.Provider
      value={{
        radio,
        teacherPerformance,
        evidence,
        selectId,
        teacherScore,
        score,
        total,
        otherContent,
        loading,
        getTeacherScoreData,
        changeRadio,
        changeTeacherPerformance,
        setEvidence,
        setSelectId,
        setTotal,
        setContextScore: setScore,
        setLoading,
        setOtherContent,
        validEvidence,
        onConfirm: handleConfirmClick,
        reset,
      }}
    >
      <TeacherScoreDialog
        open={open && total < 50}
        add={add}
        onClickOther={() => setAdd(false)}
        onClose={handleClose}
        onConfirm={handleConfirmClick}
        onAdd={handleAddClick}
      />

      <ZeroTipDialog
        open={isZero}
        justClose
        onClose={() => setIsZero(false)}
        onConfirm={() => {
          setIsZero(false);
          gotoBudget();
        }}
      />

      <Container>
        <MuiSpin spinning={loading}>
          <div style={{ display: "flex" }}>
            <Typography color="primary" style={{ marginLeft: "10px", flex: "1" }}>
              目前分數：{total}
              （最高採計30分）
            </Typography>
            <div style={{ flexBasis: "6rem" }}>
              <Button
                variant="contained"
                color="primary"
                disabled={total >= 50}
                startIcon={<Add />}
                onClick={handleShowDialog}
              >
                新增
              </Button>
            </div>
          </div>
          <ScoreBoard />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "16px 0",
            }}
          >
            <div style={{ flex: "1" }}></div>
            <div style={{ flex: "1", textAlign: "center" }}>
              <Button color="primary" variant="contained" onClick={handleSuccess}>
                教師績效編列完成
              </Button>
            </div>
            <div style={{ flex: "1", textAlign: "right" }}>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => window.open(RpMust.url.performanceTable)}
              >
                教師績效認列一覽表
              </Button>
            </div>
          </div>
        </MuiSpin>
      </Container>
    </TeacherScoreContext.Provider>
  );
};

export default TeacherScore;
