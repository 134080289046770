import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { requestOtherBudgetList } from "@/action/budgetAction";
import { MuiSpin, RpAlert } from "@/components";
import { useRpMustUser } from "@/hooks";
import { putOtherBudget } from "@/services/budgetService";
import { OtherBudget } from "@/types";

import OtherForm from "./OtherForm";

type EditDialogProps = {
  otherBudget: OtherBudget;
} & DialogProps;

const EditDialog: React.FC<EditDialogProps> = ({ open, otherBudget, onClose, ...rest }) => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState<any>({});
  const { userId } = useRpMustUser();
  const dispatch = useDispatch();

  const handleFinish = (value: any) => {
    setLoading(true);
    value.price = Number(value.price);
    value.quantity = Number(value.quantity);

    putOtherBudget(value, otherBudget.id)
      .then((res) => {
        setLoading(false);
        if (res.data.message) {
          RpAlert.error(res.data.message);
          return;
        }
        RpAlert.show("修改成功");
        dispatch(requestOtherBudgetList(userId));
        (onClose as any)();
      })
      .catch(() => RpAlert.error("修改失敗"))
      .finally(() => setLoading(false));
  };

  return (
    <Dialog open={open} maxWidth="md" fullWidth {...rest}>
      <DialogTitle>編輯研究耗材及其他費用</DialogTitle>
      <DialogContent>
        <MuiSpin spinning={loading}>
          <OtherForm
            mode="edit"
            otherBudget={otherBudget}
            loading={loading}
            setData={setForm}
            onFinish={handleFinish}
          />
        </MuiSpin>
      </DialogContent>
      <DialogActions style={{ display: "flex", justifyContent: "center" }}>
        <Button variant="contained" color="inherit" onClick={() => (onClose as any)()}>
          取消
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={() => form.submitForm()}
        >
          確認
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDialog;
