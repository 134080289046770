import React, { memo, useEffect, useState } from "react";
import { Route, RouteProps, useLocation } from "react-router-dom";

import { useIfMounted, useLogout } from "@/hooks";
import { checkAuth } from "@/services/loginService";

import { Loading } from "..";

const AuthorizeRoute: React.FC<RouteProps> = ({ children, ...props }) => {
  const location = useLocation();
  const logout = useLogout();
  const ifMounted = useIfMounted();
  const [loading, setLoading] = useState(true);
  const redirect = `/login?url=${location.pathname}`;

  useEffect(() => {
    checkAuth()
      .then((res) => {
        if (res.data) {
          ifMounted(() => setLoading(false));
          return;
        }

        logout(redirect);
      })
      .catch(() => logout(redirect));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>{loading ? <Loading /> : <Route {...props}>{children}</Route>}</React.Fragment>
  );
};

export default memo(AuthorizeRoute);
