import store from "@/store";
import { RpMustUser } from "@/types";

import { requestProject, resetProject } from "./projectAction";

export enum AUTH_ACTION {
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_SUCCESS_REVIEWER = "LOGIN_SUCCESS_REVIEWER",
  LOGIN_FAILED = "LOGIN_FAILED",
  SET_USER_INFO = "SET_USER_INFO",
  LOGOUT = "LOGOUT",
}

type LoginSuccess = {
  type: AUTH_ACTION.LOGIN_SUCCESS;
};

type LoginSuccessReviewer = {
  type: AUTH_ACTION.LOGIN_SUCCESS_REVIEWER;
  payload: {
    data: any;
  };
};

type LoginFailed = {
  type: AUTH_ACTION.LOGIN_FAILED;
};

type SetUserInfo = {
  type: AUTH_ACTION.SET_USER_INFO;
  payload: {
    user: RpMustUser;
  };
};

type Logout = {
  type: AUTH_ACTION.LOGOUT;
};

/**
 * 登入成功
 */
export const loginSuccess = (): LoginSuccess => ({
  type: AUTH_ACTION.LOGIN_SUCCESS,
});

/**
 * 審查委員登入成功
 * @param data
 */
export const loginSuccessReviewer = (data: any): LoginSuccessReviewer => ({
  type: AUTH_ACTION.LOGIN_SUCCESS_REVIEWER,
  payload: {
    data,
  },
});

/**
 * 登入失敗
 */
export const loginFailed = (): LoginFailed => ({
  type: AUTH_ACTION.LOGIN_FAILED,
});

export const logout = (): Logout => {
  store.dispatch(resetProject());
  return {
    type: AUTH_ACTION.LOGOUT,
  };
};

/**
 * 設置使用者資訊到localStorage
 * @param userInfo 使用者資訊
 */
export const setUserInfo = (userInfo: any): SetUserInfo => {
  userInfo.userId && store.dispatch(requestProject(userInfo.userId) as any);

  return {
    type: AUTH_ACTION.SET_USER_INFO,
    payload: {
      user: userInfo,
    },
  };
};

export type AuthActionTypes =
  | LoginSuccess
  | LoginSuccessReviewer
  | LoginFailed
  | SetUserInfo
  | Logout;
