import * as Yup from "yup";
import React, { useEffect } from "react";
import { useFormik } from "formik";
import { Button, Grid, TextField } from "@material-ui/core";

import { Budget } from "@/types";
import { RpMust } from "@/constants";
import { MuiSpin } from "@/components";

import classes from "./style.module.scss";

type PartTimeFormProps = {
  mode: "edit" | "add";
  budget?: Budget;
  loading: boolean;
  onFinish: (value: any) => void;
  setData?: (value: any) => void;
};

const Star = () => <span style={{ color: "red" }}>* </span>;

const PartTimeForm: React.FC<PartTimeFormProps> = ({
  mode,
  loading,
  budget,
  onFinish,
  setData,
}) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    resetForm,
    submitForm,
    getFieldProps,
    handleSubmit,
  } = useFormik({
    initialValues: {
      detail: "",
      hours: 0,
      salary: RpMust.minSalary,
    },
    validationSchema: Yup.object().shape({
      detail: Yup.string().trim("請勿留空").required("必填"),
      hours: Yup.number().min(1, "時數必須大於0").typeError("請填寫正確時數").required("必填"),
      salary: Yup.number()
        .min(RpMust.minSalary, `時薪必須大於${RpMust.minSalary}`)
        .typeError("請填寫正確時薪")
        .required("必填"),
    }),
    onSubmit: onFinish,
  });

  useEffect(() => {
    setData && setData({ resetForm, submitForm });

    if (budget) {
      setFieldValue("detail", budget.detail);
      setFieldValue("hours", budget.hours);
      setFieldValue("salary", budget.salary);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MuiSpin spinning={loading}>
      <form onSubmit={handleSubmit}>
        <Grid className={classes.mb} container justify="center">
          <Grid className={classes.center} item md="auto">
            <Star />
            工讀內容：
          </Grid>
          <Grid item sm={7}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              helperText={errors.detail && touched.detail && errors.detail}
              error={!!errors.detail && !!touched.detail && !!errors.detail}
              {...getFieldProps("detail")}
            />
          </Grid>
        </Grid>
        <Grid container justify="center" spacing={5}>
          <Grid item sm={2}>
            <div className={classes.des}>
              <Star />
              時數
            </div>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="number"
              helperText={errors.hours && touched.hours && errors.hours}
              error={!!errors.hours && !!touched.hours && !!errors.hours}
              {...getFieldProps("hours")}
            />
          </Grid>
          <Grid item xs={2}>
            <div className={classes.des}>
              <Star />
              時薪
            </div>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              type="number"
              helperText={errors.salary && touched.salary && errors.salary}
              FormHelperTextProps={{ style: { whiteSpace: "nowrap" } }}
              error={!!errors.salary && !!touched.salary && !!errors.salary}
              {...getFieldProps("salary")}
            />
          </Grid>
        </Grid>

        <Grid container justify="center">
          <Grid
            item
            xs={12}
            style={{ display: "flex", color: "red", justifyContent: "center", marginTop: 10 }}
          >
            請用每年
            <a target="_blank" href="https://www.mol.gov.tw/1607/28162/28166/28180/28182/">
              政府公告
            </a>
            最新基本工資給付
          </Grid>
          <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
            小計：
            {isNaN(values.salary * values.hours) ? 0 : values.salary * values.hours}元
          </Grid>
          {mode === "add" && (
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center", margin: "18px 0" }}
            >
              <Button type="submit" variant="contained" color="primary">
                新增
              </Button>
            </Grid>
          )}
        </Grid>
      </form>
    </MuiSpin>
  );
};

export default PartTimeForm;
